import React from 'react'
import PostsTypes from '../PostsTypes'
import ProfilePicture from '../PostsTypes/ProfilePicture'
import PostShared from '../PostsTypes/PostShared'
import { useSelector } from 'react-redux'
import styles from '../PostsTypes/index.module.scss'
import PostText from '../PostsTypes/PostText'
import PostsPhotos from '../PostsTypes/PostsPhotos'
import PostVideo from '../PostsTypes/PostVideo'
import PostsAudio from '../PostsTypes/PostsAudio'
import ColoredPattern from '../PostsTypes/ColoredPattern'
import PostActions from '../PostsTypes/PostActions'
import PostLocation from '../PostsTypes/PostLocation'

function Post({
  post,
  userInfo,
  theme,
  siteInfo,
  translate,
  patterns,
  setIsModalMedia,
  setInitialIndexMedia,
  setInitialIndexPost,
  handleAudioPlay,
  audioRefs,
}) {
  if (post.post_type === 'text' && !post.colored_pattern) {
    return (
      <PostsTypes
        post={post}
        userInfo={userInfo}
        theme={theme}
        siteInfo={siteInfo}
        isShared={false}
        isText={false}
        translate={translate}
        onClick={() => {}}
      >
        <PostText post={post} />
      </PostsTypes>
    )
  } else if (post.post_type === 'profile_picture') {
    return (
      <PostsTypes
        post={post}
        userInfo={userInfo}
        theme={theme}
        siteInfo={siteInfo}
        isShared={false}
        isText={true}
        translate={translate}
        onClick={() => {
          setInitialIndexPost(post.post_id)
        }}
      >
        <ProfilePicture
          classNameImage={styles.postProfilePicture}
          post={post}
          userInfo={userInfo}
          siteInfo={siteInfo}
          setInitialIndexMedia={setInitialIndexMedia}
          setIsModalMedia={setIsModalMedia}
        />
      </PostsTypes>
    )
  } else if (post.post_type === 'shared') {
    const allPosts = useSelector((state) => state.posts.allPosts)
    const sharedPost = allPosts.find(
      (postS) => postS.post_id === post.origin_id,
    )
    return (
      <PostsTypes
        post={post}
        userInfo={userInfo}
        theme={theme}
        isText={true}
        siteInfo={siteInfo}
        isShared={true}
        translate={translate}
        onClick={() => {
          setInitialIndexPost(post.post_id)
        }}
      >
        <PostShared
          post={sharedPost}
          userInfo={userInfo}
          siteInfo={siteInfo}
          setInitialIndexMedia={setInitialIndexMedia}
          setIsModalMedia={setIsModalMedia}
        />
      </PostsTypes>
    )
  } else if (post.post_type === 'photos') {
    return (
      <PostsTypes
        post={post}
        userInfo={userInfo}
        theme={theme}
        siteInfo={siteInfo}
        isText={true}
        isShared={false}
        translate={translate}
        onClick={() => {
          setInitialIndexPost(post.post_id)
        }}
      >
        <PostsPhotos
          post={post}
          userInfo={userInfo}
          siteInfo={siteInfo}
          setInitialIndexMedia={setInitialIndexMedia}
          setIsModalMedia={setIsModalMedia}
        />
      </PostsTypes>
    )
  } else if (post.post_type === 'videos') {
    return (
      <PostsTypes
        post={post}
        userInfo={userInfo}
        theme={theme}
        siteInfo={siteInfo}
        isText={true}
        isShared={false}
        translate={translate}
        onClick={() => {
          setInitialIndexPost(post.post_id)
        }}
      >
        <PostVideo
          post={post}
          userInfo={userInfo}
          siteInfo={siteInfo}
          setInitialIndexMedia={setInitialIndexMedia}
          setIsModalMedia={setIsModalMedia}
        />
      </PostsTypes>
    )
  } else if (post.post_type === 'audio') {
    return (
      <PostsTypes
        key={post.post_id}
        post={post}
        userInfo={userInfo}
        theme={theme}
        siteInfo={siteInfo}
        isText={true}
        isShared={false}
        translate={translate}
        onClick={() => {}}
      >
        {post.postAudio.map((audio) => (
          <PostsAudio
            key={`${post.post_id}_${audio.media_id}`}
            post={post}
            audioRef={audioRefs}
            onPlay={handleAudioPlay}
            item={audio}
          />
        ))}
      </PostsTypes>
    )
  } else if (post.post_type === 'text' && post.colored_pattern) {
    return (
      <PostsTypes
        post={post}
        userInfo={userInfo}
        theme={theme}
        siteInfo={siteInfo}
        isShared={false}
        isText={false}
        translate={translate}
        onClick={() => {}}
      >
        <ColoredPattern post={post} patterns={patterns} />
      </PostsTypes>
    )
  } else if (post.post_type === 'actions') {
    return (
      <PostsTypes
        post={post}
        userInfo={userInfo}
        theme={theme}
        siteInfo={siteInfo}
        isShared={false}
        isText={true}
        translate={translate}
        onClick={() => {}}
      >
        <PostActions post={post} />
      </PostsTypes>
    )
  } else if (post.post_type === 'location') {
    return (
      <PostsTypes
        post={post}
        userInfo={userInfo}
        theme={theme}
        siteInfo={siteInfo}
        isShared={false}
        isText={true}
        translate={translate}
        onClick={() => {}}
      >
        <PostLocation post={post} />
      </PostsTypes>
    )
  }
}

export default Post
