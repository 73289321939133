import { all } from 'redux-saga/effects'
import authSagas from './auth.sagas'
import appSagas from './app.sagas'
import siteSaga from './site.sagas'
import storesSaga from './stories.sagas'
import postSaga from './post.sagas'
import messagesSagas from './messages.sagas'

export default function* rootSaga() {
  yield all([
    authSagas(),
    appSagas(),
    siteSaga(),
    storesSaga(),
    postSaga(),
    messagesSagas(),
  ])
}
