import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import authReducer from './auth.reducer'
import appReducer from './app.reducer'
import siteReducer from './site.reducer'
import storesReducer from './stores.reducer'
import postReducer from './post.reducer'
import messagesReducer from './messages.reducer'

const rootReducer = (history) =>
  combineReducers({
    site: siteReducer,
    auth: authReducer,
    app: appReducer,
    stories: storesReducer,
    posts: postReducer,
    messages: messagesReducer,
    router: connectRouter(history),
  })
export default rootReducer
