import React from 'react'
import styles from '../index.module.scss'

function PostsAudio({ item, audioRef, onPlay, post }) {
  return (
    <div className={styles.itemAudio}>
      <audio
        ref={(el) =>
          (audioRef.current[`${post.post_id}_${item.media_id}`] = el)
        }
        className={'js_audio'}
        controls
        preload="auto"
        onPlay={() => {
          onPlay(item, post)
        }}
      >
        <source
          src={`${process.env.REACT_APP_BASE_TINY_MCE_URL}/${item.source}`}
          type="audio/mp3"
        />
      </audio>
    </div>
  )
}

export default PostsAudio
