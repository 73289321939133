import * as ActionTypes from '../constants/post.constants'

export const postInfo = (values) => ({
  type: ActionTypes.ALL_POST_INFO_REQUEST,
  payload: values,
})
export const postInfoSuccess = (values) => ({
  type: ActionTypes.ALL_POST_INFO_SUCCESS,
  payload: values,
})
export const postInfoError = (error) => ({
  type: ActionTypes.ALL_POST_INFO_ERROR,
  payload: error,
})

export const createPostNoFiles = (values) => ({
  type: ActionTypes.CREATE_POST_NONE_FILES_REQUEST,
  payload: values,
})
export const createPost = (values) => ({
  type: ActionTypes.CREATE_POST_REQUEST,
  payload: values,
})
export const newPost = (values) => ({
  type: ActionTypes.NEW_POST_SUCCESS,
  payload: values,
})
export const updatePost = (values) => ({
  type: ActionTypes.UPDATE_POST_SUCCESS,
  payload: values,
})
export const createPostProgress = (values) => ({
  type: ActionTypes.CREATE_POST_PROGRESS,
  payload: values,
})

export const createNews = (values) => ({
  type: ActionTypes.CREATE_NEWS,
  payload: values,
})

export const postColoredPatternsInfo = (values) => ({
  type: ActionTypes.POST_COLORED_PATTERNS_REQUEST,
  payload: values,
})
export const postColoredPatternsInfoSuccess = (values) => ({
  type: ActionTypes.POST_COLORED_PATTERNS_SUCCESS,
  payload: values,
})
export const postColoredPatternsInfoError = (error) => ({
  type: ActionTypes.POST_COLORED_PATTERNS_ERROR,
  payload: error,
})
