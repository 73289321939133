import React from 'react'
import styles from '../index.module.scss'

function ColoredPattern({ post, patterns }) {
  const background = patterns.find((p) => p.pattern_id === post.colored_pattern)
  return (
    <div
      className={styles.postPatterns}
      style={{
        backgroundImage: `url(${process.env.REACT_APP_PSTS_BASE_URL}${background.background_image})`,
      }}
    >
      <div
        className={styles.paternsText}
        dangerouslySetInnerHTML={{ __html: post.text }}
      />
    </div>
  )
}
export default ColoredPattern
