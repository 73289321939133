export const USER_MESSAGES_INFO_REQUEST = 'USER_MESSAGES_INFO_REQUEST'
export const USER_MESSAGES_INFO_SUCCESS = 'USER_MESSAGES_INFO_SUCCESS'
export const USER_MESSAGES_CALL = 'USER_MESSAGES_CALL'

export const SEND_MESSAGES_REQUEST = 'SEND_MESSAGES_REQUEST'
export const NEW_MESSAGES_SUCCESS = 'NEW_MESSAGES_SUCCESS'

export const MESSAGE_VIEW_REQUEST = 'MESSAGE_VIEW_REQUEST'

export const MESSAGE_NOTIFY = 'MESSAGE_NOTIFY'
export const CHAT_ID = 'CHAT_ID'
export const CALL_PEER = 'CALL_PEER'
