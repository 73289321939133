import { baseInstance } from './index'

export const storesInfo = () => baseInstance.get('/stories')

export const creteStores = (formData, onUploadProgress) => {
  console.log(formData)
  return baseInstance.post('/create_stores', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  })
}
