// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessagesList_messageItem__gU0ZS, .MessagesList_messageItemMe__B30yz {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.MessagesList_messageItemMe__B30yz {
  justify-content: flex-end;
}

.MessagesList_messageItemInner__KvFkW, .MessagesList_messageItemInnerM__6ng1W {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  max-width: 47%;
}

.MessagesList_messageItemInnerM__6ng1W {
  align-items: flex-end;
}

.MessagesList_avatar__lQeCI, .MessagesList_avatarM__RsWES {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  object-fit: cover;
}

.MessagesList_avatarM__RsWES {
  width: 1.5rem;
  height: 1.5rem;
}

.MessagesList_messageTextM__i2fKh, .MessagesList_messageText__l6lHs {
  --tw-shadow-colored: 0 1px 3px 0 , 0 1px 2px -1px ;
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  color: rgb(107, 114, 128);
  border-radius: 20px;
  background-color: rgb(241, 245, 249);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Cantarell, "Helvetica Neue", sans-serif;
}

.MessagesList_messageTextM__i2fKh {
  background-image: linear-gradient(to top right, #0ea5e9, rgb(14, 165, 233), #3b82f6);
  --tw-text-opacity: 1;
  color: rgb(255, 255, 255, var(--tw-text-opacity));
}`, "",{"version":3,"sources":["webpack://./src/components/MessagesList/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,mBAAA;AACF;;AACA;EACE,yBAAA;AAEF;;AAAA;EACE,aAAA;EACA,uBAAA;EACA,WAAA;EACA,cAAA;AAGF;;AADA;EACE,qBAAA;AAIF;;AAFA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;AAKF;;AAHA;EACE,aAAA;EACA,cAAA;AAMF;;AAHA;EACE,kDAAA;EACA,yHAAA;EACA,oBAAA;EACA,yBAAA;EACA,mBAAA;EACA,oCAAA;EACA,gBAAA;EACA,mBAAA;EACA,oBAAA;EACA,wHAAA;AAMF;;AAJA;EACE,oFAAA;EACA,oBAAA;EACA,iDAAA;AAOF","sourcesContent":[".messageItem, .messageItemMe{\r\n  position: relative;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: flex-start;\r\n  margin-bottom: 15px;\r\n}\r\n.messageItemMe{\r\n  justify-content: flex-end;\r\n}\r\n.messageItemInner, .messageItemInnerM{\r\n  display: flex;\r\n  align-items: flex-start;\r\n  gap: 0.5rem;\r\n  max-width: 47%;\r\n}\r\n.messageItemInnerM{\r\n  align-items: flex-end;\r\n}\r\n.avatar, .avatarM{\r\n  width: 2rem;\r\n  height: 2rem;\r\n  border-radius: 50%;\r\n  object-fit: cover;\r\n}\r\n.avatarM{\r\n  width: 1.5rem;\r\n  height: 1.5rem;\r\n}\r\n\r\n.messageTextM, .messageText{\r\n  --tw-shadow-colored: 0 1px 3px 0 , 0 1px 2px -1px ;\r\n  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);\r\n  padding: 0.5rem 1rem;\r\n  color: rgb(107, 114, 128);\r\n  border-radius: 20px;\r\n  background-color: rgb(241 245 249);\r\n  font-weight: 500;\r\n  font-size: 0.875rem;\r\n  line-height: 1.25rem;\r\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Oxygen-Sans, Cantarell, \"Helvetica Neue\", sans-serif;\r\n}\r\n.messageTextM{\r\n  background-image: linear-gradient(to top right, #0ea5e9, rgb(14 165 233 / 1), #3b82f6);\r\n  --tw-text-opacity: 1;\r\n  color: rgb(255 255 255 / var(--tw-text-opacity));\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageItem": `MessagesList_messageItem__gU0ZS`,
	"messageItemMe": `MessagesList_messageItemMe__B30yz`,
	"messageItemInner": `MessagesList_messageItemInner__KvFkW`,
	"messageItemInnerM": `MessagesList_messageItemInnerM__6ng1W`,
	"avatar": `MessagesList_avatar__lQeCI`,
	"avatarM": `MessagesList_avatarM__RsWES`,
	"messageTextM": `MessagesList_messageTextM__i2fKh`,
	"messageText": `MessagesList_messageText__l6lHs`
};
export default ___CSS_LOADER_EXPORT___;
