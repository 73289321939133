import React from 'react'
import styles from '../index.module.scss'
import VideoTwo from './VideoTwo'
import ManyVideos from './ManyVideos'
import OneVideo from './OneVideo'

function PostVideo({ post, setIsModalMedia, setInitialIndexMedia }) {
  if (post.postVideos.length < 2) {
    return (
      <>
        {post.postVideos.length > 0 &&
          post.postVideos.map((item, index) => (
            <OneVideo
              key={index}
              setInitialIndexMedia={setInitialIndexMedia}
              setIsModalMedia={setIsModalMedia}
              item={item}
              index={index}
            />
          ))}
      </>
    )
  } else if (post.postVideos.length < 3) {
    return (
      <div className={styles.twoPhotos}>
        {post.postVideos.map((video, index) => (
          <VideoTwo
            video={video}
            key={index}
            post={post}
            videoIndex={index}
            setInitialIndexMedia={setInitialIndexMedia}
            setIsModalMedia={setIsModalMedia}
          />
        ))}
      </div>
    )
  } else if (post.postVideos.length > 2) {
    return (
      <div className={styles.menyMediaOne}>
        {post.postVideos.slice(0, 1).map((video, index) => (
          <ManyVideos
            video={video}
            key={index}
            post={post}
            videoIndex={index}
            setInitialIndexMedia={setInitialIndexMedia}
            setIsModalMedia={setIsModalMedia}
          />
        ))}
        <div>
          {post.postVideos.slice(1, 4).map((video, index) => (
            <ManyVideos
              video={video}
              key={index + 1}
              post={post}
              length={post.postVideos.length}
              videoIndex={index + 1}
              setInitialIndexMedia={setInitialIndexMedia}
              setIsModalMedia={setIsModalMedia}
            />
          ))}
        </div>
      </div>
    )
  }
}

export default PostVideo
