import React, { useState } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import {
  AddToFavoriteIcon,
  DeleteIcon,
  FlagIcon,
  LikeSvg,
  MessagesCountIcon,
  NotifyOfIcon,
  SendPostIcon,
  ShareActionIcon,
  UnFollowActionIcon,
} from '../../assets'
import { getAvatarUrl } from '../../api'
import EmojiPicker from 'emoji-picker-react'
import PostText from './PostText'
function PostsTypes({
  post,
  siteInfo,
  userInfo,
  theme,
  translate,
  isShared,
  onClick,
  isText,
  children,
}) {
  const [isActions, setIsActions] = useState(false)
  const [textInput, setTextInput] = useState('')
  const [isEmoji, setIsEmoji] = useState(false)
  const isLike = post.postReactions.some(
    (items) => items.user_id === userInfo.user_id,
  )
  const onActions = () => {
    setIsActions(!isActions)
  }

  const placeCaretAtEnd = (el) => {
    el.focus()
    const range = document.createRange()
    range.selectNodeContents(el)
    range.collapse(false)
    const sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)
  }

  return (
    <article className={theme ? styles.PostContainerW : styles.PostContainerB}>
      <div className={styles.postHeader}>
        <div className={styles.postUserInner}>
          <Link to={`/${post.user_name}`}>
            <img
              src={`${process.env.REACT_APP_PSTS_BASE_URL}${post.user_avatar}`}
              alt={`${post.user_firstname} ${post.user_lastname}`}
              width={40}
              height={40}
            />
          </Link>
          <div className={styles.fioContainer}>
            <Link to={`/${post.user_name}`} className={styles.fiO}>
              {post.user_firstname} {post.user_lastname}
            </Link>
            <div className={styles.createdAt}>
              {dayjs.utc(post.time.date).tz(dayjs.tz.guess()).fromNow()}
            </div>
          </div>
        </div>
        <button className={styles.actions} onClick={onActions}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            stroke={'black'}
            fill={'black'}
            viewBox="0 0 512 512"
            width={23}
            height={23}
          >
            <circle cx="256" cy="256" r="48"></circle>
            <circle cx="416" cy="256" r="48"></circle>
            <circle cx="96" cy="256" r="48"></circle>
          </svg>
          {isActions && (
            <div
              className={
                theme ? styles.actionsContainer : styles.actionsContainerB
              }
            >
              <div className={styles.wrapper}>
                <div className={styles.actionInner}>
                  <AddToFavoriteIcon
                    width={15}
                    height={15}
                    fill={'rgb(107 114 128 / 1)'}
                  />
                  <div>{translate('Add to favorites')}</div>
                </div>
                <div className={styles.actionInner}>
                  <NotifyOfIcon
                    width={20}
                    height={20}
                    fill={'rgb(107 114 128 / 1)'}
                  />
                  <div>{translate('Mute Notification')}</div>
                </div>
                <div className={styles.actionInner}>
                  <FlagIcon
                    width={20}
                    height={20}
                    fill={'rgb(107 114 128 / 1)'}
                  />
                  <div>{translate('Add to favorites')}</div>
                </div>
                <div className={styles.actionInner}>
                  <ShareActionIcon
                    width={20}
                    height={20}
                    fill={'rgb(107 114 128 / 1)'}
                  />
                  <div>{translate('Add to favorites')}</div>
                </div>
              </div>
              <div className={styles.actionInnerUnFollowActionIcon}>
                {post.user_id === userInfo.user_id ? (
                  <DeleteIcon width={20} height={20} />
                ) : (
                  <UnFollowActionIcon width={25} height={25} fill={'none'} />
                )}
                <div>
                  {post.user_id === userInfo.user_id
                    ? translate('удалить')
                    : translate('Add to favorites')}
                </div>
              </div>
            </div>
          )}
        </button>
      </div>
      <div
        className={styles.postBody}
        onClick={() => {
          setIsActions(false)
          onClick()
        }}
      >
        {post.text && isText && <PostText post={post} />}
        {children}
      </div>
      <div className={styles.postInfoContainer}>
        <div className={styles.postInfoInner}>
          <div className={styles.lickesContainer}>
            <button className={isLike ? styles.licke : styles.unlicke}>
              <LikeSvg
                width={20}
                height={20}
                fill={isLike ? '#ed4956' : '#5e5454'}
              />
            </button>
            <div className={styles.reactionLikeCount}>
              {post.reaction_like_count}
            </div>
          </div>
          <div className={styles.lickesContainer}>
            <button className={styles.unlicke}>
              <MessagesCountIcon
                width={20}
                height={20}
                fill={'rgb(107 114 128 / 1)'}
              />
            </button>
            <div className={styles.reactionLikeCount}>{post.comments}</div>
          </div>
        </div>
        <div className={styles.postInfoInner2}>
          <button className={isLike ? styles.licke : styles.unlicke}>
            <SendPostIcon
              width={20}
              height={20}
              fill={'rgb(107 114 128 / 1)'}
            />
          </button>
          <button className={isLike ? styles.licke : styles.unlicke}>
            <ShareActionIcon
              width={20}
              height={20}
              fill={'rgb(107 114 128 / 1)'}
            />
          </button>
        </div>
      </div>
      {post.postComments.length > 0 && (
        <div className={styles.commentsListContainer}></div>
      )}
      <div className={styles.postInputCommentsContainer}>
        <div className={styles.postInputCommentsInner}>
          <img
            src={
              userInfo.user_avatar_cover
                ? getAvatarUrl(userInfo.user_avatar_cover)
                : userInfo.user_picture
                  ? getAvatarUrl(userInfo.user_picture)
                  : `${process.env.REACT_APP_INFO_BASE_URL}/${siteInfo.avatar}`
            }
            alt={userInfo.user_lastname}
            className={styles.avatarCommetInput}
          />
          <div className={styles.inputWrapper}>
            <div
              className={styles.inputDiv}
              contentEditable={true}
              aria-label={translate('Ваш Коментарий')}
              role={'textbox'}
              onClick={() => {
                setIsEmoji(false)
              }}
              onInput={(event) => {
                setTextInput(event.target.innerHTML)
                setTimeout(() => {
                  placeCaretAtEnd(event.target)
                }, 0)
              }}
              suppressContentEditableWarning={true}
              aria-multiline="true"
              dangerouslySetInnerHTML={{ __html: textInput }}
            ></div>
            <div className={styles.inputActions}>
              <button className={styles.smile}>
                <i className="fa fa-paperclip"></i>
              </button>
              <button
                className={styles.smile}
                onClick={() => {
                  setIsEmoji(!isEmoji)
                }}
              >
                <img
                  src={
                    'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f600.png'
                  }
                  alt={''}
                  width={20}
                  height={20}
                />
              </button>
            </div>
            {isEmoji && (
              <EmojiPicker
                onMouseOut={() => setIsEmoji(false)}
                className={styles.emojiPicker}
                searchDisabled
                onEmojiClick={(emoji) => {
                  setTextInput(
                    (prevState) =>
                      prevState +
                      `<img src='${emoji.imageUrl}' alt='${emoji.emoji}' width="30px" height="30px">`,
                  )
                  setIsEmoji(false)
                }}
              />
            )}
          </div>
          <button className={styles.sendComment}>
            {translate('Комментировать')}
          </button>
        </div>
      </div>
    </article>
  )
}

export default PostsTypes
