import { takeEvery, call, all } from 'redux-saga/effects'
import * as ActionTypes from '../constants/messages.constants'
import { socketUrl } from '../api'
import { getAccessToken } from '../utils'
import { toast } from 'react-toastify'

const allMessagesUserRequest = (action) => {
  return new Promise(() => {
    socketUrl.emit('messagesUser', action)
  })
}

export function* allMessagesUser(action) {
  try {
    const token = yield call(getAccessToken)
    yield call(allMessagesUserRequest, {
      ...action.payload,
      authorization: token,
    })
  } catch (error) {
    toast.error(error.message)
  }
}

const sendMessagesUserRequest = (action) => {
  return new Promise(() => {
    socketUrl.emit('sendMessageTouUser', action)
  })
}

export function* sendMessagesUserUser(action) {
  try {
    const token = yield call(getAccessToken)
    yield call(sendMessagesUserRequest, {
      ...action.payload,
      authorization: token,
    })
  } catch (error) {
    toast.error(error.message)
  }
}

export default function* messagesSagas() {
  yield all([
    takeEvery(ActionTypes.USER_MESSAGES_INFO_REQUEST, allMessagesUser),
    takeEvery(ActionTypes.SEND_MESSAGES_REQUEST, sendMessagesUserUser),
  ])
}
