import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'
import configureStore from './config/store'
import App from './App'
import isBetween from 'dayjs/plugin/isBetween'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import './config/localization/i18n'

dayjs.extend(isBetween)
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
export const store = configureStore()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store.store}>
    <PersistGate loading={null} persistor={store.persistor}>
      <App />
      <ToastContainer />
    </PersistGate>
  </Provider>,
)
reportWebVitals()
serviceWorker.unregister()
