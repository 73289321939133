import React from 'react'
import ProfilePicture from '../ProfilePicture'
import styles from '../index.module.scss'
import PhotoListTwo from './PhotoListTwo'
import ManyPhotos from './ManyPhotos'

function PostsPhotos({
  post,
  setIsModalMedia,
  setInitialIndexMedia,
  userInfo,
  siteInfo,
}) {
  if (post.postPhotos.length < 2) {
    return (
      <>
        <ProfilePicture
          classNameImage={styles.postProfilePicture}
          post={post}
          userInfo={userInfo}
          siteInfo={siteInfo}
          setInitialIndexMedia={setInitialIndexMedia}
          setIsModalMedia={setIsModalMedia}
        />
      </>
    )
  } else if (post.postPhotos.length < 3) {
    return (
      <div className={styles.twoPhotos}>
        {post.postPhotos.map((photo, index) => (
          <PhotoListTwo
            photo={photo}
            key={index}
            post={post}
            photoIndex={index}
            setInitialIndexMedia={setInitialIndexMedia}
            setIsModalMedia={setIsModalMedia}
          />
        ))}
      </div>
    )
  } else if (post.postPhotos.length > 2) {
    return (
      <div className={styles.menyMediaOne}>
        {post.postPhotos.slice(0, 1).map((photo, index) => (
          <ManyPhotos
            photo={photo}
            key={index}
            post={post}
            photoIndex={index}
            setInitialIndexMedia={setInitialIndexMedia}
            setIsModalMedia={setIsModalMedia}
          />
        ))}
        <div>
          {post.postPhotos.slice(1, 4).map((photo, index) => (
            <ManyPhotos
              photo={photo}
              key={index + 1}
              post={post}
              length={post.postPhotos.length}
              photoIndex={index + 1}
              setInitialIndexMedia={setInitialIndexMedia}
              setIsModalMedia={setIsModalMedia}
            />
          ))}
        </div>
      </div>
    )
  }
}
export default PostsPhotos
