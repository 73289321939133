export const categoryNewsData = [
  {
    name: 'categoryNews.art',
  },
  {
    name: 'categoryNews.Causes',
  },
  {
    name: 'categoryNews.Crafts',
  },
  {
    name: 'categoryNews.Dance',
  },
  {
    name: 'categoryNews.Drinks',
  },
  {
    name: 'categoryNews.Film',
  },
  {
    name: 'categoryNews.Fitness',
  },
  {
    name: 'categoryNews.Food',
  },
  {
    name: 'categoryNews.games',
  },
  {
    name: 'categoryNews.Gardening',
  },
  {
    name: 'categoryNews.Health',
  },
  {
    name: 'categoryNews.Literature',
  },
  {
    name: 'categoryNews.Music',
  },
  {
    name: 'categoryNews.Networking',
  },
  {
    name: 'categoryNews.Party',
  },
  {
    name: 'categoryNews.Religion',
  },
  {
    name: 'categoryNews.Shopping',
  },
  {
    name: 'categoryNews.Sports',
  },
  {
    name: 'categoryNews.Theater',
  },
  {
    name: 'categoryNews.Wellness',
  },
  {
    name: 'categoryNews.other',
  },
]
