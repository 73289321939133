import React from 'react'
import styles from '../../index.module.scss'

function OneVideo({ item, setInitialIndexMedia, setIsModalMedia, index }) {
  return (
    <button
      className={styles.profilePicture}
      onClick={() => {
        if (item.source) {
          setInitialIndexMedia(index)
          setIsModalMedia(true)
        }
      }}
    >
      {item.source ? (
        <div className={styles.videoOne}>
          <video
            id="video"
            preload="metadata"
            onClick={(e) => e.preventDefault()}
          >
            <source
              src={`${process.env.REACT_APP_BASE_TINY_MCE_URL}/${item.source}#t=0.10`}
              type="video/mp4"
            />
          </video>
          <div className={styles.buttonPlay} />
        </div>
      ) : (
        <>
          {item.sourceHtml && (
            <div
              className={styles.videoOne}
              dangerouslySetInnerHTML={{ __html: item.sourceHtml }}
            ></div>
          )}
        </>
      )}
    </button>
  )
}
export default OneVideo
