// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CallModal_calModal__6VSsN {
  --call-w: max(1128px, min(1288px, 90vw));
  --call-h: max(500px, min(693px, 90vh));
  --call-header-and-footer-height: 144px;
  --call-content-h: calc(var(--call-h) - var(--call-header-and-footer-height));
  --call-speaker-label-height: 66px;
  --call-messages-container-height: calc(var(--call-h) - var(--call-header-and-footer-height) - var(--call-speaker-label-height));
  --calls-bg-color: #19191a;
  --participnant-icon-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.24));
  --call-orator-toggle-others-w: 24px;
  --call-orator-others-w: 240px;
  --call-modal-padding-w: 24px;
  --call-modal-footer-h: 88px;
  --calls-modal-z-index: 1002;
  --call-subtitles-h: 84px;
  --in-call-chat-w: calc(350px + var(--call-modal-padding-w));
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10001;
}

.CallModal_calModalBgCl__g7Iir {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.72);
}

.CallModal_modal__SpAPh {
  position: relative;
  animation: CallModal_calls-appearance__Fy7uL 0.4s ease;
  background: 0 0;
  overflow: visible;
  max-height: 100%;
  z-index: 1001;
  color: var(--vkui--color_text_primary);
  border-radius: var(--vkui--size_border_radius_paper--regular);
}`, "",{"version":3,"sources":["webpack://./src/components/CallModal/index.module.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;EACA,sCAAA;EACA,sCAAA;EACA,4EAAA;EACA,iCAAA;EACA,+HAAA;EACA,yBAAA;EACA,oEAAA;EACA,mCAAA;EACA,6BAAA;EACA,4BAAA;EACA,2BAAA;EACA,2BAAA;EACA,wBAAA;EACA,2DAAA;EACA,eAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AACF;;AACA;EACE,eAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,+BAAA;AAEF;;AACA;EACE,kBAAA;EACA,sDAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,sCAAA;EACA,6DAAA;AAEF","sourcesContent":[".calModal{\r\n  --call-w: max(1128px, min(1288px, 90vw));\r\n  --call-h: max(500px, min(693px, 90vh));\r\n  --call-header-and-footer-height: 144px;\r\n  --call-content-h: calc(var(--call-h) - var(--call-header-and-footer-height));\r\n  --call-speaker-label-height: 66px;\r\n  --call-messages-container-height: calc(var(--call-h) - var(--call-header-and-footer-height) - var(--call-speaker-label-height));\r\n  --calls-bg-color: #19191a;\r\n  --participnant-icon-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.24));\r\n  --call-orator-toggle-others-w: 24px;\r\n  --call-orator-others-w: 240px;\r\n  --call-modal-padding-w: 24px;\r\n  --call-modal-footer-h: 88px;\r\n  --calls-modal-z-index: 1002;\r\n  --call-subtitles-h: 84px;\r\n  --in-call-chat-w: calc(350px + var(--call-modal-padding-w));\r\n  position: fixed;\r\n  top: 0;\r\n  bottom: 0;\r\n  left: 0;\r\n  right: 0;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  z-index: 10001;\r\n}\r\n.calModalBgCl{\r\n  position: fixed;\r\n  left: 0;\r\n  top: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  background: rgba(0, 0, 0, 0.72);\r\n}\r\n\r\n.modal{\r\n  position: relative;\r\n  animation: calls-appearance 0.4s ease;\r\n  background: 0 0;\r\n  overflow: visible;\r\n  max-height: 100%;\r\n  z-index: 1001;\r\n  color: var(--vkui--color_text_primary);\r\n  border-radius: var(--vkui--size_border_radius_paper--regular);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calModal": `CallModal_calModal__6VSsN`,
	"calModalBgCl": `CallModal_calModalBgCl__g7Iir`,
	"modal": `CallModal_modal__SpAPh`,
	"calls-appearance": `CallModal_calls-appearance__Fy7uL`
};
export default ___CSS_LOADER_EXPORT___;
