import React from 'react'
import styles from './index.module.scss'

function CreatePost({ setIsCreatePostModal, translate, theme }) {
  return (
    <article
      className={
        theme ? styles.createPostContainer : styles.createPostContainerBlack
      }
    >
      <button
        className={styles.createPostContainerInner}
        onClick={() => {
          setIsCreatePostModal(true)
        }}
      >
        <div className={styles.textInfo}>
          {translate(' What do you have in mind? ')}
        </div>
        <div className={styles.iconsContainer}>
          <div className={styles.iconRed}>
            <svg
              width={30}
              height={30}
              xmlns="http://www.w3.org/2000/svg"
              className="w-8 h-8 stroke-pink-600 fill-pink-200/70"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#db2777"
              fill={'rgb(251 207 232 / 0.7)'}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M15 8h.01"></path>
              <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z"></path>
              <path d="M3.5 15.5l4.5 -4.5c.928 -.893 2.072 -.893 3 0l5 5"></path>
              <path d="M14 14l1 -1c.928 -.893 2.072 -.893 3 0l2.5 2.5"></path>
            </svg>
          </div>
          <div className={styles.blue}>
            <svg
              width={30}
              height={30}
              xmlns="http://www.w3.org/2000/svg"
              className="w-8 h-8 stroke-sky-600 fill-sky-200/70 "
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#0284c7"
              fill="rgb(186 230 253 / 0.7)"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z"></path>
              <path d="M3 6m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"></path>
            </svg>
          </div>
        </div>
      </button>
    </article>
  )
}

export default CreatePost
