import React from 'react'
import styles from '../index.module.scss'
import ProfilePicture from '../ProfilePicture'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import PostText from '../PostText'
import { getAvatarUrl } from '../../../api'

function PostShared({ post, setIsModalMedia, setInitialIndexMedia }) {
  return (
    <div className={styles.postSharedContainer}>
      <div className={styles.postSharedHeader}>
        <Link to={`/${post.user_name}`}>
          <img
            src={getAvatarUrl(post.user_avatar)}
            alt={`${post.user_firstname} ${post.user_lastname}`}
            width={40}
            height={40}
            className={styles.sharedAvatar}
          />
        </Link>
        <div className={styles.fioContainer}>
          <Link to={`/${post.user_name}`} className={styles.fiO}>
            {post.user_firstname} {post.user_lastname}
          </Link>
          <div className={styles.createdAt}>
            {dayjs().to(dayjs(post.time.date))}
          </div>
        </div>
      </div>
      {post.post_type === 'profile_picture' ? (
        <>
          {post.text && <PostText post={post} />}
          <ProfilePicture
            post={post}
            classNameImage={styles.postProfilePictureShared}
            setIsModalMedia={setIsModalMedia}
            setInitialIndexMedia={setInitialIndexMedia}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default PostShared
