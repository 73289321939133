import React from 'react'
import styles from '../../index.module.scss'

function ManyVideos({
  video,
  videoIndex,
  setIsModalMedia,
  setInitialIndexMedia,
  length,
}) {
  return (
    <button
      className={
        videoIndex === 0
          ? styles.indexNull
          : videoIndex === 3 && length > 4
            ? styles.morFor
            : styles.otherIndex
      }
      onClick={() => {
        setInitialIndexMedia(videoIndex)
        setIsModalMedia(true)
      }}
    >
      <div className={styles.media}>
        {video.source && (
          <video
            key={videoIndex}
            id={`video-${videoIndex}`}
            onClick={(e) => e.preventDefault()}
            preload="metadata"
          >
            <source
              src={`${process.env.REACT_APP_BASE_TINY_MCE_URL}/${video.source}#t=0.10`}
              type="video/mp4"
            />
          </video>
        )}
        <div className={styles.buttonPlay} />
      </div>
      {videoIndex === 3 && length > 4 && (
        <div className={styles.bg}>+ {length - 4}</div>
      )}
    </button>
  )
}

export default ManyVideos
