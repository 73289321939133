import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Parallax, Navigation, Pagination } from 'swiper/modules'
import { useSelector } from 'react-redux'
import './pagination.css'

function ModalViewMedia({
  post,
  initialIndexMedia,
  isModalMedia,
  setInitialIndexMedia,
  setIsModalMedia,
  theme,
}) {
  const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
  const slideRenders = (postInfo) => {
    const postProfilePicture =
      postInfo.post_type === 'profile_picture' ||
      postInfo.post_type === 'photos'
    const postShared = postInfo.post_type === 'shared'
    const postVideos = postInfo.post_type === 'videos'
    if (postProfilePicture) {
      return (
        <>
          {postInfo.postPhotos.map((item, index) => (
            <SwiperSlide key={index} className={styles.swiperSlide}>
              <img
                alt={`${post.user_firstname} ${post.user_lastname}`}
                src={`${process.env.REACT_APP_BASE_TINY_MCE_URL}/${item.source}`}
                className={styles.swiperMedia}
              />
            </SwiperSlide>
          ))}
        </>
      )
    } else if (postShared) {
      const allPosts = useSelector((state) => state.posts.allPosts)
      const sharedPost = allPosts.find(
        (postS) => postS.post_id === postInfo.origin_id,
      )
      const postProfilePictureS =
        sharedPost.post_type === 'profile_picture' ||
        postInfo.post_type === 'photos'
      if (postProfilePictureS) {
        return (
          <>
            {sharedPost.postPhotos.map((item, index) => (
              <SwiperSlide key={index} className={styles.swiperSlide}>
                <img
                  alt={`${post.user_firstname} ${post.user_lastname}`}
                  src={`${process.env.REACT_APP_BASE_TINY_MCE_URL}/${item.source}`}
                  className={styles.swiperMedia}
                />
              </SwiperSlide>
            ))}
          </>
        )
      }
    } else if (postVideos) {
      return (
        <>
          {post.postVideos.map((video, index) => {
            return (
              <SwiperSlide key={index} className={styles.swiperSlide}>
                {video.source ? (
                  <video
                    className={styles.swiperMedia}
                    controls={true}
                    preload="metadata"
                  >
                    <source
                      src={`${process.env.REACT_APP_BASE_TINY_MCE_URL}/${video.source}#t=0.10`}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <></>
                )}
              </SwiperSlide>
            )
          })}
        </>
      )
    }
  }

  return (
    <>
      <div className={styles.modalContainer}>
        <div className={theme ? styles.modalInner : styles.modalInnerB}>
          <div className={styles.colSwiper}>
            <Swiper
              onSlideChange={(swiper) => {
                setInitialIndexMedia(swiper.activeIndex)
              }}
              initialSlide={initialIndexMedia}
              slidesPerView={1}
              spaceBetween={0}
              speed={500}
              parallax={true}
              navigation={!isMobile}
              pagination
              className={styles.swiperContainer}
              modules={[Navigation, Parallax, Pagination]}
            >
              {slideRenders(post)}
              <button
                type="button"
                className={styles.modalClose}
                onClick={() => {
                  setIsModalMedia(!isModalMedia)
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </Swiper>
          </div>
          <div className={styles.colComments}></div>
        </div>
        <div
          className={styles.modalBg}
          onClick={() => {
            setIsModalMedia(!isModalMedia)
          }}
        />
      </div>
    </>
  )
}

export default ModalViewMedia
