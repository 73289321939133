import React from 'react'
import styles from '../../index.module.scss'
function PhotoListTwo({
  photo,
  setInitialIndexMedia,
  setIsModalMedia,
  photoIndex,
  post,
}) {
  return (
    <button
      className={styles.mediaButton}
      onClick={() => {
        setInitialIndexMedia(photoIndex)
        setIsModalMedia(true)
      }}
    >
      <img
        alt={`${post.user_firstname} ${post.user_lastname}`}
        src={`${process.env.REACT_APP_BASE_TINY_MCE_URL}/${photo.source}`}
        className={styles.mediaTwo}
      />
    </button>
  )
}

export default PhotoListTwo
