import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actionsMessages from '../../actions/messages.actions'
import RingRing from '../../assets/audio/04335.mp3'
import RingRingIn from '../../assets/audio/ringring.mp3'
import styles from './index.module.scss'
import './calls.css'
import './vkui.css'
import { configuration, getAvatarUrl } from '../../api'
import * as actionMessages from '../../actions/messages.actions'

function CallModal({ socket, user, call }) {
  const dispatch = useDispatch()
  const peer = useSelector((state) => state.messages.peer)
  const siteInfo = useSelector((state) => state.site.siteInfo)
  const [hours, setHours] = useState(0)
  const [min, setMin] = useState(0)
  const [second, setSecond] = useState(0)
  const [total, setTotal] = useState(0)
  const [answer, setAnswer] = useState(false)
  const [isMicrophone, setIsMicrophone] = useState(true)
  const [isMayCamera, setIsMayCamera] = useState(true)
  const [usersCall, setUsersCall] = useState([])
  const [isOratorCamera, setIsOratorCamera] = useState(true)
  const youVideo = useRef()
  const otherVideo = useRef()
  const [tracks, setTracks] = useState(null)
  const [newCall, setNewCall] = useState(null)
  const [idOrator, setIdOrator] = useState(null)
  const audioRef = useRef()
  const fullscreenRef = useRef(null)

  const openStream = (video) => {
    const config = { audio: true, video }
    return navigator.mediaDevices.getUserMedia(config)
  }

  const playStream = (tag, stream) => {
    if (tag) {
      tag.srcObject = stream
      tag.oncanplay = () => {
        tag.play()
      }
    }
  }

  const playAudio = (newAudio) => {
    newAudio.play()
  }

  const pauseAudio = (newAudio) => {
    newAudio.pause()
    newAudio.currentTime = 0
  }

  useEffect(() => {
    if (audioRef.current) {
      if (answer) {
        pauseAudio(audioRef.current)
      } else {
        if (user.user_id === call.recipient_id) {
          playAudio(audioRef.current)
        } else {
          playAudio(audioRef.current)
        }
      }
    }
  }, [answer])

  useEffect(() => {
    const setTime = () => {
      setTotal((t) => t + 1)
      setTimeout(setTime, 1000)
    }
    setTime()
    return () => setTotal(0)
  }, [])

  useEffect(() => {
    setSecond(total % 60)
    setMin(parseInt(total / 60))
    setHours(parseInt(total / 3600))
  }, [total])

  const addCallMessage = useCallback(
    (calls, times, disconnect) => {
      if (calls.recipient_id !== user.user_id || disconnect) {
        const msg = {
          conversation_id: calls.conversation_id,
          user_id: calls.user_id,
          message: null,
          image: null,
          voice_note: null,
          shared: [],
          recipient_id: calls.recipient_id,
          message_rid: null,
          type: 'CALL',
          call: { video: call.video, times },
          time: new Date().toISOString(),
        }
        dispatch(actionsMessages.sendMessage(msg))
      }
    },
    [user, dispatch, socket],
  )

  useEffect(() => {
    socket.on('endCallToClient', (data) => {
      tracks && tracks.forEach((track) => track.stop())
      if (newCall) newCall.close()
      addCallMessage(data, data.times)
      dispatch(actionsMessages.callUser(null))
    })

    return () => socket.off('endCallToClient')
  }, [socket, dispatch, tracks, addCallMessage, newCall])

  useEffect(() => {
    if (answer) {
      setTotal(0)
    } else {
      const timer = setTimeout(() => {
        socket.emit('endCall', { ...call, times: 0 })
        addCallMessage(call, 0)
        dispatch(actionsMessages.callUser(null))
      }, 15000)

      return () => clearTimeout(timer)
    }
  }, [dispatch, answer, call, socket, addCallMessage])

  const handleAnswer = () => {
    openStream(call.video).then((stream) => {
      playStream(youVideo.current, stream)
      const track = stream.getTracks()
      setTracks(track)

      const newCall = peer.call(call.peerId, stream)
      newCall.on('stream', function (remoteStream) {
        playStream(otherVideo.current, remoteStream)
      })
      setAnswer(true)
      setNewCall(newCall)
    })
  }

  useEffect(() => {
    peer.on('call', (newCall) => {
      openStream(call.video).then((stream) => {
        playStream(youVideo.current, stream)
        const track = stream.getTracks()
        setTracks(track)
        newCall.answer(stream)
        newCall.on('stream', function (remoteStream) {
          if (otherVideo.current) {
            playStream(otherVideo.current, remoteStream)
          }
        })
        setAnswer(true)
        setNewCall(newCall)
      })
    })
    return () => peer.removeListener('call')
  }, [peer, call.video])

  const handleEndCall = () => {
    tracks && tracks.forEach((track) => track.stop())
    if (newCall) newCall.close()
    let times = answer ? total : 0
    socket.emit('endCall', { ...call, times })
    addCallMessage(call, times, null)
    dispatch(actionsMessages.callUser(null))
  }

  const toggleMicrophone = () => {
    if (tracks) {
      if (isMicrophone) {
        setIsMicrophone(false)
        tracks.forEach((track) => {
          if (track.kind === 'audio') {
            track.enabled = false
          }
        })
      } else {
        setIsMicrophone(true)
        tracks.forEach((track) => {
          if (track.kind === 'audio') {
            track.enabled = true
          }
        })
      }
    }
  }

  const toggleCamera = () => {}

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      if (fullscreenRef.current && fullscreenRef.current.requestFullscreen) {
        fullscreenRef.current.requestFullscreen()
      }
    } else {
      document.exitFullscreen()
    }
  }

  return (
    <>
      {user.user_id === call.recipient_id ? (
        <audio controls ref={audioRef} style={{ display: 'none' }}>
          <source src={RingRingIn} type="audio/mp3" />
        </audio>
      ) : (
        <audio controls ref={audioRef} style={{ display: 'none' }}>
          <source src={RingRing} type="audio/mp3" />
        </audio>
      )}

      {call.recipient_id === user.user_id && !answer ? (
        <div
          className={'BaseModal CallModal CallModal--withAnimation'}
          aria-modal="true"
        >
          <div className={'BaseModal__backdrop'} />
          <div className={'BaseModal__content'}>
            <div
              role="dialog"
              aria-label="Ivani Serge Входящий аудиозвонок"
              aria-modal="true"
              tabIndex="-1"
            >
              <div className={'FocusTrap'}>
                <div className="FocusTrap__stub" tabIndex="0"></div>
                <div className={'FocusTrap__content'}>
                  <div className={'vkui__root'}>
                    <section
                      className={'IncomingCall'}
                      data-testid="calls_incoming_call_modal_root"
                    >
                      <div
                        className="BlurredBackground"
                        style={{
                          background:
                            'radial-gradient(circle, rgb(56, 58, 52) 40%, rgb(10, 10, 10) 100%)',
                        }}
                      ></div>
                      <div className={'IncomingCall__collapse'}>
                        <button
                          className={
                            'vkuiIconButton vkuiIconButton--sizeY-compact vkuiTappable vkuiTappable--hasPointer-none vkuiClickable__resetButtonStyle vkuiClickable__host vkuiClickable__realClickable vkui-focus-visible vkuiRootComponent'
                          }
                        >
                          <></>
                        </button>
                      </div>
                      <header className={'IncomingCallHeader'}>
                        <div
                          data-testid="calls_incoming_call_title"
                          className="IncomingCallHeader__title"
                        >
                          {call.user.user_firstname} {call.user.user_lastname}
                        </div>
                        <div
                          data-testid="calls_incoming_call_desc"
                          className="IncomingCallHeader__description"
                        >
                          Входящий {call.video ? 'видеоозвонок' : 'аудиозвонок'}
                        </div>
                      </header>
                      <img
                        data-testid="calls_call_photo_visible"
                        src={
                          call.user.user_avatar_cover
                            ? getAvatarUrl(call.user.user_avatar_cover)
                            : call.user.user_picture
                              ? getAvatarUrl(call.user.user_picture)
                              : `${process.env.REACT_APP_INFO_BASE_URL}/${siteInfo.avatar}`
                        }
                        className="CallPhoto IncomingCall__photo CallPhoto--waves"
                        alt={`${call.user.user_firstname} ${call.user.user_lastname}`}
                        draggable="false"
                      />
                      <footer className={'IncomingCall__footer'}>
                        <button
                          onClick={handleAnswer}
                          className="CallButton CallButton--positive CallButton--withText CallButton--withAnimation"
                          data-testid="calls_incoming_call_reply_audio"
                          data-focus-first="true"
                          style={{ display: 'block', cursor: 'pointer' }}
                        >
                          <figure className="CallButton__icon CallButton__icon--withAnimation">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="white"
                              className="vkuiIcon vkuiIcon--24 vkuiIcon--w-24 vkuiIcon--h-24 vkuiIcon--phone_24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M14.1117 15.259C15.4658 13.9047 17.5977 13.7799 19.0152 14.9717L20.0395 15.8346C21.2085 16.8183 21.3273 18.5917 20.303 19.7944C19.7444 20.4407 18.9581 20.8466 18.1078 20.9278C14.8348 21.3686 11.4013 19.7914 7.80538 16.1952C4.20943 12.5989 2.63137 9.16419 3.07219 5.89191C3.0951 5.63587 3.14831 5.38346 3.23069 5.13996C3.42132 4.5806 3.75783 4.08229 4.20546 3.69651C5.40907 2.67312 7.18129 2.79101 8.16497 3.96103L9.02681 4.98541C10.2205 6.40113 10.0967 8.5341 8.74251 9.88839L8.00746 10.6225C7.81149 10.8188 7.74733 11.1111 7.84302 11.3715C8.11049 12.1026 8.79303 13.016 9.88865 14.1117C10.9853 15.2085 11.8986 15.8901 12.6287 16.1585C12.8893 16.254 13.1815 16.1894 13.3776 15.9931L14.1117 15.259Z" />
                            </svg>
                          </figure>
                          Ответить
                        </button>
                        <button
                          onClick={handleEndCall}
                          className="CallButton CallButton--negative CallButton--withText CallButton--withAnimation"
                          data-testid="calls_incoming_call_decline"
                          style={{ display: 'block', cursor: 'pointer' }}
                        >
                          <figure className="CallButton__icon CallButton__icon--withAnimation">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="white"
                              xmlns="http://www.w3.org/2000/svg"
                              className="vkuiIcon vkuiIcon--24 vkuiIcon--w-24 vkuiIcon--h-24 vkuiIcon--cancel_24"
                            >
                              <path d="M7.5364 6.2636C7.18492 5.91213 6.61508 5.91213 6.2636 6.2636C5.91213 6.61508 5.91213 7.18492 6.2636 7.5364L10.7272 12L6.2636 16.4636C5.91213 16.8151 5.91213 17.3849 6.2636 17.7364C6.61508 18.0879 7.18492 18.0879 7.5364 17.7364L12 13.2728L16.4636 17.7364C16.8151 18.0879 17.3849 18.0879 17.7364 17.7364C18.0879 17.3849 18.0879 16.8151 17.7364 16.4636L13.2728 12L17.7364 7.5364C18.0879 7.18492 18.0879 6.61508 17.7364 6.2636C17.3849 5.91213 16.8151 5.91213 16.4636 6.2636L12 10.7272L7.5364 6.2636Z" />
                            </svg>
                          </figure>
                          Отклонить
                        </button>
                      </footer>
                    </section>
                  </div>
                </div>
                <div className="FocusTrap__stub" tabIndex="0"></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.calModal}>
          <div className={styles.calModalBgCl}></div>
          <div className={styles.modal}>
            <div className={'FocusTrap'}>
              <div className="FocusTrap__stub" tabIndex="0"></div>
              <div className={'FocusTrap__content'}>
                <div className={'vkui__root'}>
                  <section
                    className={'Call'}
                    data-testid="calls_call_main_modal_root"
                    ref={fullscreenRef}
                    webkitAllowFullScreen
                    mozallowfullscreen
                  >
                    <div className={'CallHeader'}>
                      <div className={'CallHeader__main'}>
                        <div className={'CallHeader__timeAndNetwork'}>
                          <div
                            className="CallTimer"
                            data-testid="calls_call_timer"
                            role="timer"
                            aria-label={`${hours}:${min}:${second}`}
                            data-duration={`${hours}:${min}:${second}`}
                          ></div>
                        </div>
                      </div>
                      <div className={'CallHeader__aside'}>
                        <div className="CallHeader__participants"></div>
                        <div className={'CallHeader__hands'}></div>
                        <div>
                          <button
                            className="vkuiButton vkuiButton--size-m vkuiButton--mode-secondary vkuiButton--appearance-overlay vkuiButton--align-center vkuiButton--sizeY-regular vkuiButton--with-icon vkuiTappable vkuiTappable--hasPointer-none vkuiClickable__resetButtonStyle vkuiClickable__host vkuiClickable__realClickable vkui-focus-visible vkuiRootComponent"
                            type="button"
                            data-focus-first="true"
                            data-testid="calls_call_link_settings_button"
                            aria-label="Настройки ссылки"
                          >
                            <span className="vkuiButton__in">
                              <span
                                className="vkuiButton__before"
                                role="presentation"
                              >
                                <i className="fa fa-link"></i>
                              </span>
                              <span className="vkuiButton__content">
                                Ссылка
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className={'Call__contentWrapper'}>
                      <div className={'Call__content'}>
                        <article
                          className={
                            'vkui--vkBase--dark vkuiTokensClassProvider--default-color CallOrator'
                          }
                        >
                          <main className={'CallOrator__orator'}>
                            <div className={'CallOrator__topControlPanel'}>
                              <div className={'OratorTitle'}>
                                <span className="OratorTitle__name">
                                  {call.user.user_id !== user.user_id ? (
                                    <>
                                      {call.user.user_firstname}{' '}
                                      {call.user.user_lastname}
                                    </>
                                  ) : (
                                    <>
                                      {user.user_firstname} {user.user_lastname}
                                    </>
                                  )}
                                </span>
                              </div>
                              <div
                                className="CallNotification CallOrator__notification CallNotification--visually-hidden"
                                data-testid="calls_call_notification"
                                aria-live="polite"
                                aria-atomic="true"
                              ></div>
                            </div>
                            <div className={'Participant__wrapper'}>
                              <article
                                className={
                                  'Participant Participant--withAnimation Participant--isOrator'
                                }
                                aria-label="Вы"
                                participant-id="519282746:d0"
                                tabIndex="-1"
                              >
                                {call.video && isOratorCamera ? (
                                  <div
                                    className={
                                      'react-transform-wrapper transform-component-module_wrapper__SPB86 VideoZoomWrapper'
                                    }
                                  >
                                    <div
                                      className={
                                        'react-transform-component transform-component-module_content__FBWxo VideoZoomWrapper__content'
                                      }
                                    >
                                      <div className={'Participant__main'}>
                                        <video
                                          autoPlay
                                          ref={otherVideo}
                                          playsInline
                                          crossOrigin={'anonymous'}
                                          preload="metadata"
                                          disablePictureInPicture
                                          className={
                                            'Video Video--contain Video--mirrored Video--visible'
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className={
                                      'BlurredBackground Participant__main'
                                    }
                                    style={{
                                      background:
                                        'radial-gradient(circle, rgb(56, 58, 58) 40%, rgb(10, 10, 10) 100%)',
                                    }}
                                  >
                                    <img
                                      src={
                                        call.user.user_avatar_cover
                                          ? getAvatarUrl(
                                              call.user.user_avatar_cover,
                                            )
                                          : call.user.user_picture
                                            ? getAvatarUrl(
                                                call.user.user_picture,
                                              )
                                            : `${process.env.REACT_APP_INFO_BASE_URL}/${siteInfo.avatar}`
                                      }
                                      alt={call.user.user_lastname}
                                      draggable="false"
                                      className="CallPhoto Participant__oratorPhoto CallPhoto--200"
                                      data-testid="calls_call_photo_visible"
                                    />
                                    <video
                                      autoPlay
                                      playsInline
                                      ref={otherVideo}
                                      crossOrigin={'anonymous'}
                                      preload="metadata"
                                      disablePictureInPicture
                                      style={{ display: 'none' }}
                                    />
                                    <video
                                      autoPlay
                                      ref={youVideo}
                                      playsInline
                                      muted
                                      crossOrigin={'anonymous'}
                                      preload="metadata"
                                      disablePictureInPicture
                                      style={{ display: 'none' }}
                                    />
                                  </div>
                                )}
                              </article>
                              <header className="Participant__header">
                                <div className="Participant__indicators"></div>
                              </header>
                            </div>
                          </main>
                          <button
                            className={'CallOrator__toggleOthers'}
                            data-testid="calls_call_collapse_participants"
                            aria-label="Скрыть участников"
                          >
                            <i className="fas fa-chevron-right vkuiIcon vkuiIcon--16 vkuiIcon--w-12 vkuiIcon--h-16 vkuiIcon--chevron_16 CallOrator__toggleIcon CallOrator__toggleIcon--withAnimation"></i>
                          </button>
                          <aside className={'CallOrator__others'}>
                            <ul className={'CallOrator__list'}>
                              <li
                                className={'CallOrator__participant'}
                                data-testid="calls_sausage_participant"
                                data-calls-id="participant"
                              >
                                <div className={'Participant__wrapper'}>
                                  <article
                                    className={
                                      'Participant Participant--withAnimation Participant--isInteractive'
                                    }
                                    aria-label="Вы"
                                    participant-id="519282746:d0"
                                    tabIndex="0"
                                    role="button"
                                  >
                                    {call.video && isMayCamera ? (
                                      <div
                                        className={
                                          'react-transform-wrapper transform-component-module_wrapper__SPB86 VideoZoomWrapper'
                                        }
                                      >
                                        <div
                                          className={
                                            'react-transform-component transform-component-module_content__FBWxo VideoZoomWrapper__content'
                                          }
                                        >
                                          <div className={'Participant__main'}>
                                            <video
                                              autoPlay
                                              ref={youVideo}
                                              playsInline
                                              muted
                                              crossOrigin={'anonymous'}
                                              preload="metadata"
                                              disablePictureInPicture
                                              className={'Video Video--visible'}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        className={
                                          'BlurredBackground Participant__main'
                                        }
                                        style={{
                                          background:
                                            'radial-gradient(circle, rgb(56, 58, 58) 40%, rgb(10, 10, 10) 100%)',
                                        }}
                                      >
                                        <img
                                          src={
                                            user.user_avatar_cover
                                              ? getAvatarUrl(
                                                  user.user_avatar_cover,
                                                )
                                              : user.user_picture
                                                ? getAvatarUrl(
                                                    user.user_picture,
                                                  )
                                                : `${process.env.REACT_APP_INFO_BASE_URL}/${siteInfo.avatar}`
                                          }
                                          alt={user.user_lastname}
                                          draggable="false"
                                          className="CallPhoto Participant__offsetCard CallPhoto--64"
                                          data-testid="calls_call_photo_visible"
                                        />
                                      </div>
                                    )}
                                    <footer className="Participant__footer">
                                      <div className="Participant__indicators Participant__indicators--footer"></div>
                                      <span className="Participant__name">
                                        Вы {'  '}
                                      </span>
                                      <i
                                        className={
                                          isMicrophone
                                            ? `fa  fa-microphone-alt vkuiIcon vkuiIcon--16 vkuiIcon--w-16 vkuiIcon--h-16 vkuiIcon--microphone_slash_16 Participant__statusIcon--mic`
                                            : `fa  fa-microphone-alt-slash vkuiIcon vkuiIcon--16 vkuiIcon--w-16 vkuiIcon--h-16 vkuiIcon--microphone_slash_16 Participant__statusIcon--mic`
                                        }
                                      ></i>
                                    </footer>
                                  </article>
                                </div>
                              </li>
                            </ul>
                          </aside>
                        </article>
                        <div
                          className="CallsReactionsColumn"
                          aria-live="polite"
                        ></div>
                      </div>
                      <div className={'Call__chat'}>
                        <div className="InCallChatContainer-module__container--PkjJs"></div>
                      </div>
                    </div>
                    <footer className={'Call__footer'}>
                      <div className={'Call__footerGroup'}>
                        <section
                          className={
                            'Group-module__group--GDQhT Group-module__groupPaddingM--INYMU Group-module__groupModePlain--aVHF6 vkui--vkBase--dark vkuiTokensClassProvider--default-color vkuiInternalGroup vkuiGroup vkuiGroup--sizeX-regular vkuiGroup--mode-plain vkuiInternalGroup--mode-plain vkuiGroup--padding-m vkuiRootComponent'
                          }
                        >
                          <div
                            className={
                              'Call__controlPanelButtonsGroup Call__controlPanelButtonsGroup--left'
                            }
                          >
                            <div
                              className={'Calls__iconButton__tooltip'}
                              aria-describedby=":rb:"
                            >
                              <div className={'Calls__iconButton__wrapper'}>
                                <div
                                  className={
                                    'RoundedGroupItem-module__root--L3Wv7'
                                  }
                                >
                                  <button
                                    className={
                                      'vkuiIconButton vkuiTappable vkuiTappable--hasPointer-none vkuiClickable__resetButtonStyle vkuiClickable__host vkuiClickable__realClickable vkui-focus-visible vkuiRootComponent'
                                    }
                                    aria-label={'Открыть чат'}
                                    data-testid="calls_call_footer_button_chat"
                                  >
                                    <div className="ChatIconButton ChatIconButton--none ChatIconButton--withAnimation"></div>
                                    <span
                                      aria-hidden="true"
                                      className="vkuiTappable__stateLayer vkuiTappable__ripple"
                                    ></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="CallScreenShareButton__outer">
                              <div className="vkui__root">
                                <div
                                  className="Calls__iconButton__tooltip"
                                  aria-describedby=":rc:"
                                >
                                  <div className="Calls__iconButton__wrapper">
                                    <div className="RoundedGroupItem-module__root--L3Wv7">
                                      <button
                                        className="vkuiIconButton vkuiTappable vkuiTappable--hasPointer-none vkuiClickable__resetButtonStyle vkuiClickable__host vkuiClickable__realClickable vkui-focus-visible vkuiRootComponent"
                                        type="button"
                                        aria-label="Демонстрация экрана"
                                        data-testid="calls_call_footer_button_sharing"
                                        aria-expanded="false"
                                      >
                                        <i
                                          className={
                                            'fa fa-desktop vkuiIcon vkuiIcon--24 vkuiIcon--w-24 vkuiIcon--h-24 vkuiIcon--arrow_up_rectangle_24'
                                          }
                                        ></i>
                                        <span
                                          aria-hidden="true"
                                          className="vkuiTappable__stateLayer vkuiTappable__ripple"
                                        ></span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div className={'Call__footerGroup'}>
                        <section
                          className={
                            'Group-module__group--GDQhT Group-module__groupPaddingM--INYMU Group-module__groupModePlain--aVHF6 vkui--vkBase--light vkuiTokensClassProvider--default-color vkuiInternalGroup vkuiGroup vkuiGroup--sizeX-regular vkuiGroup--mode-plain vkuiInternalGroup--mode-plain vkuiGroup--padding-m vkuiRootComponent'
                          }
                        >
                          <div className={'Call__buttons'}>
                            <div
                              className={
                                'vkui--vkBase--dark vkuiTokensClassProvider--default-color CallReactionsButton'
                              }
                            >
                              <div className="CallReactionsButton__anchor--hand"></div>
                              <div className={'Calls__iconButton__wrapper'}>
                                <div
                                  className={
                                    'RoundedGroupItem-module__root--L3Wv7 RoundedGroupItem-module__rootOverlay--ach0v'
                                  }
                                >
                                  <button
                                    className={
                                      'vkuiIconButton vkuiTappable vkuiTappable--hasPointer-none vkuiClickable__resetButtonStyle vkuiClickable__host vkuiClickable__realClickable vkui-focus-visible vkuiRootComponent'
                                    }
                                    aria-label={'Поднять руку'}
                                  >
                                    <svg
                                      viewBox="0 0 24 24"
                                      className={
                                        'vkuiIcon vkuiIcon--24 vkuiIcon--w-24 vkuiIcon--h-24 vkuiIcon--hand_heart_filled_24'
                                      }
                                      fill="white"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M3.67375 7.06963C4.20509 6.8772 4.79271 7.15012 4.98625 7.6792L6.73173 12.451C6.86075 12.8037 7.2525 12.9857 7.60672 12.8574C7.96095 12.7291 8.14351 12.3392 8.01448 11.9864L5.45367 4.98568C5.26013 4.45659 5.53398 3.87169 6.06531 3.67926C6.59665 3.48683 7.18427 3.75975 7.37781 4.28884L9.58919 10.3343C9.71821 10.6871 10.11 10.869 10.4642 10.7407C10.8184 10.6124 11.001 10.2225 10.8719 9.86976L8.31114 2.86901C8.1176 2.33992 8.39144 1.75501 8.92278 1.56259C9.45411 1.37016 10.0417 1.64307 10.2353 2.17216L12.7961 9.17292C12.9251 9.52565 13.3169 9.70759 13.6711 9.57931C14.0253 9.45102 14.2079 9.06108 14.0788 8.70836L12.1004 3.29972C11.9069 2.77063 12.1807 2.18572 12.7121 1.9933C13.2434 1.80087 13.831 2.07378 14.0245 2.60287L17.4014 11.8344L18.6366 8.79447C18.8993 8.14785 19.6522 7.85196 20.2881 8.14538C20.8654 8.41178 21.1427 9.07219 20.9261 9.6676C20.5485 10.7053 19.9553 12.3357 19.4455 13.738C19.2714 13.7132 19.0927 13.6999 18.9099 13.6992C18.891 13.6991 18.8721 13.6993 18.8532 13.6999L18.6936 13.7043C18.6749 13.7048 18.6563 13.7056 18.6377 13.7067C18.0174 13.7431 17.4687 13.927 16.9981 14.1736C16.4537 13.8903 15.8198 13.6992 15.1053 13.6992C12.8452 13.6992 11.2 15.6141 11.2 17.7386C11.2 18.7032 11.3909 19.52 11.9841 20.3757C11.995 20.3915 12.0061 20.4073 12.0173 20.4231C7.90593 21.0189 6.5945 18.0329 6.17988 16.8994L3.06211 8.37605C2.86858 7.84696 3.14242 7.26206 3.67375 7.06963Z" />
                                      <path d="M15.1053 15.4991C15.628 15.4991 16.1016 15.6885 16.5307 15.9939L16.9992 16.3432L17.4677 15.9939C17.8563 15.7173 18.2814 15.5307 18.7431 15.5036L18.9028 15.4992C20.0618 15.5038 21 16.5047 21 17.7386C21 18.4092 20.8832 18.8502 20.5366 19.3502C20.1536 19.9027 19.4836 20.5364 18.2925 21.5218L17.1949 22.4299C17.0803 22.5247 16.9197 22.5247 16.8051 22.4299L15.7075 21.5218C14.5164 20.5364 13.8464 19.9027 13.4634 19.3502C13.1168 18.8502 13 18.4092 13 17.7386C13 16.5018 13.9425 15.4991 15.1053 15.4991Z" />
                                      <path d="M3.67375 7.06963C4.20509 6.8772 4.79271 7.15012 4.98625 7.6792L6.73173 12.451C6.86075 12.8037 7.2525 12.9857 7.60672 12.8574C7.96095 12.7291 8.14351 12.3392 8.01448 11.9864L5.45367 4.98568C5.26013 4.45659 5.53398 3.87169 6.06531 3.67926C6.59665 3.48683 7.18427 3.75975 7.37781 4.28884L9.58919 10.3343C9.71821 10.6871 10.11 10.869 10.4642 10.7407C10.8184 10.6124 11.001 10.2225 10.8719 9.86976L8.31114 2.86901C8.1176 2.33992 8.39144 1.75501 8.92278 1.56259C9.45411 1.37016 10.0417 1.64307 10.2353 2.17216L12.7961 9.17292C12.9251 9.52565 13.3169 9.70759 13.6711 9.57931C14.0253 9.45102 14.2079 9.06108 14.0788 8.70836L12.1004 3.29972C11.9069 2.77063 12.1807 2.18572 12.7121 1.9933C13.2434 1.80087 13.831 2.07378 14.0245 2.60287L17.4014 11.8344L18.6366 8.79447C18.8993 8.14785 19.6522 7.85196 20.2881 8.14538C20.8654 8.41178 21.1427 9.07219 20.9261 9.6676C20.5485 10.7053 19.9553 12.3357 19.4455 13.738C19.2714 13.7132 19.0927 13.6999 18.9099 13.6992C18.891 13.6991 18.8721 13.6993 18.8532 13.6999L18.6936 13.7043C18.6749 13.7048 18.6563 13.7056 18.6377 13.7067C18.0174 13.7431 17.4687 13.927 16.9981 14.1736C16.4537 13.8903 15.8198 13.6992 15.1053 13.6992C12.8452 13.6992 11.2 15.6141 11.2 17.7386C11.2 18.7032 11.3909 19.52 11.9841 20.3757C11.995 20.3915 12.0061 20.4073 12.0173 20.4231C7.90593 21.0189 6.5945 18.0329 6.17988 16.8994L3.06211 8.37605C2.86858 7.84696 3.14242 7.26206 3.67375 7.06963Z" />
                                      <path d="M15.1053 15.4991C15.628 15.4991 16.1016 15.6885 16.5307 15.9939L16.9992 16.3432L17.4677 15.9939C17.8563 15.7173 18.2814 15.5307 18.7431 15.5036L18.9028 15.4992C20.0618 15.5038 21 16.5047 21 17.7386C21 18.4092 20.8832 18.8502 20.5366 19.3502C20.1536 19.9027 19.4836 20.5364 18.2925 21.5218L17.1949 22.4299C17.0803 22.5247 16.9197 22.5247 16.8051 22.4299L15.7075 21.5218C14.5164 20.5364 13.8464 19.9027 13.4634 19.3502C13.1168 18.8502 13 18.4092 13 17.7386C13 16.5018 13.9425 15.4991 15.1053 15.4991Z" />
                                    </svg>
                                    <span
                                      aria-hidden="true"
                                      className="vkuiTappable__stateLayer vkuiTappable__ripple"
                                    ></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                'vkui--vkBase--dark vkuiTokensClassProvider--default-color CallReactionsButton'
                              }
                            >
                              <div className={'Calls__iconButton__wrapper'}>
                                <div
                                  className={
                                    'RoundedGroupItem-module__root--L3Wv7'
                                  }
                                >
                                  <button
                                    onClick={toggleCamera}
                                    className="vkuiIconButton vkuiTappable vkuiTappable--hasPointer-none vkuiClickable__resetButtonStyle vkuiClickable__host vkuiClickable__realClickable vkui-focus-visible vkuiRootComponent"
                                    type="button"
                                    aria-label="Выключить камеру"
                                    data-testid="calls_call_footer_button_camera"
                                  >
                                    {isMayCamera && call.video ? (
                                      <svg
                                        viewBox="0 0 24 24"
                                        fill="white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className={
                                          'vkuiIcon vkuiIcon--24 vkuiIcon--w-24 vkuiIcon--h-24 vkuiIcon--hand_heart_filled_24'
                                        }
                                      >
                                        <path d="M13.1542 5C14.4915 5 14.9764 5.13924 15.4653 5.4007C15.9542 5.66216 16.3378 6.04584 16.5993 6.53473C16.8608 7.02362 17 7.50855 17 8.84583V15.1542C17 16.4915 16.8608 16.9764 16.5993 17.4653C16.3378 17.9542 15.9542 18.3378 15.4653 18.5993C14.9764 18.8608 14.4915 19 13.1542 19H5.84583C4.50855 19 4.02362 18.8608 3.53473 18.5993C3.04584 18.3378 2.66216 17.9542 2.4007 17.4653C2.13924 16.9764 2 16.4915 2 15.1542V8.84583C2 7.50855 2.13924 7.02362 2.4007 6.53473C2.66216 6.04584 3.04584 5.66216 3.53473 5.4007C4.02362 5.13924 4.50855 5 5.84583 5H13.1542ZM22.7996 7.89691C22.9297 8.0701 23 8.28085 23 8.49745V15.5026C23 16.0549 22.5523 16.5026 22 16.5026C21.7834 16.5026 21.5726 16.4323 21.3994 16.3022L19.1997 14.65C19.074 14.5556 19 14.4075 19 14.2502V9.74979C19 9.59253 19.074 9.44443 19.1997 9.34999L21.3995 7.69786C21.8411 7.36619 22.4679 7.45531 22.7996 7.89691Z" />
                                      </svg>
                                    ) : (
                                      <svg
                                        viewBox="0 0 24 24"
                                        fill="white"
                                        className={
                                          'vkuiIcon vkuiIcon--24 vkuiIcon--w-24 vkuiIcon--h-24 vkuiIcon--hand_heart_filled_24'
                                        }
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M2.5144 4.24234C2.15118 3.90302 1.58166 3.92239 1.24234 4.28561C0.90302 4.64883 0.922392 5.21835 1.28561 5.55767L2.37786 6.57805C2.13114 7.053 2 7.54804 2 8.84583V15.1542C2 16.4915 2.13924 16.9764 2.4007 17.4653C2.66216 17.9542 3.04584 18.3378 3.53473 18.5993C4.02362 18.8608 4.50855 19 5.84583 19H13.1542C14.3624 19 14.8748 18.8863 15.3231 18.6716L16.4856 19.7577C16.8489 20.097 17.4184 20.0776 17.7577 19.7144C18.097 19.3512 18.0776 18.7817 17.7144 18.4423L2.5144 4.24234Z" />
                                        <path d="M16.999 15.3463L5.92401 5H13.1542C14.4915 5 14.9764 5.13924 15.4653 5.4007C15.9542 5.66216 16.3378 6.04584 16.5993 6.53473C16.8608 7.02362 17 7.50855 17 8.84583C17 11.0123 17.0227 13.18 16.999 15.3463Z" />
                                        <path d="M23 8.49745V15.5026C23 16.3177 22.0359 16.7802 21.3994 16.3022L19.1997 14.65C19.074 14.5556 19 14.4075 19 14.2502V9.74979C19 9.59253 19.074 9.44443 19.1997 9.34999L21.3995 7.69786C22.0512 7.20835 23 7.70148 23 8.49745Z" />
                                      </svg>
                                    )}

                                    <span
                                      aria-hidden="true"
                                      className="vkuiTappable__stateLayer vkuiTappable__ripple"
                                    ></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                'vkui--vkBase--dark vkuiTokensClassProvider--default-color CallReactionsButton'
                              }
                            >
                              <div className={'Calls__iconButton__wrapper'}>
                                <div
                                  className={
                                    'RoundedGroupItem-module__root--L3Wv7'
                                  }
                                >
                                  <button
                                    className="vkuiIconButton vkuiTappable vkuiTappable--hasPointer-none vkuiClickable__resetButtonStyle vkuiClickable__host vkuiClickable__realClickable vkui-focus-visible vkuiRootComponent"
                                    type="button"
                                    aria-label="Выключить микрофон"
                                    data-testid="calls_call_footer_button_camera"
                                    onClick={toggleMicrophone}
                                  >
                                    {isMicrophone ? (
                                      <svg
                                        fill="white"
                                        viewBox="0 0 24 24"
                                        className={
                                          'vkuiIcon vkuiIcon--24 vkuiIcon--w-24 vkuiIcon--h-24 vkuiIcon--hand_heart_filled_24'
                                        }
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M12 2C9.79086 2 8 3.79086 8 6V11C8 13.2091 9.79086 15 12 15C14.2091 15 16 13.2091 16 11V6C16 3.79086 14.2091 2 12 2Z" />
                                        <path d="M5.10012 12C4.54878 12 4.11826 12.4856 4.25702 13.0192C5.07861 16.1786 7.78268 18.5785 11.1 18.9499V21.1C11.1 21.5971 11.5029 22 12 22C12.4971 22 12.9 21.5971 12.9 21.1V18.9499C16.2173 18.5785 18.9214 16.1786 19.743 13.0192C19.8818 12.4856 19.4512 12 18.8999 12C18.4502 12 18.074 12.329 17.9466 12.7603C17.1879 15.327 14.8127 17.2 12 17.2C9.18732 17.2 6.81208 15.327 6.05345 12.7603C5.92599 12.329 5.54981 12 5.10012 12Z" />
                                      </svg>
                                    ) : (
                                      <svg
                                        viewBox="0 0 24 24"
                                        fill="white"
                                        className={
                                          'vkuiIcon vkuiIcon--24 vkuiIcon--w-24 vkuiIcon--h-24 vkuiIcon--hand_heart_filled_24'
                                        }
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M9.0161 4.47403C8.75296 4.14618 8.7142 3.68008 8.99137 3.36399C9.72451 2.52788 10.8006 2 12 2C14.2091 2 16 3.79086 16 6V11C16 11.7907 15.1386 12.1021 14.6184 11.4539L9.0161 4.47403ZM17.5285 15.0796C17.9017 15.5445 18.6127 15.5431 18.9133 15.0283C19.2761 14.4071 19.5575 13.7325 19.743 13.0192C19.8818 12.4856 19.4512 12 18.8999 12C18.4502 12 18.074 12.329 17.9466 12.7603C17.824 13.1748 17.6594 13.5712 17.4575 13.9446C17.2613 14.3075 17.2702 14.7578 17.5285 15.0796Z"
                                        />
                                        <path d="M8 11C8 13.4935 10.3704 15.4067 12.79 14.922L14.2732 16.77C13.5693 17.0475 12.8025 17.2 12 17.2C9.18732 17.2 6.81208 15.327 6.05345 12.7603C5.92599 12.329 5.54981 12 5.10012 12C4.54878 12 4.11826 12.4856 4.25702 13.0192C5.07861 16.1786 7.78268 18.5785 11.1 18.9499V21.1C11.1 21.5971 11.5029 22 12 22C12.4971 22 12.9 21.5971 12.9 21.1V18.9499C13.8005 18.8491 14.6558 18.5988 15.4406 18.2244L17.3981 20.6634C17.7093 21.051 18.2757 21.113 18.6634 20.8019C19.051 20.4907 19.113 19.9243 18.8019 19.5366L6.60188 4.33665C6.29075 3.94902 5.72429 3.887 5.33665 4.19813C4.94901 4.50926 4.88699 5.07572 5.19812 5.46336L8 8.95422V11Z" />
                                      </svg>
                                    )}
                                    <span
                                      aria-hidden="true"
                                      className="vkuiTappable__stateLayer vkuiTappable__ripple"
                                    ></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div
                              className={'Calls__iconButton__tooltip'}
                              aria-describedby=":rg:"
                            >
                              <div className={'Calls__iconButton__wrapper'}>
                                <div
                                  className={
                                    'RoundedGroupItem-module__root--L3Wv7 RoundedGroupItem-module__rootModeNegative--kVvq1'
                                  }
                                >
                                  <button
                                    className="vkuiIconButton vkuiTappable vkuiTappable--hasPointer-none vkuiClickable__resetButtonStyle vkuiClickable__host vkuiClickable__realClickable vkui-focus-visible vkuiTappable--hovered-background vkuiRootComponent"
                                    type="button"
                                    aria-label="Выйти"
                                    data-testid="calls_call_footer_button_leave_call"
                                    onClick={handleEndCall}
                                  >
                                    <svg
                                      viewBox="0 0 24 24"
                                      fill="white"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className={
                                        'vkuiIcon vkuiIcon--28 vkuiIcon--w-28 vkuiIcon--h-28 vkuiIcon--cancel_outline_28'
                                      }
                                    >
                                      <path d="M7.5364 6.2636C7.18492 5.91213 6.61508 5.91213 6.2636 6.2636C5.91213 6.61508 5.91213 7.18492 6.2636 7.5364L10.7272 12L6.2636 16.4636C5.91213 16.8151 5.91213 17.3849 6.2636 17.7364C6.61508 18.0879 7.18492 18.0879 7.5364 17.7364L12 13.2728L16.4636 17.7364C16.8151 18.0879 17.3849 18.0879 17.7364 17.7364C18.0879 17.3849 18.0879 16.8151 17.7364 16.4636L13.2728 12L17.7364 7.5364C18.0879 7.18492 18.0879 6.61508 17.7364 6.2636C17.3849 5.91213 16.8151 5.91213 16.4636 6.2636L12 10.7272L7.5364 6.2636Z" />
                                    </svg>
                                    <span
                                      aria-hidden="true"
                                      className="vkuiTappable__stateLayer vkuiTappable__ripple"
                                    ></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div className={'Call__footerGroup'}>
                        <section
                          className={
                            'Group-module__group--GDQhT Group-module__groupPaddingM--INYMU Group-module__groupModePlain--aVHF6 vkui--vkBase--dark vkuiTokensClassProvider--default-color vkuiInternalGroup vkuiGroup vkuiGroup--sizeX-regular vkuiGroup--mode-plain vkuiInternalGroup--mode-plain vkuiGroup--padding-m vkuiRootComponent'
                          }
                        >
                          <div
                            className={
                              'Call__controlPanelButtonsGroup Call__controlPanelButtonsGroup--right'
                            }
                          >
                            <div
                              className={'Calls__iconButton__tooltip'}
                              aria-describedby=":rh:"
                            >
                              <div className={'Calls__iconButton__wrapper'}>
                                <div
                                  className={
                                    'RoundedGroupItem-module__root--L3Wv7'
                                  }
                                >
                                  <button
                                    className="vkuiIconButton vkuiTappable vkuiTappable--hasPointer-none vkuiClickable__resetButtonStyle vkuiClickable__host vkuiClickable__realClickable vkui-focus-visible vkuiTappable--hovered-background vkuiRootComponent"
                                    type="button"
                                    aria-label="Участники"
                                  >
                                    <svg
                                      viewBox="0 0 24 24"
                                      fill="white"
                                      className="vkuiIcon vkuiIcon--24 vkuiIcon--w-24 vkuiIcon--h-24 vkuiIcon--users_24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M16.5 12.7496C18.7941 12.7496 23 13.6068 23 17.0353C23 18.5353 22.6176 18.7496 21.8529 18.7496L15.7628 18.7504C15.9208 18.2613 16 17.6877 16 17.0353C16 15.3125 15.3686 13.9686 14.3282 12.9735C15.1113 12.813 15.8763 12.7496 16.5 12.7496ZM7.5 12.7496C9.79412 12.7496 14 13.6068 14 17.0353C14 18.5353 13.6176 18.7496 12.8529 18.7496H2.14706C1.38235 18.7496 1 18.5353 1 17.0353C1 13.6068 5.20588 12.7496 7.5 12.7496ZM7.5 5.24963C9.1575 5.24963 10.5 6.59213 10.5 8.24963C10.5 9.90713 9.1575 11.2496 7.5 11.2496C5.8425 11.2496 4.5 9.90713 4.5 8.24963C4.5 6.59213 5.8425 5.24963 7.5 5.24963ZM16.5 5.24963C18.1575 5.24963 19.5 6.59213 19.5 8.24963C19.5 9.90713 18.1575 11.2496 16.5 11.2496C14.8425 11.2496 13.5 9.90713 13.5 8.24963C13.5 6.59213 14.8425 5.24963 16.5 5.24963Z" />
                                    </svg>

                                    <span
                                      aria-hidden="true"
                                      className="vkuiTappable__stateLayer vkuiTappable__ripple"
                                    ></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div
                              className={'Calls__iconButton__tooltip'}
                              aria-describedby=":r15:"
                            >
                              <div className={'Calls__iconButton__wrapper'}>
                                <div
                                  className={
                                    'RoundedGroupItem-module__root--L3Wv7'
                                  }
                                >
                                  <button
                                    className="vkuiIconButton vkuiTappable vkuiTappable--hasPointer-none vkuiClickable__resetButtonStyle vkuiClickable__host vkuiClickable__realClickable vkui-focus-visible vkuiTappable--hovered-background vkuiRootComponent"
                                    type="button"
                                    onClick={toggleFullscreen}
                                    aria-label="Развернуть"
                                    data-testid="calls_call_footer_button_fullscreen"
                                  >
                                    {document.fullscreenElement ? (
                                      <svg
                                        viewBox="0 0 24 24"
                                        fill="white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="vkuiIcon vkuiIcon--24 vkuiIcon--w-24 vkuiIcon--h-24 vkuiIcon--fullscreen_24"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M5.00001 13.9C5.00001 13.4029 5.40295 13 5.90001 13H10.1C10.5971 13 11 13.4029 11 13.9V18.1C11 18.5971 10.5971 19 10.1 19C9.60294 19 9.2 18.5971 9.2 18.1V16.0728L5.5364 19.7364C5.18492 20.0879 4.61508 20.0879 4.2636 19.7364C3.91213 19.3849 3.91213 18.8151 4.2636 18.4636L7.92721 14.8H5.90001C5.40295 14.8 5.00001 14.3971 5.00001 13.9Z"
                                        />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M19.7364 4.2636C20.0879 4.61508 20.0879 5.18492 19.7364 5.5364L16.0728 9.2H18.0982C18.5952 9.2 18.9982 9.60294 18.9982 10.1C18.9982 10.5971 18.5952 11 18.0982 11H13.9C13.4029 11 13 10.5971 13 10.1V5.90001C13 5.40295 13.4029 5.00001 13.9 5.00001C14.3971 5.00001 14.8 5.40295 14.8 5.90001V7.92721L18.4636 4.2636C18.8151 3.91213 19.3849 3.91213 19.7364 4.2636Z"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        className="vkuiIcon vkuiIcon--24 vkuiIcon--w-24 vkuiIcon--h-24 vkuiIcon--fullscreen_24"
                                        viewBox="0 0 24 24"
                                        fill="white"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M14 4.9C14 4.40294 14.4029 4 14.9 4H19.1C19.5971 4 20 4.40294 20 4.9V9.10001C20 9.59706 19.5971 10 19.1 10C18.6029 10 18.2 9.59706 18.2 9.10001V7.07279L14.5364 10.7364C14.1849 11.0879 13.6151 11.0879 13.2636 10.7364C12.9121 10.3849 12.9121 9.81508 13.2636 9.46361L16.9272 5.8H14.9C14.4029 5.8 14 5.39706 14 4.9Z"
                                        />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M10.7364 13.2636C11.0879 13.6151 11.0879 14.1849 10.7364 14.5364L7.07279 18.2H9.09818C9.59524 18.2 9.99818 18.6029 9.99818 19.1C9.99818 19.5971 9.59524 20 9.09818 20H4.9C4.40294 20 4 19.5971 4 19.1V14.9C4 14.403 4.40294 14 4.9 14C5.39706 14 5.8 14.403 5.8 14.9V16.9272L9.46361 13.2636C9.81508 12.9121 10.3849 12.9121 10.7364 13.2636Z"
                                        />
                                      </svg>
                                    )}
                                    <span
                                      aria-hidden="true"
                                      className="vkuiTappable__stateLayer vkuiTappable__ripple"
                                    ></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </footer>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CallModal
