import React from 'react'
import styles from './index.module.scss'
import { getAvatarUrl } from '../../api'

function MessagesList({ message, userInfo, siteInfo }) {
  return (
    <div
      className={
        userInfo.user_id === message.user_id
          ? styles.messageItemMe
          : styles.messageItem
      }
    >
      <div
        className={
          userInfo.user_id === message.user_id
            ? styles.messageItemInnerM
            : styles.messageItemInner
        }
      >
        {userInfo.user_id !== message.user_id && (
          <img
            src={
              message.user.user_avatar_cover
                ? getAvatarUrl(message.user.user_avatar_cover)
                : message.user.user_picture
                  ? getAvatarUrl(message.user.user_picture)
                  : `${process.env.REACT_APP_INFO_BASE_URL}/${siteInfo.avatar}`
            }
            alt={message.user.user_lastname}
            className={styles.avatar}
          />
        )}
        {message.type === 'TEXT' && (
          <div
            dangerouslySetInnerHTML={{ __html: message.message }}
            className={
              userInfo.user_id === message.user_id
                ? styles.messageTextM
                : styles.messageText
            }
          />
        )}
        {userInfo.user_id === message.user_id && (
          <img
            src={
              userInfo.user_avatar_cover
                ? getAvatarUrl(userInfo.user_avatar_cover)
                : userInfo.user_picture
                  ? getAvatarUrl(userInfo.user_picture)
                  : `${process.env.REACT_APP_INFO_BASE_URL}/${siteInfo.avatar}`
            }
            alt={userInfo.user_lastname}
            className={styles.avatarM}
          />
        )}
      </div>
    </div>
  )
}

export default MessagesList
