import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import * as ActionTypes from '../constants/stores.constants'
import * as actions from '../actions/stores.actions'
import * as api from '../api/stores.api'
import { END, eventChannel } from 'redux-saga'
import { socketUrl } from '../api'

const convertBlobUrlToFile = async (blobUrl, fileName) => {
  try {
    const response = await fetch(blobUrl)
    const blob = await response.blob()
    return new File([blob], fileName, { type: blob.type })
  } catch (error) {
    console.error('Error creating file from blob:', error)
    return null
  }
}

async function createUploader(action) {
  let emit
  const chan = eventChannel((emitter) => {
    emit = emitter
    return () => {}
  })
  const formData = new FormData()
  const files = await Promise.all(
    action.files.map(async (file, index) => {
      const fileType = file.includes('video') ? 'video' : 'image'
      const extension = fileType === 'video' ? '.mp4' : '.jpg'
      return await convertBlobUrlToFile(file, `file-${index + 1}${extension}`)
    }),
  )
  files.forEach((file) => {
    formData.append('files[]', file)
  })
  formData.append('text', JSON.stringify({ text: action.text }))
  const onUploadProgress = ({ total, loaded }) => {
    const percentage = Math.round((loaded * 100) / total)
    emit(percentage)
    if (percentage === 100) emit(END)
  }
  const uploadPromise = api.creteStores(formData, onUploadProgress)
  return [uploadPromise, chan]
}

function* uploadProgressWatcher(chan) {
  while (true) {
    const progress = yield take(chan)
    yield put(actions.createStoresProgress(progress))
  }
}

const updateStoresItems = (data) => {
  return new Promise(() => {
    socketUrl.emit('updateStares', data)
  })
}

export function* createStores(action) {
  try {
    const [uploadPromise, chan] = yield call(createUploader, action.payload)
    yield fork(uploadProgressWatcher, chan)
    const response = yield call(() => uploadPromise)
    if (response.type === 'items') {
      yield put(actions.updateItemsStores(response.store))
      yield call(updateStoresItems, response.store)
    } else if (response.type === 'newStories') {
      yield put(actions.updateStores(response.store))
      yield call(updateStoresItems, response.store)
    }
  } catch (error) {
    yield put(actions.storeInfoError(error))
    toast.error(error.error)
  }
}

export function* storesInfo() {
  try {
    const response = yield call(api.storesInfo)
    if (response) {
      yield put(actions.storeInfoSuccess(response))
    }
  } catch (error) {
    yield put(actions.storeInfoError(error))
    toast.error(error.error)
  }
}

export default function* storesSaga() {
  yield all([
    takeEvery(ActionTypes.ALL_STORES_INFO_REQUEST, storesInfo),
    takeEvery(ActionTypes.CREATE_STORES_REQUEST, createStores),
  ])
}
