import React from 'react'
import styles from '../index.module.scss'

function PostActions({ post }) {
  return (
    <div className={styles.postActionsContainer}>
      <div className={styles.Action}>{post.feeling_action}</div>
      <div className={styles.ActionValue}>{post.feeling_value}</div>
    </div>
  )
}
export default PostActions
