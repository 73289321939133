import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import * as actionsPost from '../../actions/post.actions'
import { useDispatch, useSelector } from 'react-redux'
import ModalIframeVideo from '../ModalIframeVideo'
import { formatTime } from '../../utils'
import { actionsButtons } from '../../utils/datas/actionsCreatePost.data'
import * as actionUserInfo from '../../actions/app.actions'
import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps'
import { getAvatarUrl } from '../../api'
import NewsPost from '../NewsPost'
import EmojiPicker from 'emoji-picker-react'

function ModalCreatePost({
  setIsCreatePostModal,
  theme,
  isCreatePostModal,
  translate,
  userInfo,
  userType = 'user',
  patterns,
}) {
  const coords = useSelector((state) => state.app.coords)
  const dispatch = useDispatch()
  const [textInput, setTextInput] = useState('')
  const [inputHtml, setInputHtml] = useState(null)
  const [inputFile, setInputFile] = useState([])
  const [inputFileVideo, setInputFileVideo] = useState([])
  const [inputFilePrev, setInputFilePrev] = useState([])
  const [isTypeVideo, setIsTypeVideo] = useState(false)
  const [isIframeVideo, setIsIframeVideo] = useState(false)
  const [isFileVideo, setIsFileVideo] = useState(false)
  const [isFileImg, setIsFileImg] = useState(false)
  const [isVoice, setIsVoice] = useState(false)
  const [isVoiceCreate, setIsVoiceCreate] = useState(false)
  const [isAudio, setIsAudio] = useState(false)
  const [isVoiceUrl, setIsVoiceUrl] = useState([])
  const [isAudioUrl, setIsAudioUrl] = useState([])
  const [isMorButtons, setIsMorButtons] = useState(false)
  const [recording, setRecording] = useState(false)
  const [isActions, setIsActions] = useState(false)
  const [isLocations, setIsLocations] = useState(false)
  const [isLocationsSend, setIsLocationsSend] = useState(false)
  const [isTypeActions, setIsTypeActions] = useState(true)
  const [typeActions, setTypeActions] = useState('')
  const [typeActionsText, setTypeActionsText] = useState('')
  const [recordTime, setRecordTime] = useState(0)
  const mediaRecorderRef = useRef(null)
  const audioChunks = useRef([])
  const recordInterval = useRef(null)
  const streamRef = useRef(null)
  const [isArticle, setIsArticle] = useState(false)
  const [isPrivacy, setIsPrivacy] = useState('public')
  const [isAnonymous, setIsAnonymous] = useState(false)
  const [isTypePost, setIsTypePost] = useState(false)
  const [isGraph, setIsGraph] = useState(false)
  const [isEmoji, setIsEmoji] = useState(false)
  const [patternColor, setPatternsColor] = useState({
    pattern_id: null,
    background_image: '',
  })

  const onPostNoFiles = (data) => {
    dispatch(actionsPost.createPostNoFiles(data))
    setIsArticle(false)
    setRecordTime(0)
    setTypeActionsText('')
    setTypeActions('')
    setIsTypeActions(true)
    setIsLocations(false)
    setIsActions(false)
    setRecording(false)
    setIsMorButtons(false)
    setIsAudioUrl([])
    setIsVoiceUrl([])
    setIsAudio(false)
    setIsVoiceCreate(false)
    setIsVoice(false)
    setIsFileImg(false)
    setInputFile([])
    setTextInput('')
    setInputFileVideo([])
    setInputFilePrev([])
    setIsTypeVideo(false)
    setIsIframeVideo(false)
    setIsFileVideo(false)
    setIsCreatePostModal(false)
  }
  const onPostFiles = (data) => {
    dispatch(actionsPost.createPost(data))
    setIsArticle(false)
    setRecordTime(0)
    setTypeActionsText('')
    setTypeActions('')
    setIsTypeActions(true)
    setIsLocations(false)
    setIsActions(false)
    setRecording(false)
    setIsMorButtons(false)
    setIsAudioUrl([])
    setIsVoiceUrl([])
    setIsAudio(false)
    setIsVoiceCreate(false)
    setIsVoice(false)
    setIsFileImg(false)
    setInputFile([])
    setTextInput('')
    setInputFileVideo([])
    setInputFilePrev([])
    setIsTypeVideo(false)
    setIsIframeVideo(false)
    setIsFileVideo(false)
    setIsCreatePostModal(false)
  }
  const onTypeVideo = () => {
    if (!isLocations && !isVoiceCreate && !isFileVideo) {
      setIsVoice(false)
      setIsTypeVideo(!isTypeVideo)
      setIsArticle(false)
      setRecordTime(0)
      setTypeActionsText('')
      setTypeActions('')
      setIsTypeActions(true)
      setIsLocations(false)
      setIsActions(false)
      setRecording(false)
      setIsMorButtons(false)
      setIsAudioUrl([])
      setIsVoiceUrl([])
      setIsAudio(false)
      setIsVoiceCreate(false)
      setIsFileImg(false)
      setInputFile([])
    }
  }
  const onTypeAudio = () => {
    if (!isAudio && !isLocations && !isFileVideo && !isFileImg) {
      setIsVoice(!isVoice)
      setIsTypeVideo(false)
      setIsArticle(false)
      setRecordTime(0)
      setTypeActionsText('')
      setTypeActions('')
      setIsTypeActions(true)
      setIsLocations(false)
      setIsActions(false)
      setIsMorButtons(false)
      setIsFileImg(false)
      setInputFile([])
      setInputFileVideo([])
      setInputFilePrev([])
      setIsIframeVideo(false)
      setIsFileVideo(false)
    }
  }

  const onSelectImages = () => {
    if (!isLocations && !isVoiceCreate && !isFileImg) {
      setIsVoice(false)
      setIsTypeVideo(false)
      setIsArticle(false)
      setRecordTime(0)
      setTypeActionsText('')
      setTypeActions('')
      setIsTypeActions(true)
      setIsLocations(false)
      setIsActions(false)
      setRecording(false)
      setIsMorButtons(false)
      setIsAudioUrl([])
      setIsVoiceUrl([])
      setIsAudio(false)
      setIsVoiceCreate(false)
      setIsFileImg(false)
      setInputFileVideo([])
      setIsIframeVideo(false)
      setIsFileVideo(false)
      document.querySelector('.selectImage').click()
    }
  }

  const onActions = () => {
    if (!isLocations && !isVoiceCreate && !isFileVideo && !isFileImg) {
      setIsVoice(false)
      setIsTypeVideo(false)
      setIsActions(!isActions)
      setIsTypeActions(true)
      setTypeActions('')
      setTypeActionsText('')
      setIsArticle(false)
      setRecordTime(0)
      setIsTypeActions(true)
      setIsLocations(false)
      setRecording(false)
      setIsMorButtons(false)
      setIsAudioUrl([])
      setIsVoiceUrl([])
      setIsAudio(false)
      setIsVoiceCreate(false)
      setIsFileImg(false)
      setInputFile([])
      setInputFileVideo([])
      setInputFilePrev([])
      setIsIframeVideo(false)
      setIsFileVideo(false)
    }
  }
  const onSelectVideo = () => {
    document.querySelector('.selectVideo').click()
  }
  const onSelectAudio = () => {
    document.querySelector('.selectAudio').click()
  }

  const onChangeUploadFile = (event) => {
    const selectedFiles = event.target.files
    const selectedFilesArray = Array.from(selectedFiles)
    setIsFileVideo(true)
    setIsAudio(true)
    const filesArray = selectedFilesArray.map((file) => {
      return {
        url: URL.createObjectURL(file),
        type: file.type.startsWith('video') ? 'video' : 'image',
      }
    })
    setInputFile((previousImages) => previousImages.concat(filesArray))
    setInputFilePrev((previousImages) => previousImages.concat(filesArray))
    event.target.value = ''
  }

  const onChangeUploadVideo = (event) => {
    const selectedFiles = event.target.files
    const selectedFilesArray = Array.from(selectedFiles)
    setIsFileImg(true)
    setIsAudio(true)
    const filesArray = selectedFilesArray.map((file) => {
      return {
        url: URL.createObjectURL(file),
        type: file.type.startsWith('video') ? 'video' : 'image',
      }
    })
    setInputFileVideo((previousImages) => previousImages.concat(filesArray))
    setInputFilePrev((previousImages) => previousImages.concat(filesArray))
    event.target.value = ''
  }

  const onChangeUploadAudio = (event) => {
    const selectedFiles = event.target.files
    const selectedFilesArray = Array.from(selectedFiles)
    setIsFileImg(true)
    setIsFileVideo(true)
    const filesArray = selectedFilesArray.map((file) => {
      return {
        url: URL.createObjectURL(file),
        type: 'audio',
      }
    })
    setIsAudioUrl((previousImages) => previousImages.concat(filesArray))
    event.target.value = ''
  }
  function deleteHandler(image) {
    setInputFile(inputFile.filter((e) => e.url !== image))
    setInputFilePrev(inputFilePrev.filter((e) => e.url !== image))
    URL.revokeObjectURL(image)
  }
  function deleteHandlerVideo(image) {
    setInputFileVideo(inputFileVideo.filter((e) => e.url !== image))
    setInputFilePrev(inputFilePrev.filter((e) => e.url !== image))
    URL.revokeObjectURL(image)
  }

  function deleteHandlerAudio(audio) {
    if (isVoiceUrl.length > 0) {
      setIsVoiceUrl(isVoiceUrl.filter((e) => e.url !== audio))
    } else {
      setIsAudioUrl(isAudioUrl.filter((e) => e.url !== audio))
    }
    URL.revokeObjectURL(audio)
  }

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      streamRef.current = stream
      mediaRecorderRef.current = new MediaRecorder(stream)
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data)
        }
      }

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunks.current, { type: 'audio/mp3' })
        const audioUrl = URL.createObjectURL(audioBlob)
        setIsVoiceUrl((prevState) => [
          ...prevState,
          { url: audioUrl, type: 'audio' },
        ])
        audioChunks.current = []
        setRecordTime(0)
      }
      setIsFileVideo(true)
      setIsFileImg(true)

      mediaRecorderRef.current.start()
      setRecording(true)
      recordInterval.current = setInterval(() => {
        setRecordTime((prevTime) => prevTime + 1)
      }, 1000)
    } catch (err) {
      console.error('Error accessing microphone', err)
    }
  }

  const stopRecording = () => {
    mediaRecorderRef.current.stop()
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop())
    }
    setRecording(false)
    setIsVoiceCreate(false)
    clearInterval(recordInterval.current)
  }

  const onMoreButtons = () => {
    if (
      inputFileVideo.length <= 0 &&
      inputFilePrev.length <= 0 &&
      isAudioUrl.length <= 0 &&
      isVoiceUrl.length <= 0 &&
      !isActions
    ) {
      setIsMorButtons(!isMorButtons)
      setIsArticle(false)
      setRecordTime(0)
      setTypeActionsText('')
      setTypeActions('')
      setIsTypeActions(true)
      setIsLocations(false)
      setIsActions(false)
      setRecording(false)
      setIsAudioUrl([])
      setIsVoiceUrl([])
      setIsAudio(false)
      setIsVoiceCreate(false)
      setIsVoice(false)
      setIsFileImg(false)
      setInputFile([])
      setTextInput('')
      setInputFileVideo([])
      setInputFilePrev([])
      setIsTypeVideo(false)
      setIsIframeVideo(false)
      setIsFileVideo(false)
    }
  }

  useEffect(() => {
    if (
      inputFileVideo.length <= 0 &&
      inputFilePrev.length <= 0 &&
      isAudioUrl.length <= 0 &&
      isVoiceUrl.length <= 0
    ) {
      setIsFileVideo(false)
      setIsFileImg(false)
      setIsAudio(false)
    }
  }, [inputFileVideo, inputFilePrev, isAudioUrl, isVoiceUrl])

  const audio = isVoiceUrl.length > 0 ? isVoiceUrl : isAudioUrl

  const onLocation = () => {
    if (navigator.geolocation && !isArticle && !isActions && !isVoiceCreate) {
      navigator.geolocation.getCurrentPosition((position) => {
        dispatch(
          actionUserInfo.toggleCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }),
        )
        setIsLocations(!isLocations)
        setIsLocationsSend(true)
      })
    }
  }

  const onArticle = () => {
    if (!isLocations && !isActions && !isVoiceCreate) {
      setIsArticle(!isArticle)
      setRecordTime(0)
      setTypeActionsText('')
      setTypeActions('')
      setIsTypeActions(true)
      setIsLocations(false)
      setIsActions(false)
      setRecording(false)
      setIsMorButtons(false)
      setIsAudioUrl([])
      setIsVoiceUrl([])
      setIsAudio(false)
      setIsVoiceCreate(false)
      setIsVoice(false)
      setIsFileImg(false)
      setInputFile([])
      setTextInput('')
      setInputFileVideo([])
      setInputFilePrev([])
      setIsTypeVideo(false)
      setIsIframeVideo(false)
      setIsFileVideo(false)
    }
  }

  const onGraph = () => {
    setIsGraph(!isGraph)
  }

  const onCreatePst = (type) => {
    if (type === 'photos') {
      onPostFiles({
        files: inputFile,
        text: textInput,
        user_type: userType,
        privacy: isPrivacy,
        is_anonymous: isAnonymous,
        post_type: 'photos',
        group_approved: false,
        is_hidden: false,
        feeling_action: null,
        feeling_value: null,
        type: '',
      })
    } else if (type === 'videos') {
      onPostFiles({
        files: inputFileVideo,
        text: textInput,
        user_type: userType,
        post_type: 'videos',
        privacy: isPrivacy,
        is_anonymous: isAnonymous,
        group_approved: false,
        is_hidden: false,
        feeling_action: null,
        feeling_value: null,
        type: '',
      })
    } else if (type === 'html') {
      onPostNoFiles({
        source_html: inputHtml,
        text: textInput,
        user_type: userType,
        post_type: 'videos',
        type: 'html',
        privacy: isPrivacy,
        is_anonymous: isAnonymous,
        group_approved: false,
        is_hidden: false,
        feeling_action: null,
        feeling_value: null,
        location: null,
        colored_pattern: patternColor.pattern_id,
      })
    } else if (type === 'text') {
      onPostNoFiles({
        text: textInput,
        location: null,
        post_type: 'text',
        user_type: userType,
        privacy: isPrivacy,
        is_anonymous: isAnonymous,
        group_approved: false,
        is_hidden: false,
        feeling_action: null,
        feeling_value: null,
        colored_pattern: patternColor.pattern_id,
        type: 'text',
      })
    } else if (type === 'voice') {
      onPostFiles({
        files: isVoiceUrl.length > 0 ? isVoiceUrl : isAudioUrl,
        text: textInput,
        user_type: userType,
        post_type: 'audio',
        privacy: isPrivacy,
        is_anonymous: isAnonymous,
        group_approved: false,
        is_hidden: false,
        feeling_action: typeActions,
        feeling_value: typeActionsText,
        type: '',
      })
    } else if (type === 'actions') {
      onPostNoFiles({
        text: textInput,
        location: null,
        feeling_action: typeActionsText,
        feeling_value: typeActionsText,
        user_type: userType,
        post_type: 'actions',
        privacy: isPrivacy,
        is_anonymous: isAnonymous,
        group_approved: false,
        is_hidden: false,
        colored_pattern: patternColor.pattern_id,
        type: 'actions',
      })
    } else if (type === 'actionsFiles') {
      onPostFiles({
        files:
          isVoiceUrl.length > 0
            ? isVoiceUrl
            : inputFile.length > 0
              ? inputFile
              : inputFileVideo.length > 0
                ? inputFileVideo
                : isAudioUrl,
        text: textInput,
        user_type: userType,
        post_type: 'actions',
        privacy: isPrivacy,
        is_anonymous: isAnonymous,
        group_approved: false,
        is_hidden: false,
        feeling_action: null,
        feeling_value: null,
        type: '',
      })
    } else if (type === 'location') {
      onPostNoFiles({
        text: textInput,
        location: coords,
        user_type: userType,
        post_type: 'location',
        privacy: isPrivacy,
        is_anonymous: isAnonymous,
        group_approved: false,
        is_hidden: false,
        feeling_action: null,
        feeling_value: null,
        colored_pattern: patternColor.pattern_id,
        type: 'location',
      })
    }
  }

  const actionsFiles =
    (isVoiceUrl.length > 0 && typeActions && typeActionsText) ||
    (inputFile.length > 0 && typeActions && typeActionsText) ||
    (inputFileVideo.length > 0 && typeActions && typeActionsText) ||
    (isAudioUrl.length > 0 && typeActions && typeActionsText)

  const isHtml =
    (isVoiceUrl.length <= 0 && typeActions && typeActionsText && inputHtml) ||
    (inputFile.length <= 0 && typeActions && typeActionsText && inputHtml) ||
    (inputFileVideo.length <= 0 &&
      typeActions &&
      typeActionsText &&
      inputHtml) ||
    (isAudioUrl.length <= 0 && typeActions && typeActionsText && inputHtml)

  const placeCaretAtEnd = (el) => {
    el.focus()
    const range = document.createRange()
    range.selectNodeContents(el)
    range.collapse(false)
    const sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)
  }

  return (
    <>
      <div
        className={styles.modalBg}
        onClick={() => {
          setIsCreatePostModal(!isCreatePostModal)
        }}
      />
      <div className={styles.modalContainer}>
        <div className={theme ? styles.modalInner : styles.modalInnerB}>
          <div className={styles.modalHeader}>
            <div></div>
            <div className={styles.title}>{translate('Добавить пост')}</div>
            <button
              type="button"
              className={styles.modalClose}
              onClick={() => {
                setIsCreatePostModal(!isCreatePostModal)
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#000000"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <>
            <div
              className={
                patternColor.pattern_id
                  ? styles.inputContainerP
                  : styles.inputContainer
              }
              style={{
                backgroundImage: patternColor.pattern_id
                  ? ` url(${process.env.REACT_APP_PSTS_BASE_URL}${patternColor.background_image})`
                  : 'none',
              }}
            >
              <div
                className={
                  patternColor.pattern_id ? styles.textareaP : styles.textarea
                }
                onClick={() => setIsEmoji(false)}
                role={'textbox'}
                onInput={(event) => {
                  setTextInput(event.target.innerHTML)
                  setTimeout(() => {
                    placeCaretAtEnd(event.target)
                  }, 0)
                }}
                suppressContentEditableWarning={true}
                dangerouslySetInnerHTML={{ __html: textInput }}
                contentEditable
                aria-multiline={true}
                aria-label="Что у вас нового?"
              ></div>
              <div className={styles.inputActionsSmile}>
                <button
                  className={styles.smile}
                  onClick={() => setIsEmoji(!isEmoji)}
                >
                  <img
                    src={
                      'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f600.png'
                    }
                    alt={''}
                    width={20}
                    height={20}
                  />
                </button>
              </div>
              {isEmoji && (
                <EmojiPicker
                  onMouseOut={() => setIsEmoji(false)}
                  className={styles.emojiPicker}
                  searchDisabled
                  onEmojiClick={(emoji) => {
                    setTextInput(
                      (prevState) =>
                        prevState +
                        `<img src='${emoji.imageUrl}' alt='${emoji.emoji}' width="30px" height="30px">`,
                    )
                    setIsEmoji(false)
                  }}
                />
              )}
            </div>
            {isGraph && (
              <div className={styles.patternsContainer}>
                {patterns.length > 0 &&
                  patterns.map((p, index) => (
                    <button
                      key={index}
                      className={styles.patterns}
                      onClick={() => {
                        setPatternsColor(p)
                      }}
                    >
                      <img
                        width={40}
                        height={40}
                        src={`${process.env.REACT_APP_PSTS_BASE_URL}${p.background_image}`}
                        alt={''}
                      />
                    </button>
                  ))}
              </div>
            )}
            {!isIframeVideo ? (
              <div className={styles.butonsContainer}>
                <input
                  type={'file'}
                  multiple
                  hidden
                  onChange={onChangeUploadFile}
                  className="selectImage"
                  accept={'image/*'}
                />
                <button className={styles.buttonBlue} onClick={onSelectImages}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon s-ion-icon"
                    viewBox="0 0 512 512"
                    stroke={'rgb(2 132 199 / 1)'}
                    fill={'rgb(2 132 199 / 1)'}
                  >
                    <path d="M416 64H96a64.07 64.07 0 00-64 64v256a64.07 64.07 0 0064 64h320a64.07 64.07 0 0064-64V128a64.07 64.07 0 00-64-64zm-80 64a48 48 0 11-48 48 48.05 48.05 0 0148-48zM96 416a32 32 0 01-32-32v-67.63l94.84-84.3a48.06 48.06 0 0165.8 1.9l64.95 64.81L172.37 416zm352-32a32 32 0 01-32 32H217.63l121.42-121.42a47.72 47.72 0 0161.64-.16L448 333.84z"></path>
                  </svg>
                  <div>{translate('Изображение')}</div>
                </button>
                <input
                  type={'file'}
                  multiple
                  hidden
                  onChange={onChangeUploadVideo}
                  className="selectVideo"
                  accept={'video/*'}
                />
                <button className={styles.buttonGreen} onClick={onTypeVideo}>
                  {isTypeVideo && (
                    <div className={styles.typeVideo}>
                      {inputFileVideo.length <= 0 && (
                        <div
                          className={styles.addTolink}
                          onClick={() => {
                            setIsIframeVideo(true)
                          }}
                        >
                          {translate('встроить')}
                        </div>
                      )}
                      {!inputHtml && (
                        <div
                          className={styles.choiseFile}
                          onClick={onSelectVideo}
                        >
                          {translate('Файл')}
                        </div>
                      )}
                    </div>
                  )}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon s-ion-icon"
                    viewBox="0 0 512 512"
                    stroke={'rgb(13 148 136 / 1)'}
                    fill={'rgb(13 148 136 / 1)'}
                  >
                    <path d="M464 384.39a32 32 0 01-13-2.77 15.77 15.77 0 01-2.71-1.54l-82.71-58.22A32 32 0 01352 295.7v-79.4a32 32 0 0113.58-26.16l82.71-58.22a15.77 15.77 0 012.71-1.54 32 32 0 0145 29.24v192.76a32 32 0 01-32 32zM268 400H84a68.07 68.07 0 01-68-68V180a68.07 68.07 0 0168-68h184.48A67.6 67.6 0 01336 179.52V332a68.07 68.07 0 01-68 68z"></path>
                  </svg>
                  <div>{translate('Видео')}</div>
                </button>
                <button className={styles.buttonVoice} onClick={onTypeAudio}>
                  <input
                    type={'file'}
                    multiple
                    hidden
                    onChange={onChangeUploadAudio}
                    className="selectAudio"
                    accept={'audio/*'}
                  />
                  {isVoice && (
                    <div className={styles.typeAudio}>
                      {isAudioUrl.length <= 0 && (
                        <div
                          className={styles.addTolink}
                          onClick={() => {
                            setIsVoiceCreate(true)
                          }}
                        >
                          {translate('Записать')}
                        </div>
                      )}
                      {isVoiceUrl.length <= 0 && !isVoiceCreate && (
                        <div
                          className={styles.choiseFile}
                          onClick={onSelectAudio}
                        >
                          {translate('Файл')}
                        </div>
                      )}
                    </div>
                  )}
                  <i className="fa fa-microphone"></i>
                  <div>{translate('аудио')}</div>
                </button>
                <button className={styles.buttonOrange} onClick={onActions}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon s-ion-icon"
                    viewBox="0 0 512 512"
                  >
                    <path d="M414.39 97.61A224 224 0 1097.61 414.39 224 224 0 10414.39 97.61zM184 208a24 24 0 11-24 24 23.94 23.94 0 0124-24zm167.67 106.17c-12 40.3-50.2 69.83-95.62 69.83s-83.62-29.53-95.72-69.83a8 8 0 017.83-10.17h175.69a8 8 0 017.82 10.17zM328 256a24 24 0 1124-24 23.94 23.94 0 01-24 24z"></path>
                  </svg>
                  <div>{translate('действия')}</div>
                </button>
                {!isMorButtons && (
                  <button className={styles.buttonMore} onClick={onMoreButtons}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ionicon s-ion-icon"
                      viewBox="0 0 512 512"
                    >
                      <circle cx="256" cy="256" r="48"></circle>
                      <circle cx="416" cy="256" r="48"></circle>
                      <circle cx="96" cy="256" r="48"></circle>
                    </svg>
                  </button>
                )}
                {isMorButtons && (
                  <>
                    <button className={styles.buttonRed} onClick={onLocation}>
                      <i className="fa fa-location-arrow"></i>
                      <div>{translate('Локация')}</div>
                    </button>
                    <button className={styles.newspaper} onClick={onArticle}>
                      <i className="fa fa-newspaper"></i>
                      <div>{translate('Статья')}</div>
                    </button>
                    <button className={styles.newspaper} onClick={onGraph}>
                      <i className="fas fa-stamp"></i>
                      <div>{translate('Цветное')}</div>
                    </button>
                  </>
                )}
              </div>
            ) : (
              <ModalIframeVideo
                setInputHtml={setInputHtml}
                setIsIframeVideo={setIsIframeVideo}
                inputHtml={inputHtml}
              />
            )}
          </>

          {isVoiceCreate && (
            <div className={styles.recordContainer}>
              <button
                onClick={recording ? stopRecording : startRecording}
                className={styles.record}
              >
                {recording ? (
                  <i className={'fa fa-stop'}></i>
                ) : (
                  <i className={'fa fa-play'}></i>
                )}
              </button>
              <progress
                value={recordTime}
                max={5000}
                className={styles.progressor}
              ></progress>
              <div className={styles.textTime}>{formatTime(recordTime)}</div>
              <i
                className={'fa fa-window-close'}
                onClick={() => {
                  setIsVoiceCreate(false)
                }}
              ></i>
            </div>
          )}
          {audio.length > 0 && (
            <div className={`plr10 ${styles.manyImagesInner}`}>
              {audio.map((item, index) => (
                <div key={index} className={styles.manyAudios}>
                  <audio
                    key={index}
                    src={item.url}
                    className={'js_audio'}
                    controls
                    preload="auto"
                  />
                  <button
                    type="button"
                    className={styles.deleteAudio}
                    onClick={() => {
                      deleteHandlerAudio(item.url)
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="5.5"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </button>
                </div>
              ))}
            </div>
          )}
          {inputFilePrev.length > 0 && (
            <div
              className={
                inputFilePrev.length > 1
                  ? styles.manyImagesInner
                  : styles.filesList
              }
            >
              {inputFilePrev.length === 1 ? (
                <>
                  {inputFilePrev.map((img, index) => {
                    return (
                      <div key={index} className={styles.oneImage}>
                        {img.type === 'image' ? (
                          <img src={img.url} key={index} alt={''} />
                        ) : (
                          <video controls src={img.url} />
                        )}
                        <button
                          type="button"
                          className={styles.deleteImage}
                          onClick={() => {
                            if (img.type === 'image') {
                              deleteHandler(img.url)
                            } else {
                              deleteHandlerVideo(img.url)
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="5.5"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    )
                  })}
                </>
              ) : (
                <>
                  {inputFilePrev.map((img, index) => {
                    return (
                      <div key={index} className={styles.manyImages}>
                        {img.type === 'image' ? (
                          <img src={img.url} key={index} alt={''} />
                        ) : (
                          <video src={img.url} />
                        )}
                        <button
                          type="button"
                          className={styles.deleteImage}
                          onClick={() => {
                            if (img.type === 'image') {
                              deleteHandler(img.url)
                            } else {
                              deleteHandlerVideo(img.url)
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="5.5"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    )
                  })}
                </>
              )}
            </div>
          )}
          {isActions && (
            <>
              {isTypeActions ? (
                <div className={styles.ActionsContainer}>
                  <input
                    type={'text'}
                    readOnly={false}
                    disabled
                    className={styles.input}
                    placeholder={'Что вы делаете?'}
                  />
                  <div className={styles.actionsItems}>
                    {actionsButtons.map((item, index) => (
                      <div
                        key={index}
                        className={styles.actionItem}
                        onClick={() => {
                          setTypeActions(item.name)
                          setIsTypeActions(false)
                        }}
                      >
                        {translate(item.name)}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className={styles.inputActions}>
                  <button
                    className={styles.changeAction}
                    onClick={() => {
                      setTypeActions('')
                      setIsTypeActions(true)
                    }}
                  >
                    {translate(typeActions)}
                  </button>
                  <input
                    className={styles.inputActionInfo}
                    placeholder={'Расскажите подробнее'}
                    type={'text'}
                    onChange={(event) => {
                      setTypeActionsText(event.target.value)
                    }}
                  />
                </div>
              )}
            </>
          )}
          {isLocations && coords && (
            <div className={styles.yanexMap}>
              <YMaps>
                <Map
                  width={600}
                  height={300}
                  style={{
                    borderWidth: 2,
                    borderColor: '#0d6efd4a',
                    width: 600,
                    height: 250,
                    borderRadius: 30,
                    overflow: 'hidden',
                  }}
                  defaultState={{
                    center: [coords.latitude, coords.longitude],
                    zoom: 15,
                  }}
                >
                  <Placemark
                    height={20}
                    width={20}
                    geometry={[coords.latitude, coords.longitude]}
                    options={{
                      iconImageHref: userInfo.user_avatar_cover
                        ? getAvatarUrl(userInfo.user_avatar_cover)
                        : userInfo.user_picture
                          ? getAvatarUrl(userInfo.user_picture)
                          : `${process.env.REACT_APP_INFO_BASE_URL}/${siteInfo.avatar}`,
                      visible: true,
                      iconImageSize: [50, 50],
                      iconLayout: 'default#image',
                    }}
                  ></Placemark>
                </Map>
              </YMaps>
            </div>
          )}
          <div className={styles.submitContainer}>
            <button
              className={styles.typePost}
              onClick={() => {
                setIsTypePost(!isTypePost)
              }}
            >
              {translate(isAnonymous ? 'Anonymous' : isPrivacy)}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ionicon s-ion-icon"
                viewBox="0 0 512 512"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="48"
                  d="M112 184l144 144 144-144"
                  className="ionicon-fill-none"
                ></path>
              </svg>
              {isTypePost && (
                <div className={styles.typepostChoice}>
                  <div
                    className={styles.variant}
                    onClick={() => {
                      setIsPrivacy('public')
                      setIsAnonymous(false)
                    }}
                  >
                    <div>{translate('public')}</div>
                    {isPrivacy === 'public' && !isAnonymous && (
                      <div className={styles.icon}>
                        <i className={'fa fa-check'}></i>
                      </div>
                    )}
                  </div>
                  <div
                    className={styles.variant}
                    onClick={() => {
                      setIsPrivacy('me')
                      setIsAnonymous(false)
                    }}
                  >
                    <div>{translate('me')}</div>
                    {isPrivacy === 'me' && !isAnonymous && (
                      <div className={styles.icon}>
                        <i className={'fa fa-check'}></i>
                      </div>
                    )}
                  </div>
                  <div
                    className={styles.variant}
                    onClick={() => {
                      setIsPrivacy('friends')
                      setIsAnonymous(false)
                    }}
                  >
                    <div>{translate('friends')}</div>
                    {isPrivacy === 'friends' && !isAnonymous && (
                      <div className={styles.icon}>
                        <i className={'fa fa-check'}></i>
                      </div>
                    )}
                  </div>
                  <div
                    className={styles.variant}
                    onClick={() => {
                      setIsPrivacy('public')
                      setIsAnonymous(true)
                    }}
                  >
                    <div>{translate('Anonymous')}</div>
                    {isPrivacy === 'public' && isAnonymous && (
                      <div className={styles.icon}>
                        <i className={'fa fa-check'}></i>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </button>

            <button
              className={styles.buttonCreate}
              onClick={() => {
                if (
                  inputFile.length > 0 &&
                  !typeActions &&
                  !typeActionsText &&
                  inputFileVideo.length <= 0 &&
                  !isLocationsSend &&
                  !isHtml
                ) {
                  onCreatePst('photos')
                } else if (
                  inputFile.length <= 0 &&
                  inputFileVideo.length > 0 &&
                  !typeActions &&
                  !typeActionsText &&
                  !isLocationsSend &&
                  !isHtml
                ) {
                  onCreatePst('videos')
                } else if (
                  (inputFile.length <= 0 &&
                    inputFileVideo.length <= 0 &&
                    isVoiceUrl.length > 0 &&
                    !isLocationsSend &&
                    !isHtml) ||
                  (inputFile.length <= 0 &&
                    inputFileVideo.length <= 0 &&
                    isAudioUrl.length > 0 &&
                    !isLocationsSend &&
                    !isHtml)
                ) {
                  onCreatePst('voice')
                } else if (
                  inputFile.length <= 0 &&
                  inputFileVideo.length <= 0 &&
                  isVoiceUrl.length <= 0 &&
                  !typeActions &&
                  !typeActionsText &&
                  isAudioUrl.length <= 0 &&
                  !isLocationsSend &&
                  !inputHtml
                ) {
                  onCreatePst('text')
                } else if (
                  inputFile.length <= 0 &&
                  inputFileVideo.length <= 0 &&
                  isVoiceUrl.length <= 0 &&
                  typeActions &&
                  typeActionsText &&
                  isAudioUrl.length <= 0 &&
                  !isLocationsSend &&
                  !inputHtml
                ) {
                  onCreatePst('actions')
                } else if (actionsFiles && !isLocationsSend && !inputHtml) {
                  onCreatePst('actionsFiles')
                } else if (isLocationsSend && !inputHtml) {
                  onCreatePst('location')
                } else if (inputHtml) {
                  onCreatePst('html')
                }
              }}
            >
              {translate('Опубликовать')}
            </button>
          </div>
        </div>
      </div>
      {isArticle && (
        <NewsPost
          setIsArticle={setIsArticle}
          dispatch={dispatch}
          theme={theme}
          translate={translate}
          userInfo={userInfo}
          setIsCreatePostModal={setIsCreatePostModal}
        />
      )}
    </>
  )
}

export default ModalCreatePost
