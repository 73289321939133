import React from 'react'
import styles from '../ChatLists/index.module.scss'
import { Link } from 'react-router-dom'
import { getAvatarUrl } from '../../api'

function SearchUsersChat({
  online,
  user,
  onChatId,
  messages,
  userInfo,
  siteInfo,
}) {
  const addUser = (data) => {
    const chat = messages.find(
      (mes) =>
        (mes.user_id === data.user_id &&
          mes.recipient_id === userInfo.user_id) ||
        (mes.recipient_id === data.user_id && mes.user_id === userInfo.user_id),
    )
    if (chat) {
      onChatId(chat)
    } else {
      onChatId({
        conversation_id: -1,
        image: null,
        voice_note: null,
        shared: [],
        user_id: userInfo.user_id,
        recipient_id: data.user_id,
        user: data,
        message_rid: null,
        messages: [],
      })
    }
  }

  const isOnline =
    online.length > 0 && online.some((item) => item.user_id === user.user_id)

  return (
    <Link
      to={`/messages/${user.user_name}`}
      className={styles.chatItemContainer}
      onClick={() => {
        addUser(user)
      }}
    >
      <div className={styles.avatarContainer}>
        <img
          src={
            user.user_avatar_cover
              ? getAvatarUrl(user.user_avatar_cover)
              : user.user_picture
                ? getAvatarUrl(user.user_picture)
                : `${process.env.REACT_APP_INFO_BASE_URL}/${siteInfo.avatar}`
          }
          alt={user.user_lastname}
          className={styles.avatar}
        />
        <div
          className={styles.isOnline}
          style={{
            backgroundColor: isOnline ? 'rgb(34 197 94)' : 'rgb(138 143 140)',
          }}
        />
      </div>
      <div className={styles.chatInfoContainer}>
        <div className={styles.userMessageInner}>
          <div className={styles.userName}>
            {user.user_firstname} {user.user_lastname}
          </div>
          <div className={styles.message}></div>
        </div>
        <div className={styles.time}></div>
      </div>
    </Link>
  )
}

export default SearchUsersChat
