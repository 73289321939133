import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../LefMain/index.module.scss'

function LeftMainSubLinks({ link, pathname, theme, translate }) {
  return (
    <>
      {link.subLinks ? (
        <>
          <Link
            to={link.path}
            className={
              pathname === link.path
                ? styles.activeL
                : theme
                  ? styles.linkName
                  : styles.linkNameW
            }
          >
            {link.img}
            <span> {translate(link.name)} </span>
            {link.icon}
          </Link>
        </>
      ) : (
        <>
          <Link
            to={link.path}
            className={
              pathname === link.path
                ? styles.activeL
                : theme
                  ? styles.linkName
                  : styles.linkNameW
            }
          >
            {link.img}
            <span> {translate(link.name)} </span>
          </Link>
        </>
      )}
    </>
  )
}
export default LeftMainSubLinks
