import React from 'react'
import styles from '../index.module.scss'

function ProfilePicture({
  post,
  classNameImage,
  setIsModalMedia,
  setInitialIndexMedia,
}) {
  return (
    <button
      className={styles.profilePicture}
      onClick={() => {
        setInitialIndexMedia(0)
        setIsModalMedia(true)
      }}
    >
      <img
        alt={`${post.user_firstname} ${post.user_lastname}`}
        src={`${process.env.REACT_APP_BASE_TINY_MCE_URL}/${post.postPhotos[0]?.source}`}
        className={classNameImage}
      />
    </button>
  )
}
export default ProfilePicture
