export const ALL_POST_INFO_REQUEST = 'ALL_POST_INFO_REQUEST'
export const ALL_POST_INFO_SUCCESS = 'ALL_POST_INFO_SUCCESS'
export const ALL_POST_INFO_ERROR = 'ALL_POST_INFO_ERROR'
export const CREATE_POST_REQUEST = 'CREATE_POST_REQUEST'
export const CREATE_POST_NONE_FILES_REQUEST = 'CREATE_POST_NONE_FILES_REQUEST'
export const CREATE_POST_PROGRESS = 'CREATE_POST_PROGRESS'
export const NEW_POST_SUCCESS = 'NEW_POST_SUCCESS'
export const UPDATE_POST_REQUEST = 'UPDATE_POST_REQUEST'
export const UPDATE_POST_SUCCESS = 'UPDATE_POST_SUCCESS'
export const CREATE_NEWS = 'CREATE_NEWS'
export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS'

export const POST_COLORED_PATTERNS_REQUEST = 'POST_COLORED_PATTERNS_REQUEST'
export const POST_COLORED_PATTERNS_SUCCESS = 'POST_COLORED_PATTERNS_SUCCESS'
export const POST_COLORED_PATTERNS_ERROR = 'POST_COLORED_PATTERNS_ERROR'
