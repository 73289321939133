import { baseInstance } from './index'

export const postsInfo = () => {
  return baseInstance.get('/posts_info')
}
export const postsPatternsInfo = () => {
  return baseInstance.get('/posts_patterns')
}
export const cretePostsFiles = (formData, onUploadProgress) => {
  return baseInstance.post('/create_posts_files', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  })
}

export const createPost = (data) => {
  return baseInstance.post('/create_post', data)
}

export const updatePost = (data) => {
  return baseInstance.post('/update_post', data)
}
