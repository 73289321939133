export const countries_data = [
  {
    country_code: 'af',
    country_name: 'countryLabel.afghanistan',
  },
  {
    country_code: 'al',
    country_name: 'countryLabel.albania',
  },
  {
    country_code: 'dz',
    country_name: 'countryLabel.algeria',
  },
  {
    country_code: 'ds',
    country_name: 'countryLabel.samoa',
  },
]
