import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PageContainer from '../../../components/PageContainer'
import { Link, useParams } from 'react-router-dom'
import cover from '../../../assets/profile-cover.jpg'
import * as appUser from '../../../actions/app.actions'
import { Field, Form, Formik } from 'formik'
import { isValidImageType } from '../../../utils'
import * as appActions from '../../../actions/app.actions'
import { getAvatarUrl } from '../../../api'
import CreatePost from '../../../components/CreatePost'
import Post from '../../../components/Post'
import ModalCreatePost from '../../../components/ModalCreatePost'
import ModalViewMedia from '../../../components/ModalViewMedia'
import ProfileBlockFriends from '../../../components/ProfileBlockFriends'
import NavLinksProfile from '../../../components/NavLinksProfile'
import { IconCall } from '../../../assets'

const AutoSubmit = ({ initialValues, values, submitForm }) => {
  useEffect(() => {
    if (initialValues !== values && values.user_cover !== null) {
      submitForm()
    }
  }, [initialValues, submitForm, values])
  return null
}

const AutoSubmitAvatar = ({ initialValues, values, submitForm }) => {
  useEffect(() => {
    if (initialValues !== values && values.avatar !== null) {
      submitForm()
    }
  }, [initialValues, submitForm, values])
  return null
}
function UserProfile() {
  const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
  const dispatch = useDispatch()
  const { user_name } = useParams()
  const { t } = useTranslation('common')
  const userInfo = useSelector((state) => state.app.user)
  const theme = useSelector((state) => state.app.theme)
  const siteInfo = useSelector((state) => state.site.siteInfo)
  const uploadProgress = useSelector((state) => state.app.uploadAvatarProgress)
  const toggleCoverProgress = useSelector(
    (state) => state.app.toggleCoverProgress,
  )
  const uploadCoverProgress = useSelector(
    (state) => state.app.uploadCoverProgress,
  )
  const allPosts = useSelector((state) => state.posts.allPosts)
  const patterns = useSelector((state) => state.posts.patterns)
  const loadings = useSelector((state) => state.posts.loadings.newPost)
  const toggleAvatarProgress = useSelector(
    (state) => state.app.toggleAvatarProgress,
  )
  const isSveCropper = useSelector((state) => state.app.isSveCropper)
  const allUsers = useSelector((state) => state.app.allUsers).filter(
    (i) => i.user_id !== userInfo.user_id,
  )
  const [currentAudio, setCurrentAudio] = useState({
    post_id: null,
    media_id: null,
  })
  const [isCreatePostModal, setIsCreatePostModal] = useState(false)
  const [isModalMedia, setIsModalMedia] = useState(false)
  const [initialIndexMedia, setInitialIndexMedia] = useState(0)
  const [initialIndexPost, setInitialIndexPost] = useState(0)
  const audioRefs = useRef([])
  const user =
    userInfo.user_name === user_name
      ? userInfo
      : allUsers.find((i) => i.user_name === user_name)
  const submitCoverForm = ({ user_cover }) => {
    dispatch(appUser.uploadCover({ user_cover: user_cover }))
  }

  const submitAvatarForm = ({ avatar }) => {
    dispatch(appUser.uploadAvatar({ avatar: avatar }))
  }

  const progresor = loadings || isSveCropper || toggleCoverProgress
  const deleteAvatar = () => {
    dispatch(appUser.toggleDeleteAvatar(true))
  }

  const toggleCropperAvatar = () => {
    dispatch(appUser.toggleCropperAvatar(!toggleAvatarProgress))
  }

  useEffect(() => {
    return () => {
      if (currentAudio.ref) {
        currentAudio.ref.pause()
        currentAudio.ref.currentTime = 0
      }
    }
  }, [currentAudio])

  const handleAudioPlay = (audio, post) => {
    const currentRef = audioRefs.current[`${post.post_id}_${audio.media_id}`]
    if (currentAudio.ref) {
      if (currentAudio.ref !== currentRef) {
        currentAudio.ref.pause()
        currentAudio.ref.currentTime = 0
      } else {
        console.log(currentRef.paused)
        if (!currentRef.paused) {
          currentRef.play()
          return
        } else {
          currentRef.pause()
          return
        }
      }
    }
    if (currentRef) {
      currentRef.play()
    }
    setCurrentAudio({
      post_id: audio.post_id,
      media_id: audio.media_id,
      ref: currentRef,
    })
  }

  const navLinks = [
    {
      name: 'Timeline',
      route: `/`,
    },
    {
      name: 'Friend',
      route: `/`,
    },
    {
      name: 'Photo',
      route: `/`,
    },
    {
      name: 'Video',
      route: `/`,
    },
    {
      name: 'Group',
      route: `/`,
    },
  ]

  return (
    <>
      {user && siteInfo && (
        <>
          <PageContainer
            theme={theme}
            siteInfo={siteInfo}
            translate={t}
            userInfo={userInfo}
          >
            <section
              className={`col-md-8 col-lg-9 offcanvas-mainbar ${isMobile ? styles.pageContainerMobile : styles.pageContainer}`}
            >
              <article
                className={
                  theme === 'bg-white'
                    ? styles.profileInfo
                    : styles.profileInfoB
                }
              >
                <div className={styles.profileInfoInner}>
                  <img
                    src={
                      user.user_cover_position
                        ? getAvatarUrl(user.user_cover_position)
                        : cover
                    }
                    alt={`${user.user_firstname} ${user.user_lastname}`}
                    className={styles.profileCover}
                  />
                  <div className={styles.gradient} />
                  {user.user_id === userInfo.user_id && (
                    <div className={styles.buttonsContainer}>
                      <div className={styles.buttonsInner}>
                        {user.user_cover && (
                          <button
                            className={styles.buttonEdits}
                            onClick={() => {
                              dispatch(appActions.toggleCropperCover(true))
                            }}
                          >
                            Изменить Область
                          </button>
                        )}
                        <Formik
                          initialValues={{
                            user_cover: null,
                          }}
                          validate={({ user_cover }) => {
                            const errors = {}

                            if (
                              user_cover &&
                              user_cover.size / 1024 / 1024 >= 10
                            ) {
                              errors.user_cover =
                                'Размер изображения превышает 10Mb'
                            }

                            if (
                              user_cover &&
                              !isValidImageType(user_cover.type)
                            ) {
                              errors.user_cover = 'Неверный формат изображения'
                            }

                            return errors
                          }}
                          onSubmit={submitCoverForm}
                        >
                          {(props) => (
                            <Form className="x-uploader">
                              <Field>
                                {({ form }) => (
                                  <>
                                    <input
                                      className="Cover"
                                      hidden
                                      name="file"
                                      type="file"
                                      accept=".png, .gif, .jpeg, .jpg"
                                      onChange={({ target: { files } }) => {
                                        if (files && files[0]) {
                                          const image = files[0]
                                          form.setFieldValue(
                                            'user_cover',
                                            image,
                                          )
                                        }
                                      }}
                                    />
                                    <i
                                      onClick={() =>
                                        document.querySelector(`.Cover`).click()
                                      }
                                      className="fa fa-camera js_x-uploader"
                                      data-handle="cover-user"
                                    ></i>
                                  </>
                                )}
                              </Field>
                              <AutoSubmit {...props} />
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.profileInfoWrapper}>
                  <div className={styles.profileInfoWrapperInner}>
                    <div
                      className={`profile-avatar-wrapper ${styles.profileAvatar}`}
                    >
                      <img
                        src={
                          user.user_avatar_cover
                            ? getAvatarUrl(user.user_avatar_cover)
                            : user.user_picture
                              ? getAvatarUrl(user.user_picture)
                              : `${process.env.REACT_APP_INFO_BASE_URL}/${siteInfo.avatar}`
                        }
                        alt={user.user_lastname}
                        className={styles.avatar}
                      />
                      {user.user_id === userInfo.user_id && (
                        <div className="profile-avatar-change">
                          <Formik
                            initialValues={{
                              avatar: null,
                            }}
                            validate={({ avatar }) => {
                              const errors = {}

                              if (avatar && avatar.size / 1024 / 1024 >= 10) {
                                errors.avatar =
                                  'Размер изображения превышает 10Mb'
                              }

                              if (avatar && !isValidImageType(avatar.type)) {
                                errors.avatar = 'Неверный формат изображения'
                              }

                              return errors
                            }}
                            onSubmit={submitAvatarForm}
                          >
                            {(props) => (
                              <Form className="x-uploader">
                                <Field>
                                  {({ form }) => (
                                    <>
                                      <input
                                        name="file"
                                        type="file"
                                        accept=".png, .gif, .jpeg, .jpg"
                                        onChange={({ target: { files } }) => {
                                          if (files && files[0]) {
                                            const image = files[0]
                                            form.setFieldValue('avatar', image)
                                          }
                                        }}
                                      />
                                      <i
                                        className="fa fa-camera js_x-uploader"
                                        data-handle="picture-user"
                                      ></i>
                                    </>
                                  )}
                                </Field>
                                <AutoSubmitAvatar {...props} />
                              </Form>
                            )}
                          </Formik>
                        </div>
                      )}
                      {user.user_picture &&
                        user.user_id === userInfo.user_id && (
                          <>
                            <div
                              className="profile-avatar-crop "
                              onClick={toggleCropperAvatar}
                            >
                              <i
                                className="fa fa-crop-alt js_init-crop-picture"
                                data-handle="user"
                                data-id="1"
                              ></i>
                            </div>
                            <div className="profile-avatar-delete ">
                              <i
                                className="fa fa-trash js_delete-picture"
                                data-handle="picture-user"
                              ></i>
                            </div>
                          </>
                        )}
                      {toggleAvatarProgress && (
                        <div className="profile-avatar-change-loader">
                          <div className="progress x-progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: uploadProgress + 2 }}
                            ></div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.infoContainer}>
                      <h3 className={styles.userFirstname}>
                        {user.user_firstname} {user.user_lastname}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className={styles.userLinks}>
                  <div className={styles.linksContainer}>
                    {navLinks.map((nav, index) => (
                      <NavLinksProfile
                        user={user}
                        index={index}
                        nav={nav}
                        key={index}
                      />
                    ))}
                  </div>
                  <div className={styles.buttonsActionsContainer}>
                    {user.user_id !== userInfo.user_id ? (
                      <>
                        <button className={styles.addTofriends}>
                          <svg
                            width={20}
                            height={20}
                            fill={'white'}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm80 224h-64v64a16 16 0 01-32 0v-64h-64a16 16 0 010-32h64v-64a16 16 0 0132 0v64h64a16 16 0 010 32z"></path>
                          </svg>
                          <span>{t('Добавить в друзья')}</span>
                        </button>
                        <button className={styles.collButton}>
                          <IconCall width={20} height={20} />
                        </button>
                        <button className={styles.collButton}>
                          <i className="fa fa-comments fa-fw fa-lg"></i>
                        </button>
                      </>
                    ) : (
                      <>
                        <></>
                      </>
                    )}
                  </div>
                </div>
              </article>
              <article className={`row offcanvas ${styles.postsRow}`}>
                <div
                  className={`col-md-7 col-lg-7 js_sticky-sidebar ${styles.col1}`}
                >
                  <CreatePost
                    theme={theme === 'bg-white'}
                    siteInfo={siteInfo}
                    translate={t}
                    userInfo={userInfo}
                    setIsCreatePostModal={setIsCreatePostModal}
                  />
                  {allPosts.length > 0 && (
                    <>
                      {allPosts
                        .filter((posts) => posts.user_id === user.user_id)
                        .map((post, index) => (
                          <Post
                            key={index}
                            post={post}
                            indexPost={index}
                            theme={theme === 'bg-white'}
                            siteInfo={siteInfo}
                            translate={t}
                            userInfo={userInfo}
                            patterns={patterns}
                            setIsModalMedia={setIsModalMedia}
                            initialIndexMedia={initialIndexMedia}
                            setInitialIndexMedia={setInitialIndexMedia}
                            setInitialIndexPost={setInitialIndexPost}
                            handleAudioPlay={handleAudioPlay}
                            currentAudio={currentAudio}
                            audioRefs={audioRefs}
                          />
                        ))}
                    </>
                  )}
                </div>
                <div
                  className={`col-md-5 col-lg-5 js_sticky-sidebar ${styles.col2}`}
                >
                  <div
                    className={
                      theme === 'bg-white' ? styles.blockBox : styles.blockBoxB
                    }
                  >
                    <div className={styles.introTitle}>
                      <h3 className={styles.introText}> {t('Информация')} </h3>
                      <Link to={'/'} className={styles.introEdit}>
                        {t('Редактировать')}
                      </Link>
                    </div>
                    <div className={styles.introInfo}>
                      <div className={styles.infoIntros}>
                        <svg
                          width={30}
                          height={30}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#5e5454"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                          ></path>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                          ></path>
                        </svg>
                        <div className={styles.titleIntro}>
                          {t('Проживает')}
                        </div>
                        <div className={styles.userCountry}>
                          {user.user_country || t('не указанно')}
                        </div>
                      </div>
                      <div className={styles.infoIntros}>
                        <svg
                          width={30}
                          height={30}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#5e5454"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                          ></path>
                        </svg>
                        <div className={styles.titleIntro}>
                          {t('Образование')}
                        </div>
                        <div className={styles.userCountry}>
                          {user.user_country || t('не указанно')}
                        </div>
                      </div>
                      <div className={styles.infoIntros}>
                        <svg
                          width={30}
                          height={30}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#5e5454"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                          ></path>
                        </svg>
                        <div className={styles.titleIntro}>
                          {t('Место работы')}
                        </div>
                        <div className={styles.userCountry}>
                          {user.user_country || t('не указанно')}
                        </div>
                      </div>
                      <div className={styles.infoIntros}>
                        <svg
                          width={30}
                          height={30}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#5e5454"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                          ></path>
                        </svg>
                        <div className={styles.titleIntro}>
                          {t('Семейное положение')}
                        </div>
                        <div className={styles.userCountry}>
                          {user.user_country || t('не указанно')}
                        </div>
                      </div>
                      <div className={styles.infoIntros}>
                        <svg
                          width={30}
                          height={30}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#5e5454"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12.75 19.5v-.75a7.5 7.5 0 00-7.5-7.5H4.5m0-6.75h.75c7.87 0 14.25 6.38 14.25 14.25v.75M6 18.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                          ></path>
                        </svg>
                        <div className={styles.titleIntro}>
                          {t('Подписчики')}
                        </div>
                        <div className={styles.userCountry}>3,240 People</div>
                      </div>
                    </div>
                  </div>
                  <ProfileBlockFriends
                    theme={theme === 'bg-white'}
                    translate={t}
                  />
                  <ProfileBlockFriends
                    theme={theme === 'bg-white'}
                    translate={t}
                  />
                </div>
              </article>
            </section>
          </PageContainer>
          {isCreatePostModal && (
            <ModalCreatePost
              translate={t}
              theme={theme === 'bg-white'}
              siteInfo={siteInfo}
              isCreatePostModal={isCreatePostModal}
              setIsCreatePostModal={setIsCreatePostModal}
              userInfo={userInfo}
              patterns={patterns}
              userType={'user'}
            />
          )}
          {isModalMedia && (
            <ModalViewMedia
              isModalMedia={isModalMedia}
              setIsModalMedia={setIsModalMedia}
              initialIndexMedia={initialIndexMedia}
              setInitialIndexMedia={setInitialIndexMedia}
              initialIndexPost={initialIndexPost}
              post={allPosts.find((post) => post.post_id === initialIndexPost)}
              theme={theme === 'bg-white'}
            />
          )}
          {progresor && (
            <div className={styles.progress}>
              <div className={styles.loader}></div>
              {uploadCoverProgress > 0 && (
                <div className={styles.percentChange}>
                  {uploadCoverProgress} %
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default UserProfile
