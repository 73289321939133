// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthInput_inputForm__6T4tX {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 700;
  --tw-text-opacity: 1 !important;
  color: rgb(75, 85, 99) !important;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
  --tw-shadow-colored: 0 1px 2px 0 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow) !important;
  border-color: rgb(226, 232, 240) !important;
  border-radius: 0.5rem !important;
  width: 100%;
  border-width: 1px;
  border-style: solid;
}
@media (max-width: 780px) {
  .AuthInput_inputForm__6T4tX {
    max-width: 400px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/AuthInput/index.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,+BAAA;EACA,iCAAA;EACA,qDAAA;EACA,2CAAA;EACA,wIAAA;EACA,2CAAA;EACA,gCAAA;EACA,WAAA;EACA,iBAAA;EACA,mBAAA;AACF;AAAE;EAfF;IAgBI,gBAAA;EAGF;AACF","sourcesContent":[".inputForm{\r\n  padding: 0.5rem 1rem;\r\n  font-size: 0.9rem;\r\n  line-height: 1.2rem;\r\n  font-weight: 700;\r\n  --tw-text-opacity: 1 !important;\r\n  color: rgb(75 85 99 / 1) !important;\r\n  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;\r\n  --tw-shadow-colored: 0 1px 2px 0 !important;\r\n  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;\r\n  border-color: rgb(226 232 240 / 1) !important;\r\n  border-radius: 0.5rem !important;\r\n  width: 100%;\r\n  border-width: 1px;\r\n  border-style: solid;\r\n  @media (max-width: 780px){\r\n    max-width: 400px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputForm": `AuthInput_inputForm__6T4tX`
};
export default ___CSS_LOADER_EXPORT___;
