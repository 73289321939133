import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PageContainer from '../../../components/PageContainer'
import styles from './index.module.scss'
import './StylesStories/style.css'
import './StylesStories/swiper.css'
import './StylesStories/snapgram.css'
import './StylesStories/snapssenger.css'
import './StylesStories/zuck.min.css'
import { getCurrentSkin, stories } from '../../../utils'
import CreateStoriesModal from '../../../components/CreateStoriesModal'
import CreatePost from '../../../components/CreatePost'
import ModalCreatePost from '../../../components/ModalCreatePost'
import Post from '../../../components/Post'
import ModalViewMedia from '../../../components/ModalViewMedia'

function FeedPage() {
  const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const userInfo = useSelector((state) => state.app.user)
  const theme = useSelector((state) => state.app.theme)
  const allPosts = useSelector((state) => state.posts.allPosts)
  const patterns = useSelector((state) => state.posts.patterns)
  const stories = useSelector((state) => state.stories.allStores)
  const success = useSelector((state) => state.stories.loadings.allStores)
  const loadings = useSelector((state) => state.posts.loadings.newPost)
  const newStore = useSelector((state) => state.stories.newStore)
  const siteInfo = useSelector((state) => state.site.siteInfo)
  const [translateX, setTranslateX] = useState(0)
  const [maxTranslate, setMaxTranslate] = useState(0)
  const [isCreateStoriesModal, setIsCreateStoriesModal] = useState(false)
  const [isCreatePostModal, setIsCreatePostModal] = useState(false)
  const [isModalMedia, setIsModalMedia] = useState(false)
  const [initialIndexMedia, setInitialIndexMedia] = useState(0)
  const [initialIndexPost, setInitialIndexPost] = useState(0)
  const [currentAudio, setCurrentAudio] = useState({
    post_id: null,
    media_id: null,
  })
  const audioRefs = useRef([])
  const storesRef = useRef(null)

  const isLoading = success || loadings

  useEffect(() => {
    const idStories = document.getElementById('stories')
    if (idStories) {
      const visibleWidth = idStories.clientWidth
      const contentWidth = idStories.scrollWidth
      setMaxTranslate(visibleWidth - contentWidth)
      idStories.style.transform = `translate3d(${translateX}px, 0px, 0px)`
    }
  }, [translateX])

  const currentSkin = getCurrentSkin()
  useEffect(() => {
    if (window.StoriesFunction) {
      storesRef.current = new window.StoriesFunction().stories('stories', {
        backNative: true,
        previousTap: true,
        skin: currentSkin['Snapgram'],
        autoFullScreen: currentSkin['params']['autoFullScreen'],
        avatars: currentSkin['params']['avatars'],
        paginationArrows: currentSkin['params']['paginationArrows'],
        list: currentSkin['params']['list'],
        cubeEffect: currentSkin['params']['cubeEffect'],
        localStorage: true,
        stories: stories,
      })
    }
  }, [])

  useEffect(() => {
    if (storesRef.current && newStore) {
      storesRef.current.update(newStore)
      // dispatch(actionStore.updateItemsClearStores(null))
    }
  }, [newStore, dispatch])

  const toggleCreateModalStories = () => {
    setIsCreateStoriesModal(true)
  }

  const onMouseDown = (e) => {
    const idStories = document.getElementById('stories')
    if (idStories) {
      idStories.style.transitionDuration = '0ms'
      idStories.style.transitionDelay = '0ms'

      const startX = e.clientX
      let initialTranslateX = translateX

      const onMouseMove = (moveEvent) => {
        const deltaX = moveEvent.clientX - startX
        let newTranslateX = initialTranslateX + deltaX
        if (newTranslateX > 0) {
          newTranslateX = 0
        } else if (newTranslateX < maxTranslate) {
          newTranslateX = maxTranslate
        }

        setTranslateX(newTranslateX)
        idStories.style.transform = `translate3d(${newTranslateX}px, 0, 0)`
      }

      const onMouseUp = () => {
        idStories.style.transitionDuration = '500ms'
        setTimeout(() => {
          idStories.style.transitionDuration = '0ms'
          idStories.style.transitionDelay = '0ms'
        }, 500)
        window.removeEventListener('mousemove', onMouseMove)
        window.removeEventListener('mouseup', onMouseUp)
      }

      window.addEventListener('mousemove', onMouseMove)
      window.addEventListener('mouseup', onMouseUp)
    }
  }

  const onTouchStart = (e) => {
    const idStories = document.getElementById('stories')
    if (idStories) {
      idStories.style.transitionDuration = '0ms'
      idStories.style.transitionDelay = '0ms'

      const startX = e.touches[0].clientX // Берем начальную точку касания
      let initialTranslateX = translateX

      const onTouchMove = (moveEvent) => {
        const deltaX = moveEvent.touches[0].clientX - startX
        let newTranslateX = initialTranslateX + deltaX
        if (newTranslateX > 0) {
          newTranslateX = 0
        } else if (newTranslateX < maxTranslate) {
          newTranslateX = maxTranslate
        }

        setTranslateX(newTranslateX)
        idStories.style.transform = `translate3d(${newTranslateX}px, 0, 0)`
      }

      const onTouchEnd = () => {
        idStories.style.transitionDuration = '500ms'
        setTimeout(() => {
          idStories.style.transitionDuration = '0ms'
          idStories.style.transitionDelay = '0ms'
        }, 500)
        window.removeEventListener('touchmove', onTouchMove)
        window.removeEventListener('touchend', onTouchEnd)
      }

      window.addEventListener('touchmove', onTouchMove)
      window.addEventListener('touchend', onTouchEnd)
    }
  }

  useEffect(() => {
    const idStories = document.getElementById('stories')
    if (idStories) {
      const visibleWidth = idStories.clientWidth
      const contentWidth = idStories.scrollWidth
      setMaxTranslate(visibleWidth - contentWidth)
      idStories.style.transform = `translate3d(${translateX}px, 0px, 0px)`
    }
  }, [translateX])

  const onScroll = (type) => {
    const idStories = document.getElementById('stories')
    if (idStories) {
      let newTranslateX = translateX

      if (type === 'left') {
        newTranslateX += 125 // Смещение на 125 пикселей
        if (newTranslateX > 0) {
          newTranslateX = 0 // Ограничиваем смещение
        }
      } else {
        newTranslateX -= 125 // Смещение на 125 пикселей
        if (newTranslateX < maxTranslate) {
          newTranslateX = maxTranslate // Ограничиваем смещение
        }
      }
      setTranslateX(newTranslateX)
      idStories.style.transitionDuration = '500ms'
      setTimeout(() => {
        idStories.style.transitionDuration = '0ms'
      }, 500)
    }
  }

  useEffect(() => {
    return () => {
      if (currentAudio.ref) {
        currentAudio.ref.pause()
        currentAudio.ref.currentTime = 0
      }
    }
  }, [currentAudio])

  const handleAudioPlay = (audio, post) => {
    const currentRef = audioRefs.current[`${post.post_id}_${audio.media_id}`]
    if (currentAudio.ref) {
      if (currentAudio.ref !== currentRef) {
        currentAudio.ref.pause()
        currentAudio.ref.currentTime = 0
      } else {
        console.log(currentRef.paused)
        if (!currentRef.paused) {
          currentRef.play()
          return
        } else {
          currentRef.pause()
          return
        }
      }
    }
    if (currentRef) {
      currentRef.play()
    }
    setCurrentAudio({
      post_id: audio.post_id,
      media_id: audio.media_id,
      ref: currentRef,
    })
  }

  return (
    <>
      {userInfo && siteInfo && (
        <>
          <PageContainer
            theme={theme}
            siteInfo={siteInfo}
            translate={t}
            userInfo={userInfo}
          >
            <section
              className={`col-md-8 col-lg-6 offcanvas-mainbar ${isMobile ? styles.pageContainerMobile : styles.pageContainer}`}
            >
              <article className={styles.storiesContainer}>
                {translateX === 0 ? (
                  <></>
                ) : (
                  <button
                    className={styles.scrollLeft}
                    onClick={() => {
                      onScroll('left')
                    }}
                  >
                    <svg
                      width={32}
                      height={32}
                      stroke={'currentcolor'}
                      xmlns="http://www.w3.org/2000/svg"
                      className="ionicon s-ion-icon"
                      viewBox="0 0 512 512"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="48"
                        d="M328 112L184 256l144 144"
                        className="ionicon-fill-none"
                      ></path>
                    </svg>
                  </button>
                )}
                <div className={styles.storiesInner}>
                  <div
                    className={`swiper swiper-initialized swiper-horizontal swiper-watch-progress ${styles.swiper}`}
                  >
                    <div
                      className={'swiper-wrapper'}
                      id={'stories'}
                      onMouseDown={onMouseDown}
                      onTouchStart={onTouchStart}
                    >
                      <div className={`swiper-slide ${styles.swiperSlide}`}>
                        <button
                          className={`${styles.storiesUser}`}
                          onClick={toggleCreateModalStories}
                        >
                          <span className={styles.buttonCreatePrev}>
                            <div className={styles.createStoriesIcon}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={styles.storiesAvatarSvg}
                                viewBox="0 0 512 512"
                              >
                                <circle cx="256" cy="272" r="64"></circle>
                                <path d="M432 144h-59c-3 0-6.72-1.94-9.62-5l-25.94-40.94a15.52 15.52 0 00-1.37-1.85C327.11 85.76 315 80 302 80h-92c-13 0-25.11 5.76-34.07 16.21a15.52 15.52 0 00-1.37 1.85l-25.94 41c-2.22 2.42-5.34 5-8.62 5v-8a16 16 0 00-16-16h-24a16 16 0 00-16 16v8h-4a48.05 48.05 0 00-48 48V384a48.05 48.05 0 0048 48h352a48.05 48.05 0 0048-48V192a48.05 48.05 0 00-48-48zM256 368a96 96 0 1196-96 96.11 96.11 0 01-96 96z"></path>
                              </svg>
                            </div>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {translateX <= 0 && (
                  <button
                    className={styles.scrollRight}
                    onClick={() => {
                      onScroll('right')
                    }}
                  >
                    <svg
                      width={32}
                      height={32}
                      stroke={'currentcolor'}
                      xmlns="http://www.w3.org/2000/svg"
                      className="ionicon s-ion-icon"
                      viewBox="0 0 512 512"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="48"
                        d="M184 112l144 144-144 144"
                        className="ionicon-fill-none"
                      ></path>
                    </svg>
                  </button>
                )}
              </article>
              <CreatePost
                theme={theme === 'bg-white'}
                siteInfo={siteInfo}
                translate={t}
                userInfo={userInfo}
                setIsCreatePostModal={setIsCreatePostModal}
              />
              {allPosts.length > 0 && (
                <>
                  {allPosts.map((post, index) => (
                    <Post
                      key={index}
                      post={post}
                      indexPost={index}
                      theme={theme === 'bg-white'}
                      siteInfo={siteInfo}
                      translate={t}
                      userInfo={userInfo}
                      patterns={patterns}
                      setIsModalMedia={setIsModalMedia}
                      initialIndexMedia={initialIndexMedia}
                      setInitialIndexMedia={setInitialIndexMedia}
                      setInitialIndexPost={setInitialIndexPost}
                      handleAudioPlay={handleAudioPlay}
                      currentAudio={currentAudio}
                      audioRefs={audioRefs}
                    />
                  ))}
                </>
              )}
            </section>
          </PageContainer>
          {isCreateStoriesModal && (
            <CreateStoriesModal
              translate={t}
              theme={theme === 'bg-white'}
              siteInfo={siteInfo}
              setIsCreateStoriesModal={setIsCreateStoriesModal}
            />
          )}
          {isCreatePostModal && (
            <ModalCreatePost
              translate={t}
              theme={theme === 'bg-white'}
              siteInfo={siteInfo}
              isCreatePostModal={isCreatePostModal}
              setIsCreatePostModal={setIsCreatePostModal}
              userInfo={userInfo}
              patterns={patterns}
              userType={'user'}
            />
          )}
          {isModalMedia && (
            <ModalViewMedia
              isModalMedia={isModalMedia}
              setIsModalMedia={setIsModalMedia}
              initialIndexMedia={initialIndexMedia}
              setInitialIndexMedia={setInitialIndexMedia}
              initialIndexPost={initialIndexPost}
              post={allPosts.find((post) => post.post_id === initialIndexPost)}
              theme={theme === 'bg-white'}
            />
          )}
          {isLoading && (
            <div className={styles.progress}>
              <div className={styles.loader}></div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default FeedPage
