import * as ActionTypes from '../constants/stores.constants'

const initialState = {
  allStores: [],
  newStore: null,
  loadings: {
    allStores: false,
  },
  errors: {
    allStores: null,
  },
}

const storesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ALL_STORES_INFO_REQUEST: {
      return {
        ...state,
        loadings: { allStores: true },
        errors: { allStores: null },
      }
    }
    case ActionTypes.ALL_STORES_INFO_SUCCESS: {
      return {
        ...state,
        loadings: { allStores: false },
        errors: { allStores: null },
        allStores: [...action.payload],
      }
    }
    case ActionTypes.ALL_STORES_INFO_ERROR: {
      return {
        ...state,
        loadings: { allStores: false },
        errors: { allStores: action.payload },
      }
    }
    case ActionTypes.CREATE_STORES_REQUEST: {
      return {
        ...state,
        loadings: { allStores: true },
      }
    }
    case ActionTypes.UPDATE_ITEMS_STORES_SUCCESS: {
      const newStore = { ...action.payload }
      const oldStore = {
        ...state.allStores.find((stor) => stor.id === newStore.id),
      }
      const stores = {
        ...oldStore,
        items: [...oldStore.items, ...newStore.items],
      }
      const allAldStores = [
        ...state.allStores.filter((stor) => stor.id !== oldStore.id),
      ]
      return {
        ...state,
        loadings: { allStores: false },
        newStore: {
          id: action.payload.id,
          photo: action.payload.photo,
          name: action.payload.name,
          length: action.payload.length,
          items: [...action.payload.items],
        },
        allStores: [stores, ...allAldStores],
      }
    }
    case ActionTypes.UPDATE_STORES_SUCCESS: {
      return {
        ...state,
        loadings: { allStores: false },
        newStore: {
          id: action.payload.id,
          photo: action.payload.photo,
          name: action.payload.name,
          length: action.payload.length,
          items: [...action.payload.items],
        },
        allStores: [
          {
            id: action.payload.id,
            photo: action.payload.photo,
            name: action.payload.name,
            length: action.payload.length,
            items: [...action.payload.items],
          },
          ...state.allStores,
        ],
      }
    }
    case ActionTypes.CLEAR_ITEMS_STORES_SUCCESS: {
      return {
        ...state,
        newStore: null,
      }
    }
    default:
      return state
  }
}
export default storesReducer
