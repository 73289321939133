import React from 'react'
import styles from '../../Pages/Private/UserProfile/index.module.scss'
import { Link } from 'react-router-dom'

function NavLinksProfile({ index, user, nav }) {
  return (
    <Link
      to={index === 0 ? `/${user.user_name}` : nav.route}
      className={index === 0 ? styles.userLinkActive : styles.userLink}
    >
      {nav.name}
    </Link>
  )
}
export default NavLinksProfile
