import React from 'react'
import styles from '../../index.module.scss'

function ManyPhotos({
  post,
  setIsModalMedia,
  setInitialIndexMedia,
  photo,
  photoIndex,
  length,
}) {
  return (
    <button
      className={
        photoIndex === 0
          ? styles.indexNull
          : photoIndex === 3 && length > 4
            ? styles.morFor
            : styles.otherIndex
      }
      onClick={() => {
        setInitialIndexMedia(photoIndex)
        setIsModalMedia(true)
      }}
    >
      <img
        alt={`${post.user_firstname} ${post.user_lastname}`}
        src={`${process.env.REACT_APP_BASE_TINY_MCE_URL}/${photo.source}`}
        className={styles.media}
      />
      {photoIndex === 3 && length > 4 && (
        <div className={styles.bg}>+ {length - 4}</div>
      )}
    </button>
  )
}

export default ManyPhotos
