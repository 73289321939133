/* eslint-disable */
// @ts-nocheck
import React, { createRef } from 'react'
import 'cropperjs/dist/cropper.css'
import Cropper, { ReactCropperElement } from 'react-cropper'

interface CropperCompProps {
  image: string;
  setCropData: (data: string) => void;
  initialAspectRatio:number;
  aspectRatio:number;
  guides:boolean;
  rotatable:boolean;
  responsive:boolean;
  checkOrientation:boolean;
  dragMode:string;
  height:string
}

const CropperComp: React.FC<CropperCompProps> = ({ image, setCropData, initialAspectRatio, aspectRatio, dragMode, checkOrientation, guides, responsive, rotatable, height }) => {
  const cropperRef = createRef<ReactCropperElement>();
  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    setCropData(cropper.getCroppedCanvas().toDataURL());
  };

  return (
    <Cropper
      src={image}
      style={{ width: '100%', height:height}}
      initialAspectRatio={initialAspectRatio}
      ref={cropperRef}
      crop={onCrop}
      aspectRatio={aspectRatio}
      rotatable={rotatable}
      responsive={responsive}
      checkOrientation={checkOrientation}
      guides={guides}
      dragMode={dragMode}
    />
  );
};

export default CropperComp;
