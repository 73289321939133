import React from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import { getAvatarUrl } from '../../api'
import dayjs from 'dayjs'
function ChatLists({ chat, onChatId, online, siteInfo }) {
  const isOnline =
    online.length > 0 &&
    online.some((item) => item.user_id === chat.user.user_id)

  return (
    <Link
      to={`/messages/${chat.user.user_name}`}
      className={styles.chatItemContainer}
      onClick={() => {
        onChatId(chat)
      }}
    >
      <div className={styles.avatarContainer}>
        <img
          src={
            chat.user.user_avatar_cover
              ? getAvatarUrl(chat.user.user_avatar_cover)
              : chat.user.user_picture
                ? getAvatarUrl(chat.user.user_picture)
                : `${process.env.REACT_APP_INFO_BASE_URL}/${siteInfo.avatar}`
          }
          alt={chat.user.user_lastname}
          className={styles.avatar}
        />
        <div
          className={styles.isOnline}
          style={{
            backgroundColor: isOnline ? 'rgb(34 197 94)' : 'rgb(138 143 140)',
          }}
        />
      </div>
      <div className={styles.chatInfoContainer}>
        <div className={styles.userMessageInner}>
          <div className={styles.userName}>
            {chat.user.user_firstname} {chat.user.user_lastname}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: chat.message }}
            className={styles.message}
          ></div>
        </div>
        <div className={styles.time}>
          {dayjs.utc(chat.time).tz(dayjs.tz.guess()).fromNow()}
        </div>
      </div>
    </Link>
  )
}

export default ChatLists
