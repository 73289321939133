const routesLik = Object.freeze({
  root: '/',
  register: '/register',
  resetPassword: '/resetPassword',
  started: '/started',
  feed: '/feed',
  messages: '/messages',
  messagesId: '/messages/:user_name',
  profile: '/:user_name',
  videos: '/videos',
  event: '/event',
  pages: '/pages',
  group: '/group',
  market: '/market',
  blog: '/blog',
  game: '/game',
  fund: '/fund',
  createBlog: '/create_blog',
  createGroup: '/create_group',
  settings: '/settings',
  upgrade: '/upgrade',
  development: '/Development',
})
export default routesLik
