import React from 'react'
import styles from '../index.module.scss'
import { Link } from 'react-router-dom'
import avatar from '../../../assets/avatar-7.jpg'
function FriendsList() {
  return (
    <Link to={'/'}>
      <div className={styles.friemdItem}>
        <img src={avatar} alt="" className={styles.avatarFriend} />
      </div>
      <div className={styles.friendName}> Jesse Steeve</div>
    </Link>
  )
}

export default FriendsList
