import React from 'react'

function IsButtonMessageComp({ className }) {
  return (
    <div className={className}>
      <></>
    </div>
  )
}
export default IsButtonMessageComp
