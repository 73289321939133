export const actionsButtons = [
  {
    id: 1,
    name: 'actionsButtons.Mood',
  },
  {
    id: 2,
    name: 'actionsButtons.listening',
  },
  {
    id: 3,
    name: 'actionsButtons.watching',
  },
  {
    id: 4,
    name: 'actionsButtons.playing',
  },
  {
    id: 5,
    name: 'actionsButtons.eating',
  },
  {
    id: 6,
    name: 'actionsButtons.drink',
  },
  {
    id: 7,
    name: 'actionsButtons.Journey',
  },
  {
    id: 8,
    name: 'actionsButtons.Reading',
  },
  {
    id: 9,
    name: 'actionsButtons.Visit',
  },
  {
    id: 10,
    name: 'actionsButtons.Holidays',
  },
  {
    id: 11,
    name: 'actionsButtons.Searching',
  },
]
