import React from 'react'
import styles from './index.module.scss'

function ModalIframeVideo({ setInputHtml, inputHtml }) {
  return (
    <div className={styles.iframeInfo}>
      <a href={'/IMG_8969.PNG'} target={'_blank'} className={styles.link}>
        Инструкция
      </a>
      <div
        className={styles.iframeInput}
        dangerouslySetInnerHTML={{ __html: inputHtml }}
        suppressContentEditableWarning={true}
        contentEditable
        aria-multiline={true}
        onInput={(event) => {
          setInputHtml(event.target.innerHTML)
        }}
        aria-label={`<iframe
      width="720"
      height="405"
      src="https://rutube.ru/play/embed/0675bcf2bc3912d68bdafda474ec22b7/"
      frameBorder="0"
      allow="clipboard-write; autoplay"
      webkitAllowFullScreen
      mozallowfullscreen
      allowFullScreen>
    </iframe>`}
      />
    </div>
  )
}
export default ModalIframeVideo
