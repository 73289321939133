export const ONLINE_USER_INFO_REQUEST = 'ONLINE_USER_INFO_REQUEST'
export const ONLINE_USER_INFO_SUCCESS = 'ONLINE_USER_INFO_SUCCESS'
export const TOGGLE_COORDS = 'TOGGLE_COORDS'
export const TOGGLE_THEME = 'TOGGLE_THEME'
export const USER_INFO_REQUEST = 'USER_INFO_REQUEST'
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS'
export const USER_INFO_ERROR = 'USER_INFO_ERROR'

export const UPLOAD_USER_AVATAR = 'UPLOAD_USER_AVATAR'
export const UPLOAD_USER_AVATAR_PROGRESS = 'UPLOAD_USER_AVATAR_PROGRESS'
export const UPLOAD_USER_AVATAR_SUCCESS = 'UPLOAD_USER_AVATAR_SUCCESS'
export const TOGGLE_CROPPER_AVATAR = 'TOGGLE_CROPPER_AVATAR'
export const CROPPER_AVATAR_SAVE = 'CROPPER_AVATAR_SAVE'
export const CROPPER_AVATAR_SAVE_SUCCESS = 'CROPPER_AVATAR_SAVE_SUCCESS'

export const UPLOAD_USER_COVER = 'UPLOAD_USER_COVER'
export const UPLOAD_USER_COVER_PROGRESS = 'UPLOAD_USER_COVER_PROGRESS'
export const UPLOAD_USER_COVER_SUCCESS = 'UPLOAD_USER_COVER_SUCCESS'
export const TOGGLE_CROPPER_COVER = 'TOGGLE_CROPPER_COVER'
export const CROPPER_COVER_SAVE = 'CROPPER_COVER_SAVE'
export const CROPPER_COVER_SAVE_SUCCESS = 'CROPPER_COVER_SAVE_SUCCESS'

export const TOGGLE_DELETE_AVATAR = 'TOGGLE_DELETE_AVATAR'
export const DELETE_AVATAR = 'DELETE_AVATAR'
export const STARTED_SAGE_CHANGE = 'STARTED_SAGE_CHANGE'
export const STARTED_SAGE_TWO_SAVE = 'STARTED_SAGE_TWO_SAVE'
export const STARTED_SAGE_TWO_SAVE_SUCCESS = 'STARTED_SAGE_TWO_SAVE_SUCCESS'
export const STARTED_SAGE_THREE_SAVE = 'STARTED_SAGE_THREE_SAVE'

export const ALL_USER_INFO_REQUEST = 'ALL_USER_INFO_REQUEST'
export const ALL_USER_INFO_SUCCESS = 'ALL_USER_INFO_SUCCESS'
export const ALL_USER_INFO_ERROR = 'ALL_USER_INFO_ERROR'
