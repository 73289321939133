import * as ActionTypes from '../constants/stores.constants'

export const storeInfo = (values) => ({
  type: ActionTypes.ALL_STORES_INFO_REQUEST,
  payload: values,
})
export const storeInfoSuccess = (values) => ({
  type: ActionTypes.ALL_STORES_INFO_SUCCESS,
  payload: values,
})
export const storeInfoError = (error) => ({
  type: ActionTypes.ALL_STORES_INFO_ERROR,
  payload: error,
})

export const createStores = (values) => ({
  type: ActionTypes.CREATE_STORES_REQUEST,
  payload: values,
})

export const createStoresProgress = (values) => ({
  type: ActionTypes.CREATE_STORES_PROGRESS,
  payload: values,
})

export const updateStores = (values) => ({
  type: ActionTypes.UPDATE_STORES_SUCCESS,
  payload: values,
})

export const updateItemsStores = (values) => ({
  type: ActionTypes.UPDATE_ITEMS_STORES_SUCCESS,
  payload: values,
})

export const updateItemsClearStores = (values) => ({
  type: ActionTypes.CLEAR_ITEMS_STORES_SUCCESS,
  payload: values,
})
