import React from 'react'
import styles from '../LefMain/index.module.scss'
import { Link } from 'react-router-dom'

function LeftMainLinks({ link, theme, pathname, translate }) {
  return (
    <Link
      to={link.path}
      className={
        pathname === link.path
          ? styles.activeL
          : theme
            ? styles.linkName
            : styles.linkNameW
      }
    >
      <img
        src={link.img}
        alt="feeds"
        className="w-6"
        width={30}
        height={30}
        style={{ borderRadius: `${link.borderRadius}%` }}
      />
      <span> {translate(link.name)} </span>
    </Link>
  )
}
export default LeftMainLinks
