// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalIframeVideo_iframeInfo__BOr6g {
  padding: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.ModalIframeVideo_link__YmrRt {
  padding-left: 25px;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Cantarell, "Helvetica Neue", sans-serif;
}

.ModalIframeVideo_iframeInput__ibgIO {
  width: 100%;
  font-size: 15px;
  font-weight: 700;
  height: 260px;
  text-align: left;
  outline: none;
  border: 1px solid #ccc;
  --tw-bg-opacity: 1;
  padding: 15px;
  background-color: rgb(241, 245, 249, var(--tw-bg-opacity));
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Cantarell, "Helvetica Neue", sans-serif;
}
.ModalIframeVideo_iframeInput__ibgIO::before {
  content: attr(aria-label);
  color: #aaa;
  position: absolute;
  top: 65%;
  left: 0;
  padding: 15px;
  text-align: left;
  white-space: pre-wrap;
  word-break: break-word;
  transform: translateY(-65%);
  pointer-events: none;
  font-family: monospace -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 12px;
  transition: opacity 0.2s ease;
  opacity: 1;
}
.ModalIframeVideo_iframeInput__ibgIO:not(:empty)::before {
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/ModalIframeVideo/index.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;AACF;;AACA;EACE,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,wHAAA;AAEF;;AAAA;EACE,WAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,aAAA;EACA,0DAAA;EACA,wHAAA;AAGF;AAFE;EACE,yBAAA;EACA,WAAA;EACA,kBAAA;EACA,QAAA;EACA,OAAA;EACA,aAAA;EACA,gBAAA;EACA,qBAAA;EACA,sBAAA;EACA,2BAAA;EACA,oBAAA;EACA,kIAAA;EACA,eAAA;EACA,6BAAA;EACA,UAAA;AAIJ;AAFE;EACE,UAAA;AAIJ","sourcesContent":[".iframeInfo{\r\n  padding: 5px;\r\n  width: 100%;\r\n  display: flex;\r\n  align-items: flex-start;\r\n  flex-direction: column;\r\n}\r\n.link{\r\n  padding-left: 25px;\r\n  margin-bottom: 15px;\r\n  font-size: 15px;\r\n  font-weight: 700;\r\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Oxygen-Sans, Cantarell, \"Helvetica Neue\", sans-serif;\r\n}\r\n.iframeInput{\r\n  width: 100%;\r\n  font-size: 15px;\r\n  font-weight: 700;\r\n  height: 260px;\r\n  text-align: left;\r\n  outline: none;\r\n  border: 1px solid #ccc;\r\n  --tw-bg-opacity: 1;\r\n  padding: 15px;\r\n  background-color: rgb(241 245 249 / var(--tw-bg-opacity));\r\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Oxygen-Sans, Cantarell, \"Helvetica Neue\", sans-serif;\r\n  &::before {\r\n    content: attr(aria-label);\r\n    color: #aaa;\r\n    position: absolute;\r\n    top: 65%;\r\n    left: 0;\r\n    padding: 15px;\r\n    text-align: left;\r\n    white-space: pre-wrap;\r\n    word-break: break-word;\r\n    transform: translateY(-65%);\r\n    pointer-events: none;\r\n    font-family: monospace -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Oxygen-Sans, Cantarell, \"Helvetica Neue\", sans-serif;\r\n    font-size: 12px;\r\n    transition: opacity 0.2s ease;\r\n    opacity: 1;\r\n  }\r\n  &:not(:empty)::before {\r\n    opacity: 0;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iframeInfo": `ModalIframeVideo_iframeInfo__BOr6g`,
	"link": `ModalIframeVideo_link__YmrRt`,
	"iframeInput": `ModalIframeVideo_iframeInput__ibgIO`
};
export default ___CSS_LOADER_EXPORT___;
