import React, { useRef, useState } from 'react'
import styles from './index.module.scss'
import bgImg from '../../assets/images/ad_pattern.png'
import {
  PostIconCreate,
  UploadImgIcon,
  VideoCameraIconCreate,
} from '../../assets'
import { formatTime } from '../../utils'
import { useDispatch } from 'react-redux'
import * as storesAction from '../../actions/stores.actions'

function CreateStoriesModal({ theme, translate, setIsCreateStoriesModal }) {
  const dispatch = useDispatch()
  const [isCameraActive, setIsCameraActive] = useState(false)
  const [isCameraPhoto, setIsCameraPhoto] = useState(false)
  const [recordingTime, setRecordingTime] = useState(0)
  const [isRecording, setIsRecording] = useState(false)
  const [inputFile, setInputFile] = useState([])
  const [videoBlob, setVideoBlob] = useState([])
  const [videoURL, setVideoURL] = useState('')
  const [inputText, setInputText] = useState('')
  const videoRef = useRef(null)
  const mediaRecorderRef = useRef(null)
  const recordedChunks = useRef([])
  const intervalRef = useRef(null)
  const canvasRef = useRef(null)
  const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
  const onSelectFile = () => {
    document.querySelector('.fileInput').click()
  }

  const onCaptureMedia = async () => {
    setIsCameraActive(true)
    try {
      const constraints = { video: true, audio: true }
      const stream = await navigator.mediaDevices.getUserMedia(constraints)
      if (videoRef.current) {
        videoRef.current.srcObject = stream
        videoRef.current.play()
      }
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: 'video/mp4',
      })
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunks.current.push(event.data)
        }
      }
    } catch (err) {
      console.error('Ошибка доступа к камере и микрофону:', err)
    }
  }

  const onCapturePhoto = async () => {
    setIsCameraPhoto(true)
    try {
      const constraints = { video: true, audio: true }
      const stream = await navigator.mediaDevices.getUserMedia(constraints)
      if (videoRef.current) {
        videoRef.current.srcObject = stream
        videoRef.current.play()
      }
    } catch (err) {
      console.error('Ошибка доступа к камере и микрофону:', err)
    }
  }

  const startRecording = () => {
    if (mediaRecorderRef.current) {
      recordedChunks.current = []
      mediaRecorderRef.current.start()
      setIsRecording(true)
      setRecordingTime(0)
      intervalRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1) // Обновляем время каждую секунду
      }, 1000)
      console.log('Запись началась')
    }
  }

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop()
      setIsRecording(false)
      clearInterval(intervalRef.current)
      saveRecording()
      console.log('Запись остановлена')
    }
  }

  const saveRecording = () => {
    mediaRecorderRef.current.onstop = () => {
      const blob = new Blob(recordedChunks.current, { type: 'video/webm' })
      console.log(blob)
      const videoURL = URL.createObjectURL(blob) // Создаем URL для предпросмотра
      setVideoURL(videoURL)
      setVideoBlob((prevState) => [...prevState, videoURL])
      setIsCameraActive(false)
      stopCamera()
      console.log('Файл сохранен в inputFile:', blob)
    }
  }

  const onReRecorder = async (video) => {
    setVideoURL(null)
    setVideoBlob([])
    URL.revokeObjectURL(video)
    await onCaptureMedia()
  }

  const onChangeUploadFile = (event) => {
    const selectedFiles = event.target.files
    const selectedFilesArray = Array.from(selectedFiles)

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file)
    })
    setInputFile((previousImages) => previousImages.concat(imagesArray))
    event.target.value = ''
  }

  function deleteHandler(image) {
    setInputFile(inputFile.filter((e) => e !== image))
    URL.revokeObjectURL(image)
  }
  function deleteHandlers() {
    inputFile.map((file) => URL.revokeObjectURL(file))
    setInputFile([])
  }

  const capturePhoto = () => {
    if (videoRef.current && canvasRef.current) {
      canvasRef.current.width = 800
      canvasRef.current.height = window.innerHeight
      const context = canvasRef.current.getContext('2d')
      context.drawImage(
        videoRef.current,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height,
      )

      canvasRef.current.toBlob((blob) => {
        const blobUrl = URL.createObjectURL(blob)
        setInputFile((prevState) => [...prevState, blobUrl])
        stopCamera()
        setIsCameraPhoto(false)
      }, 'image/png')
    }
  }

  const isBg = isCameraActive || videoURL || inputFile.length > 0

  const stopCamera = () => {
    const stream = videoRef.current?.srcObject
    if (stream) {
      const tracks = stream.getTracks()
      tracks.forEach((track) => track.stop())
    }
  }

  const onCreateStore = () => {
    dispatch(
      storesAction.createStores({
        files: videoBlob.length > 0 ? videoBlob : inputFile,
        text: inputText,
      }),
    )
    setVideoBlob([])
    setInputText('')
    setInputFile([])
    setVideoURL('')
    setIsCreateStoriesModal(false)
  }

  return (
    <>
      <div
        className={styles.modalBg}
        onClick={() => {
          setIsCreateStoriesModal(false)
        }}
      />
      <div className={styles.modalContainer}>
        <div className={theme ? styles.modalInner : styles.modalInnerB}>
          <div className={styles.modalHeader}>
            <div />
            <h2 className={styles.title}> {translate('Create Status')} </h2>
            <button
              type="button"
              className={styles.modalClose}
              onClick={() => {
                setIsCreateStoriesModal(false)
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#000000"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div className={styles.modalBOdy}>
            <div className={styles.subTitle}>What do you have in mind?</div>
            <input
              type={'text'}
              className={styles.inputModal}
              onChange={(event) => {
                setInputText(event.target.value)
              }}
            />
            <input
              id={'file'}
              type={'file'}
              hidden
              multiple
              onChange={onChangeUploadFile}
              className="fileInput"
              accept="image/png, image/jpeg, image/gif, video/*"
              capture="environment"
            />
            <div
              className={
                isBg
                  ? styles.videoRecorderContainerN
                  : styles.videoRecorderContainer
              }
            >
              {!isCameraActive ? (
                <>
                  {isCameraPhoto ? (
                    <>
                      <video
                        ref={videoRef}
                        width="100%"
                        autoPlay
                        className={styles.videoRecorder}
                      ></video>
                    </>
                  ) : (
                    <>
                      {videoURL ? (
                        <>
                          <video
                            src={videoURL}
                            width="100%"
                            controls
                            className={styles.videoRecorder}
                          ></video>
                          <button
                            className={styles.reRecorderButton}
                            onClick={async () => {
                              await onReRecorder(videoURL)
                            }}
                          >
                            {translate('Записать снова')}
                          </button>
                        </>
                      ) : (
                        <>
                          {inputFile.length > 0 ? (
                            <>
                              <div
                                className={`${styles.videoRecorderInner} ${inputFile.length > 1 ? styles.manyImagesInner : styles.filesList}`}
                              >
                                {inputFile.length === 1 ? (
                                  <>
                                    {inputFile.map((img, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className={styles.oneImage}
                                        >
                                          <img src={img} key={index} alt={''} />
                                        </div>
                                      )
                                    })}
                                  </>
                                ) : (
                                  <>
                                    {inputFile.map((img, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className={styles.manyImages}
                                        >
                                          <img src={img} key={index} alt={''} />
                                          <button
                                            type="button"
                                            className={styles.deleteImage}
                                            onClick={() => {
                                              deleteHandler(img)
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth="5.5"
                                              className="w-6 h-6"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                              ></path>
                                            </svg>
                                          </button>
                                        </div>
                                      )
                                    })}
                                  </>
                                )}
                              </div>
                              <div className={styles.imageAction}>
                                <button
                                  onClick={onSelectFile}
                                  className={styles.addImage}
                                >
                                  Добавить
                                </button>
                                <button
                                  onClick={deleteHandlers}
                                  className={styles.deleteAll}
                                >
                                  Очистить все
                                </button>
                              </div>
                            </>
                          ) : (
                            <div
                              className={styles.videoRecorderInner}
                              style={{
                                backgroundImage: `url(${bgImg})`,
                              }}
                            >
                              <div className={styles.buttonsContainer}>
                                <button
                                  onClick={onSelectFile}
                                  className={styles.uploadFile}
                                >
                                  <UploadImgIcon
                                    width={30}
                                    height={30}
                                    stroke={'currentColor'}
                                    fill={'rgb(13 148 136 / 1)'}
                                  />
                                  <span>изображение / видео</span>
                                </button>
                                {!isMobile && (
                                  <button
                                    onClick={onCapturePhoto}
                                    className={styles.uploadFile}
                                  >
                                    <PostIconCreate
                                      className={styles.ionicon}
                                    />
                                    <span>Сделать снимок</span>
                                  </button>
                                )}
                                <button
                                  onClick={onCaptureMedia}
                                  className={styles.uploadFile}
                                >
                                  <VideoCameraIconCreate
                                    className={styles.ionicon}
                                  />
                                  <span>Записать Видео</span>
                                </button>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <video
                    ref={videoRef}
                    width="100%"
                    autoPlay
                    muted
                    className={styles.videoRecorder}
                  ></video>
                  {isRecording && (
                    <div className={styles.timeFromPc}>
                      {formatTime(recordingTime)}
                    </div>
                  )}
                  <div
                    className={
                      isRecording ? styles.pulseButton : styles.buttonRecord
                    }
                  >
                    <button
                      className={isRecording ? styles.pulse : styles.circle}
                      onClick={isRecording ? stopRecording : startRecording}
                    ></button>
                  </div>
                </>
              )}
            </div>
            {isCameraActive && (
              <button
                className={styles.reRecorderButton}
                onClick={() => {
                  stopCamera()
                  setIsCameraActive(!isCameraActive)
                }}
              >
                {translate('Отмена')}
              </button>
            )}
            {isCameraPhoto && (
              <div className={styles.imageAction}>
                <button className={styles.addImage} onClick={capturePhoto}>
                  {translate('Take Photo')}
                </button>
                <button
                  onClick={() => {
                    stopCamera()
                    setIsCameraPhoto(false)
                  }}
                  className={styles.deleteAll}
                >
                  {translate('Cancel')}
                </button>
                <canvas
                  ref={canvasRef}
                  width="100%"
                  height="100%"
                  style={{ display: 'none' }}
                />
              </div>
            )}
            <div className={styles.createContainer}>
              <div className={styles.storesInfo}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="ionicon s-ion-icon"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z"
                    strokeMiterlimit="10"
                    fill={'none'}
                  ></path>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M256 128v144h96"
                    fill={'none'}
                  ></path>
                </svg>
                <p className={styles.createInfo}>
                  Your Status will be available for 24 Hours
                </p>
              </div>
              <button
                type="button"
                className={styles.butonCreate}
                onClick={onCreateStore}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateStoriesModal
