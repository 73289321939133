import React from 'react'
import styles from './index.module.scss'
import MessagesList from '../MessagesList'
function ChatBody({
  chatId,
  siteInfo,
  translate,
  theme,
  isMobile,
  online,
  userInfo,
  chatBodyRef,
}) {
  return (
    <div className={styles.chatLocationContainer}>
      <div className={styles.chatLocationContainerList}>
        <div className={styles.chatLocationContainerBody}>
          <div className={styles.chatLocationContainerOverflow}>
            <div
              className={styles.chatLocationContainerScrollOuter}
              ref={chatBodyRef}
            >
              <div className={styles.chatLocationContainerScrollInner}>
                <div className={styles.chatLocationContainerBodyWrapper}>
                  <div role={'list'} className={styles.chatLocationContainerL}>
                    {chatId.messages.length > 0 && (
                      <>
                        {chatId.messages.map((message, index) => (
                          <MessagesList
                            key={index}
                            message={message}
                            userInfo={userInfo}
                            translate={translate}
                            siteInfo={siteInfo}
                            theme={theme}
                            isMobile={isMobile}
                            online={online}
                          />
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatBody
