import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actionUserInfo from './actions/app.actions'
import * as actionSiteInfo from './actions/site.actions'
import * as actionStore from './actions/stores.actions'
import * as actionPosts from './actions/post.actions'
import PrivateRoutes from './roures/PrivateRoutes'
import PrivateStartedRoutes from './roures/PrivateStartedRoutes'
import PublicRoutes from './roures/PublicRoutes'
import { setPausedTime } from './utils'
import { socketUrl } from './api'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import * as actionsPost from './actions/post.actions'
import SocketClient from './SocketClient'

function App() {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const userInfo = useSelector((state) => state.app.user)
  const theme = useSelector((state) => state.app.theme)
  const siteInfo = useSelector((state) => state.site.siteInfo)
  const id = userInfo && userInfo.user_id
  const user = userInfo
  const { i18n } = useTranslation('common')
  dayjs.locale(i18n.language)

  useEffect(() => {
    dispatch(actionsPost.postColoredPatternsInfo())
  }, [dispatch])

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        dispatch(
          actionUserInfo.toggleCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }),
        )
      })
    }
  }, [dispatch])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(actionStore.storeInfo())
    }
  }, [dispatch, isAuthenticated])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(actionPosts.postInfo())
    }
  }, [dispatch, isAuthenticated])

  useEffect(() => {
    dispatch(actionStore.updateItemsClearStores(null))
  }, [dispatch])

  useEffect(() => {
    ;(async () => {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        const constraints = { video: true, audio: true }
        await navigator.mediaDevices.getUserMedia(constraints)
      }
    })()
  }, [])

  useEffect(() => {
    const body = document.body
    if (body) {
      body.classList = theme
    }
    setPausedTime(0)
  }, [theme])

  useEffect(() => {
    if (siteInfo && siteInfo) {
      const link = document.querySelector("link[rel*='icon']")
      if (link) {
        link.href = `${process.env.REACT_APP_INFO_BASE_URL}/${siteInfo.favicon}`
      }
      document.title = `ХАТ ${siteInfo.question}`
    }
  }, [siteInfo])

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(actionSiteInfo.noneAuthInfo())
    } else {
      dispatch(actionSiteInfo.siteInfo())
    }
  }, [dispatch, isAuthenticated])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(actionUserInfo.userInfo())
    }
  }, [dispatch, isAuthenticated])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(actionUserInfo.allUserInfo())
    }
  }, [isAuthenticated, dispatch])

  if (isAuthenticated && userInfo) {
    if (!userInfo.user_started) {
      return (
        <>
          <SocketClient socket={socketUrl} id={id} user={user} />
          <PrivateStartedRoutes />
        </>
      )
    } else {
      return (
        <>
          <SocketClient socket={socketUrl} id={id} user={user} />
          <PrivateRoutes />
        </>
      )
    }
  } else {
    return <PublicRoutes />
  }
}
export default App
