import React from 'react'
import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps'
import styles from '../index.module.scss'

function PostLocation({ post }) {
  const coords = JSON.parse(post.location)
  return (
    <div className={styles.yanexMap}>
      <YMaps>
        <Map
          width={600}
          height={400}
          style={{
            borderWidth: 2,
            borderColor: '#0d6efd4a',
            width: '100%',
            height: 400,
            borderRadius: 30,
            overflow: 'hidden',
            marginBottom: '5px',
          }}
          defaultState={{
            center: [coords.latitude, coords.longitude],
            zoom: 15,
          }}
        >
          <Placemark
            height={20}
            width={20}
            geometry={[coords.latitude, coords.longitude]}
            options={{
              iconImageHref: `${process.env.REACT_APP_PSTS_BASE_URL}${post.user_avatar}`,
              visible: true,
              iconImageSize: [50, 50],
              iconLayout: 'default#image',
            }}
          ></Placemark>
        </Map>
      </YMaps>
    </div>
  )
}
export default PostLocation
