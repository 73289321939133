// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stories.snapgram .story > .item-link {
  text-decoration: none;
  color: #333;
}
.stories.snapgram .story > .item-link > .item-preview {
  border-radius: 50%;
  padding: 2px;
  background: radial-gradient(ellipse at 70% 70%, #ee583f 8%, #d92d77 42%, #bd3381 58%);
}
.stories.snapgram .story > .item-link > .item-preview img {
  border-radius: 50%;
  border: 3px solid #fff;
}

.stories.snapgram .story.seen {
  opacity: 0.75;
}
.stories.snapgram .story.seen > a > .item-preview {
  background: #999;
}
.stories.snapgram .story.seen > a {
  color: #999 !important;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Private/FeedPage/StylesStories/snapgram.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,qFAAqF;AACvF;AACA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;AACA;EACE,gBAAgB;AAClB;AACA;EACE,sBAAsB;AACxB","sourcesContent":[".stories.snapgram .story > .item-link {\n  text-decoration: none;\n  color: #333;\n}\n.stories.snapgram .story > .item-link > .item-preview {\n  border-radius: 50%;\n  padding: 2px;\n  background: radial-gradient(ellipse at 70% 70%, #ee583f 8%, #d92d77 42%, #bd3381 58%);\n}\n.stories.snapgram .story > .item-link > .item-preview img {\n  border-radius: 50%;\n  border: 3px solid #fff;\n}\n\n.stories.snapgram .story.seen {\n  opacity: 0.75;\n}\n.stories.snapgram .story.seen > a > .item-preview {\n  background: #999;\n}\n.stories.snapgram .story.seen > a {\n  color: #999 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
