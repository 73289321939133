import * as ActionTypes from '../constants/post.constants'

const initialState = {
  allPosts: [],
  postProgress: 0,
  patterns: [],
  loadings: {
    newPost: false,
  },
  errors: {
    newPost: null,
  },
}
const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ALL_POST_INFO_REQUEST: {
      return {
        ...state,
      }
    }
    case ActionTypes.ALL_POST_INFO_SUCCESS: {
      return {
        ...state,
        allPosts: [...action.payload],
      }
    }
    case ActionTypes.ALL_POST_INFO_ERROR: {
      return {
        ...state,
        loadings: { newPost: false },
        errors: { newPost: action.payload },
      }
    }
    case ActionTypes.CREATE_POST_REQUEST: {
      return {
        ...state,
        loadings: { newPost: true },
      }
    }
    case ActionTypes.CREATE_POST_NONE_FILES_REQUEST: {
      return {
        ...state,
        loadings: { newPost: true },
      }
    }
    case ActionTypes.UPDATE_POST_REQUEST: {
      return {
        ...state,
        loadings: { newPost: true },
      }
    }
    case ActionTypes.CREATE_POST_PROGRESS: {
      return {
        ...state,
        postProgress: action.payload,
      }
    }
    case ActionTypes.NEW_POST_SUCCESS: {
      return {
        ...state,
        loadings: { newPost: false },
        postProgress: 0,
        allPosts: [action.payload, ...state.allPosts],
      }
    }
    case ActionTypes.UPDATE_POST_SUCCESS: {
      return {
        ...state,
        loadings: { newPost: false },
      }
    }
    case ActionTypes.POST_COLORED_PATTERNS_REQUEST: {
      return {
        ...state,
      }
    }
    case ActionTypes.POST_COLORED_PATTERNS_SUCCESS: {
      return {
        ...state,
        patterns: [...action.payload],
      }
    }
    case ActionTypes.POST_COLORED_PATTERNS_ERROR: {
      return {
        ...state,
      }
    }
    default:
      return state
  }
}
export default postReducer
