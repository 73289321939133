export const getAccessToken = () => {
  try {
    const accessToken = localStorage.getItem('access_token')
    if (accessToken === null) {
      return undefined
    }
    return accessToken
  } catch (error) {
    return undefined
  }
}

export const setAccessToken = (accessToken) => {
  localStorage.setItem('access_token', accessToken)
}

export const getPausedTime = () => {
  try {
    const pausedTime = localStorage.getItem('paused_time')
    if (pausedTime === null) {
      return undefined
    }
    return pausedTime
  } catch (error) {
    return undefined
  }
}

export const setPausedTime = (pausedTime) => {
  localStorage.setItem('paused_time', pausedTime)
}

export const isValidImageType = (imageType) => {
  const imageValidation = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']
  return imageValidation.includes(imageType)
}

export const formatTime = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60)
  const seconds = timeInSeconds % 60
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}

export const timestamp = function () {
  let timeIndex = 0
  const shifts = [
    35,
    60,
    60 * 3,
    60 * 60 * 2,
    60 * 60 * 25,
    60 * 60 * 24 * 4,
    60 * 60 * 24 * 10,
  ]

  const now = new Date()
  const shift = shifts[timeIndex++] || 0
  const date = new Date(now - shift * 1000)

  return date.getTime() / 1000
}

export const stories = [
  {
    id: 'ramon',
    photo:
      'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/users/1.jpg',
    name: 'Ramon',
    length: 5,
    items: [
      {
        id: 'ramon-1',
        type: 'photo',
        length: 5,
        src: 'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/stories/1.jpg',
        preview:
          'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/stories/1.jpg',
        link: '',
        linkText: false,
        time: timestamp(),
      },
      {
        id: 'ramon-2',
        type: 'video',
        length: timestamp(),
        src: 'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/stories/2.mp4',
        preview:
          'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/stories/2.jpg',
        link: '',
        linkText: false,
        time: timestamp(),
      },
      {
        id: 'ramon-3',
        type: 'photo',
        length: 5,
        src: 'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/stories/3.png',
        preview:
          'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/stories/3.png',
        link: 'https://ramon.codes',
        linkText: 'Visit my Portfolio',
        time: timestamp(),
      },
    ],
  },
  {
    id: 'gorillaz',
    photo:
      'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/users/2.jpg',
    name: 'Gorillaz',
    length: timestamp(),
    items: [
      {
        id: 'gorillaz-1',
        type: 'video',
        length: timestamp(),
        src: '/1.mp4',
        preview:
          'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/stories/4.jpg',
        link: '',
        linkText: false,
        time: timestamp(),
        itemId: 'gorillaz',
      },
      {
        id: 'gorillaz-2',
        type: 'photo',
        length: 5,
        src: 'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/stories/5.jpg',
        preview:
          'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/stories/5.jpg',
        link: '',
        linkText: false,
        time: timestamp(),
        itemId: 'gorillaz',
      },
    ],
  },
  {
    id: 'ladygaga',
    photo:
      'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/users/3.jpg',
    name: 'Lady Gaga',
    length: 5,
    items: [
      {
        itemId: 'ladygaga',
        id: 'ladygaga-1',
        type: 'photo',
        length: 5,
        src: 'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/stories/6.jpg',
        preview:
          'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/stories/6.jpg',
        link: '',
        linkText: false,
        time: timestamp(),
      },
      {
        id: 'ladygaga-2',
        type: 'photo',
        length: 5,
        src: 'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/stories/7.jpg',
        preview:
          'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/stories/7.jpg',
        link: 'http://ladygaga.com',
        linkText: false,
        time: timestamp(),
        itemId: 'ladygaga',
      },
    ],
  },
  {
    id: 'starboy',
    photo:
      'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/users/4.jpg',
    name: 'The Weeknd',
    length: timestamp(),
    items: [
      {
        itemId: 'starboy',
        id: 'starboy-1',
        type: 'photo',
        length: 5,
        src: 'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/stories/8.jpg',
        preview:
          'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/stories/8.jpg',
        link: '',
        linkText: false,
        time: timestamp(),
      },
    ],
  },
  {
    id: 'riversquomo',
    photo:
      'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/users/5.jpg',
    name: 'Rivers Cuomo',
    length: timestamp(),
    items: [
      {
        itemId: 'riversquomo',
        id: 'riverscuomo-1',
        type: 'photo',
        length: 5,
        src: 'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/stories/9.jpg',
        preview:
          'https://raw.githubusercontent.com/ramonszo/assets/master/zuck.js/stories/9.jpg',
        link: '',
        linkText: false,
        time: timestamp(),
      },
    ],
  },
]

export const getCurrentSkin = function () {
  const header = document.getElementById('header')
  let skin = location.href.split('skin=')[1]

  if (!skin) {
    skin = 'Snapgram'
  }

  if (skin.indexOf('#') !== -1) {
    skin = skin.split('#')[0]
  }

  const skins = {
    Snapgram: {
      avatars: true,
      list: false,
      autoFullScreen: false,
      cubeEffect: true,
      paginationArrows: true,
    },

    VemDeZAP: {
      avatars: false,
      list: true,
      autoFullScreen: false,
      cubeEffect: false,
      paginationArrows: true,
    },

    FaceSnap: {
      avatars: true,
      list: false,
      autoFullScreen: true,
      cubeEffect: false,
      paginationArrows: true,
    },

    Snapssenger: {
      avatars: false,
      list: false,
      autoFullScreen: false,
      cubeEffect: false,
      paginationArrows: false,
    },
  }

  const el = document.querySelectorAll('#skin option')
  const total = el.length
  for (let i = 0; i < total; i++) {
    const what = skin === el[i].value ? true : false

    if (what) {
      el[i].setAttribute('selected', 'selected')

      header.innerHTML = skin
      header.className = skin
    } else {
      el[i].removeAttribute('selected')
    }
  }

  return {
    name: skin,
    params: skins[skin],
  }
}
