import React from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import FriendsList from './FriendsList'

function ProfileBlockFriends({ theme, translate }) {
  return (
    <div
      className={
        theme ? styles.blockFriendsContainer : styles.blockFriendsContainerB
      }
    >
      <div className={styles.blockFriendsHeader}>
        <div>
          <div className={styles.friendsTitle}>{translate('Друзья')}</div>
          <div className={styles.friendsSubTitle}>
            3489 {translate('Друзей')}
          </div>
        </div>
        <Link to={'/'}>{translate('Все друзья')}</Link>
      </div>
      <div className={styles.friendsListContainer}>
        <FriendsList translate={translate} />
      </div>
    </div>
  )
}
export default ProfileBlockFriends
