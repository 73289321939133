import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import * as ActionTypes from '../constants/post.constants'
import * as actions from '../actions/post.actions'
import * as api from '../api/post.api'
import { END, eventChannel } from 'redux-saga'
import { socketUrl } from '../api'

const convertBlobUrlToFile = async (blobUrl, fileName) => {
  try {
    const response = await fetch(blobUrl)
    const blob = await response.blob()
    return new File([blob], fileName, { type: blob.type })
  } catch (error) {
    console.error('Error creating file from blob:', error)
    return null
  }
}

async function createUploader(action) {
  let emit
  const chan = eventChannel((emitter) => {
    emit = emitter
    return () => {}
  })
  const formData = new FormData()
  const files = await Promise.all(
    action.files.map(async (file, index) => {
      const extension =
        file.type === 'audio' ? '.mp3' : file.type === 'video' ? '.mp4' : '.jpg'
      return await convertBlobUrlToFile(
        file.url,
        `file-${index + 1}${extension}`,
      )
    }),
  )
  files.forEach((file) => {
    formData.append('files[]', file)
  })
  formData.append('text', JSON.stringify({ text: action.text }))
  formData.append('user_type', JSON.stringify({ user_type: action.user_type }))
  formData.append('privacy', JSON.stringify({ privacy: action.privacy }))
  formData.append('post_type', JSON.stringify({ post_type: action.post_type }))
  formData.append(
    'is_anonymous',
    JSON.stringify({ is_anonymous: action.is_anonymous }),
  )
  formData.append('is_hidden', JSON.stringify({ is_hidden: action.is_hidden }))
  formData.append(
    'group_approved',
    JSON.stringify({ group_approved: action.group_approved }),
  )
  formData.append(
    'feeling_action',
    JSON.stringify({ feeling_action: action.feeling_action }),
  )
  formData.append(
    'feeling_value',
    JSON.stringify({ feeling_value: action.feeling_value }),
  )
  const onUploadProgress = ({ total, loaded }) => {
    const percentage = Math.round((loaded * 100) / total)
    emit(percentage)
    if (percentage === 100) emit(END)
  }
  const uploadPromise = api.cretePostsFiles(formData, onUploadProgress)
  return [uploadPromise, chan]
}

function* uploadProgressWatcher(chan) {
  while (true) {
    const progress = yield take(chan)
    yield put(actions.createPostProgress(progress))
  }
}

const updatePostInfo = (data) => {
  return new Promise(() => {
    socketUrl.emit('updatePosts', data)
  })
}
const newPost = (data) => {
  return new Promise(() => {
    socketUrl.emit('newPosts', data)
  })
}

export function* createPostFiles(action) {
  try {
    const [uploadPromise, chan] = yield call(createUploader, action.payload)
    yield fork(uploadProgressWatcher, chan)
    const response = yield call(() => uploadPromise)
    yield put(actions.newPost(response))
    yield call(newPost, response)
  } catch (error) {
    yield put(actions.postInfoError(error))
    toast.error(error.error)
  }
}

export function* cretePost(action) {
  try {
    const response = yield call(api.createPost, action.payload)
    yield put(actions.newPost(response))
    yield call(newPost, response)
  } catch (error) {
    yield put(actions.postInfoError(error))
    toast.error(error.error)
  }
}

export function* updatePost(action) {
  try {
    const response = yield call(api.updatePost, action.payload)
    yield put(actions.updatePost(response))
    yield call(updatePostInfo, response)
  } catch (error) {
    yield put(actions.postInfoError(error))
    toast.error(error.error)
  }
}

export function* postInfo() {
  try {
    const response = yield call(api.postsInfo)
    if (response) {
      yield put(actions.postInfoSuccess(response))
    }
  } catch (error) {
    yield put(actions.postInfoError(error))
    toast.error(error.error)
  }
}

export function* postPatternsInfo() {
  try {
    const response = yield call(api.postsPatternsInfo)
    if (response) {
      yield put(actions.postColoredPatternsInfoSuccess(response))
    }
  } catch (error) {
    yield put(actions.postColoredPatternsInfoError(error))
    toast.error(error.error)
  }
}

export default function* postSaga() {
  yield all([
    takeEvery(ActionTypes.ALL_POST_INFO_REQUEST, postInfo),
    takeEvery(ActionTypes.CREATE_POST_REQUEST, createPostFiles),
    takeEvery(ActionTypes.CREATE_POST_NONE_FILES_REQUEST, cretePost),
    takeEvery(ActionTypes.UPDATE_POST_REQUEST, updatePost),
    takeEvery(ActionTypes.POST_COLORED_PATTERNS_REQUEST, postPatternsInfo),
  ])
}
