import React, { useEffect } from 'react'
import styles from '../../index.module.scss'

function VideoTwo({
  video,
  setInitialIndexMedia,
  videoIndex,
  setIsModalMedia,
}) {
  return (
    <button
      className={styles.mediaButton}
      onClick={() => {
        setInitialIndexMedia(videoIndex)
        setIsModalMedia(true)
      }}
    >
      <div className={styles.videoTwo}>
        <video preload="metadata" onClick={(e) => e.preventDefault()}>
          <source
            src={`${process.env.REACT_APP_BASE_TINY_MCE_URL}/${video.source}#t=0.10`}
            type="video/mp4"
          />
        </video>
        <div className={styles.buttonPlay} />
      </div>
    </button>
  )
}

export default VideoTwo
