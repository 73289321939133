import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import axios from 'axios'
import * as actionsPost from '../../actions/post.actions'
import { categoryNewsData } from '../../utils/datas/categoryNews.data'

function NewsPost({
  setIsArticle,
  dispatch,
  theme,
  translate,
  userInfo,
  setIsCreatePostModal,
}) {
  const [nameNews, setNameNews] = useState('')
  const [posterImage, setPosterImage] = useState([])
  const [categoryValue, setCategoryValue] = useState('')
  const [initialValue, setInitialValue] = useState('')
  const [hashtags, setHashtags] = useState([])
  const [newHashtags, setNewHashtags] = useState('')

  useEffect(() => {
    ;(async () => {
      if (window.TinyMce) {
        await window.TinyMce().Editor.init({
          selector: '.js_wysiwyg',
          language: userInfo.user_language,
          branding: false,
          height: 300,
          relative_urls: false,
          convert_urls: true,
          remove_script_host: false,
          image_advtab: true,
          paste_data_images: true,
          toolbar:
            'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image uploadImages | preview media fullpage | forecolor backcolor | ltr rtl',
          plugins: [
            'advlist autolink link image lists charmap preview hr anchor pagebreak spellchecker',
            'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
            'save table directionality paste',
          ],
          images_upload_handler: async function (blobInfo, success, failure) {
            try {
              const formData = new FormData()
              formData.append('file', blobInfo.blob(), blobInfo.filename())
              const response = await axios.post(
                `${process.env.REACT_APP_PSTS_BASE_URL}api/upload_from_news`,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                },
              )
              if (response.data && response.data.newAvatarUrl) {
                const imageUrl = `${process.env.REACT_APP_BASE_TINY_MCE_URL}/${response.data.newAvatarUrl}`
                success(imageUrl)
              } else {
                failure('Ошибка: некорректный ответ от сервера')
              }
            } catch (error) {
              failure('Ошибка загрузки изображения: ' + error.message)
            }
          },
          setup: (editor) => {
            editor.on('change', () => {
              const content = editor.getContent()
              setInitialValue(content)
            })
            editor.on('input', () => {
              const content = editor.getContent()
              setInitialValue(content)
            })
          },
        })
      }
    })()
  }, [])

  const posterOnChange = (event) => {
    const selectedFiles = event.target.files
    const selectedFilesArray = Array.from(selectedFiles)

    const filesArray = selectedFilesArray.map((file) => {
      return {
        url: URL.createObjectURL(file),
        type: file.type.startsWith('video') ? 'video' : 'image',
      }
    })
    setPosterImage((previousImages) => previousImages.concat(filesArray))
    event.target.value = ''
  }

  function deleteHandler(image) {
    setPosterImage(posterImage.filter((e) => e.url !== image))
    URL.revokeObjectURL(image)
  }

  const handleTagRemove = (tag) => {
    setHashtags(hashtags.filter((t) => t !== tag))
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const newTags = newHashtags
        .split(',')
        .map((tag) => tag.trim())
        .filter((tag) => tag && !hashtags.includes(tag))
      if (newHashtags.length > 0) {
        setHashtags([...hashtags, ...newTags])
        setNewHashtags('')
      }
    }
  }

  const newsCreate = () => {
    if (
      initialValue &&
      posterImage.length > 0 &&
      categoryValue &&
      hashtags.length > 0 &&
      nameNews
    ) {
      dispatch(
        actionsPost.createNews({
          nameNews: nameNews,
          files: posterImage,
          categoryValue: categoryValue,
          html: initialValue,
          hashtags: hashtags,
        }),
      )
      setIsArticle(false)
      setIsCreatePostModal(false)
    }
  }

  return (
    <div className={!theme ? styles.modalArticle : styles.modalArticleB}>
      <div className={styles.modalHeader}>
        <div />
        <div className={styles.titele}>{translate('Написать статью')}</div>
        <button
          className={styles.buttonClose}
          onClick={() => {
            setIsArticle(false)
          }}
        >
          <i className={'fa fa-window-close'}></i>
        </button>
      </div>
      <div className={styles.modalBody}>
        <input
          type={'text'}
          placeholder={'Заголовок'}
          onChange={(event) => {
            setNameNews(event.target.value)
          }}
          value={nameNews}
          className={styles.inputTitle}
        />
        <textarea className={'js_wysiwyg'}></textarea>
        <div className={styles.posterContainer}>
          <div>{translate('Обложка')}</div>
          <div
            className="x-image"
            style={{
              backgroundImage:
                posterImage.length > 0 ? `url(${posterImage[0]?.url})` : '',
            }}
          >
            {posterImage.length > 0 && (
              <button
                type="button"
                className="close  js_x-image-remover"
                title="Удалить"
                onClick={() => {
                  deleteHandler(posterImage[0].url)
                }}
              >
                <span>×</span>
              </button>
            )}
            <div className="x-uploader">
              <input
                name="file"
                type="file"
                accept=".png, .gif, .jpeg, .jpg"
                onChange={posterOnChange}
              />
              <i
                className="fa fa-camera fa-lg js_x-uploader"
                data-handle="x-image"
              ></i>
            </div>
          </div>
        </div>
        <div className={styles.posterContainer}>
          <div>{translate('Категория')}</div>
          <select
            className={`form-control ${styles.selector}`}
            name="category"
            onChange={(e) => setCategoryValue(e.target.value)}
          >
            <option
              value="none"
              defaultValue={translate('Выбор категори')}
              disabled
              selected
            >
              Выбор категорий
            </option>
            {categoryNewsData.map((item, index) => (
              <option value={item.name}>{translate(item.name)}</option>
            ))}
          </select>
        </div>
        <div className={styles.texgsContainer}>
          <div>{translate('Теги')}</div>
          <div className={`form-control ${styles.tags}`}>
            {hashtags.length > 0 && (
              <>
                {hashtags.map((tag, index) => (
                  <div
                    key={index}
                    className={styles.hashtag}
                    onClick={() => handleTagRemove(tag)}
                  >
                    #{tag} <span className={styles.removeTag}>✖</span>
                  </div>
                ))}
              </>
            )}
            <input
              type={'text'}
              placeholder={'Введите тег'}
              className={styles.inpetTeg}
              spellCheck={false}
              value={newHashtags}
              onChange={(e) => {
                setNewHashtags(e.target.value)
              }}
              onKeyUp={handleKeyDown}
            />
          </div>
        </div>
      </div>
      <div className={`card-footer text-right ${styles.save}`}>
        <button className="btn btn-primary" onClick={newsCreate}>
          Опубликовать
        </button>
      </div>
    </div>
  )
}

export default NewsPost
