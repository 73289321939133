// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HTML: <div class="loader"></div> */
.StageStart_loader__gbONo {
  width: 86px;
  height: 50px;
  border-radius: 50px;
  background: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(100% - 15px), #ccc calc(100% - 14px) 99%, rgba(0, 0, 0, 0)) left, radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(100% - 15px), #ccc calc(100% - 14px) 99%, rgba(0, 0, 0, 0)) right;
  background-size: calc(50% + 7px) 100%;
  background-repeat: no-repeat;
  position: relative;
  animation: StageStart_l10-0__zKIL2 1s infinite linear;
}

.StageStart_loader__gbONo:before {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #f03355;
  transform-origin: -14px 50%;
  animation: StageStart_l10-1__WIV3A 0.5s infinite linear;
}

@keyframes StageStart_l10-0__zKIL2 {
  0%, 49.99% {
    transform: scaleX(1);
  }
  50%, 100% {
    transform: scaleX(-1);
  }
}
@keyframes StageStart_l10-1__WIV3A {
  100% {
    transform: rotate(1turn);
  }
}
.StageStart_progress__ZCk7c {
  display: flex;
  font-size: 0.75rem;
  align-items: center;
  justify-content: center;
  height: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/StageStart/index.module.scss"],"names":[],"mappings":"AAAA,qCAAA;AACA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,yPACQ;EAER,qCAAA;EACA,4BAAA;EACA,kBAAA;EACA,qDAAA;AADF;;AAGA;EACE,WAAA;EACA,kBAAA;EACA,QAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,2BAAA;EACA,uDAAA;AAAF;;AAEA;EACE;IAAY,oBAAA;EAEZ;EADA;IAAW,qBAAA;EAIX;AACF;AAHA;EACE;IAAO,wBAAA;EAMP;AACF;AALA;EAEE,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AAOF","sourcesContent":["/* HTML: <div class=\"loader\"></div> */\r\n.loader {\r\n  width: calc(100px - 14px);\r\n  height: 50px;\r\n  border-radius: 50px;\r\n  background:\r\n          radial-gradient(farthest-side,#0000 calc(100% - 15px),#ccc calc(100% - 14px) 99%,#0000) left,\r\n          radial-gradient(farthest-side,#0000 calc(100% - 15px),#ccc calc(100% - 14px) 99%,#0000) right;\r\n  background-size: calc(50% + 7px) 100%;\r\n  background-repeat: no-repeat;\r\n  position: relative;\r\n  animation: l10-0 1s infinite linear;\r\n}\r\n.loader:before {\r\n  content: \"\";\r\n  position: absolute;\r\n  inset: 0;\r\n  margin: auto;\r\n  width: 10px;\r\n  height: 10px;\r\n  border-radius: 50%;\r\n  background: #f03355;\r\n  transform-origin: -14px 50%;\r\n  animation: l10-1 0.5s infinite linear;\r\n}\r\n@keyframes l10-0 {\r\n  0%,49.99% { transform:scaleX(1)}\r\n  50%,100% { transform:scaleX(-1)}\r\n}\r\n@keyframes l10-1 {\r\n  100% { transform:rotate(1turn)}\r\n}\r\n.progress {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  font-size: .75rem;\r\n  align-items: center;\r\n  justify-content: center;\r\n  height: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `StageStart_loader__gbONo`,
	"l10-0": `StageStart_l10-0__zKIL2`,
	"l10-1": `StageStart_l10-1__WIV3A`,
	"progress": `StageStart_progress__ZCk7c`
};
export default ___CSS_LOADER_EXPORT___;
