import React from 'react'
import styles from '../index.module.scss'

function PostText({ post }) {
  return (
    <>
      <div
        className={styles.postText}
        dangerouslySetInnerHTML={{ __html: post.text }}
      ></div>
    </>
  )
}
export default PostText
