import React, { useRef, useState } from 'react'
import PageContainer from '../../../components/PageContainer'
import { useTranslation } from 'react-i18next'
import * as actionsMessages from '../../../actions/messages.actions'
import { useDispatch, useSelector } from 'react-redux'
import styles from './index.module.scss'
import SearchUsersChat from '../../../components/SearchUsersChat'
import ChatLists from '../../../components/ChatLists'
import ChatHeader from '../../../components/ChatHeader'
import ChatBody from '../../../components/ChatBody'
import { IconPlusAction, IconSile } from '../../../assets'
import { useParams } from 'react-router-dom'

function MessagesPage() {
  const { user_name } = useParams()
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const userInfo = useSelector((state) => state.app.user)
  const online = useSelector((state) => state.app.checkOnline)
  const theme = useSelector((state) => state.app.theme)
  const messages = useSelector((state) => state.messages.messages)
  const chatId = useSelector((state) => state.messages.chatId)
  const siteInfo = useSelector((state) => state.site.siteInfo)
  const allUsers = useSelector((state) => state.app.allUsers).filter(
    (i) => i.user_id !== userInfo.user_id,
  )
  const [inputSearch, setInputSearch] = useState('')
  const [messageInput, setMessageInput] = useState('')
  const [isEmoji, setIsEmoji] = useState(false)
  const chatBodyRef = useRef(null)
  const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent)

  const onChatId = (data) => {
    dispatch(actionsMessages.chatId(data))
  }

  const filteredUsers = allUsers.filter((user) => {
    const searchQuery = inputSearch.toLowerCase()
    const firstNameMatch = user.user_firstname
      .toLowerCase()
      .includes(searchQuery)
    const lastNameMatch = user.user_lastname.toLowerCase().includes(searchQuery)
    return (
      (firstNameMatch && user.user_id !== userInfo.user_id) ||
      (lastNameMatch && user.user_id !== userInfo.user_id)
    )
  })

  const placeCaretAtEnd = (el) => {
    el.focus()
    const range = document.createRange()
    range.selectNodeContents(el)
    range.collapse(false)
    const sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)
  }

  const sendMessage = (data) => {
    dispatch(actionsMessages.sendMessage(data))
    setMessageInput('')
    setTimeout(() => {
      if (chatBodyRef.current) {
        chatBodyRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
    }, 50)
  }

  return (
    <>
      {siteInfo && userInfo && (
        <PageContainer
          theme={theme}
          siteInfo={siteInfo}
          translate={t}
          userInfo={userInfo}
        >
          <section className={`${styles.messagesContainer}`}>
            <div
              className={
                theme === 'bg-white'
                  ? styles.messagesInner
                  : styles.messagesInnerB
              }
            >
              <div className={styles.leftBar}>
                <div className={styles.sideChat}>
                  <div className={styles.sideChatHeader}>
                    <div className={styles.sideChatHeaderActions}>
                      <h2 className={styles.sideChatTitle}>{t('messages')}</h2>
                      <div className={styles.sideChatButtonsInner}>
                        <button className={styles.buttonSetting}>
                          <svg
                            width={30}
                            height={30}
                            fill={'none'}
                            xmlns="http://www.w3.org/2000/svg"
                            className="ionicon s-ion-icon"
                            viewBox="0 0 512 512"
                            stroke={'#5e5454'}
                            strokeWidth={'25'}
                          >
                            <path
                              d="M262.29 192.31a64 64 0 1057.4 57.4 64.13 64.13 0 00-57.4-57.4zM416.39 256a154.34 154.34 0 01-1.53 20.79l45.21 35.46a10.81 10.81 0 012.45 13.75l-42.77 74a10.81 10.81 0 01-13.14 4.59l-44.9-18.08a16.11 16.11 0 00-15.17 1.75A164.48 164.48 0 01325 400.8a15.94 15.94 0 00-8.82 12.14l-6.73 47.89a11.08 11.08 0 01-10.68 9.17h-85.54a11.11 11.11 0 01-10.69-8.87l-6.72-47.82a16.07 16.07 0 00-9-12.22 155.3 155.3 0 01-21.46-12.57 16 16 0 00-15.11-1.71l-44.89 18.07a10.81 10.81 0 01-13.14-4.58l-42.77-74a10.8 10.8 0 012.45-13.75l38.21-30a16.05 16.05 0 006-14.08c-.36-4.17-.58-8.33-.58-12.5s.21-8.27.58-12.35a16 16 0 00-6.07-13.94l-38.19-30A10.81 10.81 0 0149.48 186l42.77-74a10.81 10.81 0 0113.14-4.59l44.9 18.08a16.11 16.11 0 0015.17-1.75A164.48 164.48 0 01187 111.2a15.94 15.94 0 008.82-12.14l6.73-47.89A11.08 11.08 0 01213.23 42h85.54a11.11 11.11 0 0110.69 8.87l6.72 47.82a16.07 16.07 0 009 12.22 155.3 155.3 0 0121.46 12.57 16 16 0 0015.11 1.71l44.89-18.07a10.81 10.81 0 0113.14 4.58l42.77 74a10.8 10.8 0 01-2.45 13.75l-38.21 30a16.05 16.05 0 00-6.05 14.08c.33 4.14.55 8.3.55 12.47z"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="ionicon-fill-none ionicon-stroke-width"
                            ></path>
                          </svg>
                        </button>
                        <button className={styles.buttonSetting}>
                          <svg
                            width={30}
                            height={30}
                            fill={'none'}
                            xmlns="http://www.w3.org/2000/svg"
                            className="ionicon s-ion-icon"
                            viewBox="0 0 512 512"
                            stroke={'#5e5454'}
                            strokeWidth={'25'}
                          >
                            <path
                              d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                              strokeMiterlimit="10"
                              className="ionicon-fill-none ionicon-stroke-width"
                            ></path>
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M352 176L217.6 336 160 272"
                              className="ionicon-fill-none ionicon-stroke-width"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className={styles.messagesSerchInner}>
                      <input
                        type="text"
                        placeholder="Search"
                        value={inputSearch}
                        onChange={(e) => {
                          setInputSearch(e.target.value)
                        }}
                        className={styles.serchInput}
                      />
                      <div className={styles.searchingBox}>
                        <svg
                          width={25}
                          height={25}
                          fill={'#5e5454'}
                          xmlns="http://www.w3.org/2000/svg"
                          className="ionicon s-ion-icon"
                          viewBox="0 0 512 512"
                          strokeWidth={'25'}
                        >
                          <path d="M456.69 421.39L362.6 327.3a173.81 173.81 0 0034.84-104.58C397.44 126.38 319.06 48 222.72 48S48 126.38 48 222.72s78.38 174.72 174.72 174.72A173.81 173.81 0 00327.3 362.6l94.09 94.09a25 25 0 0035.3-35.3zM97.92 222.72a124.8 124.8 0 11124.8 124.8 124.95 124.95 0 01-124.8-124.8z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className={styles.chatUsersContainer}>
                    {inputSearch ? (
                      <>
                        {filteredUsers.length > 0 ? (
                          <>
                            {filteredUsers.map((us, index) => (
                              <SearchUsersChat
                                key={index}
                                user={us}
                                online={online}
                                userInfo={userInfo}
                                messages={messages}
                                siteInfo={siteInfo}
                                onChatId={onChatId}
                              />
                            ))}
                          </>
                        ) : (
                          <div>{t('Ничего не найденно')}</div>
                        )}
                      </>
                    ) : (
                      <>
                        {messages.length > 0 ? (
                          <>
                            {messages.map((chat, index) => (
                              <ChatLists
                                chat={chat}
                                key={index}
                                online={online}
                                siteInfo={siteInfo}
                                onChatId={onChatId}
                              />
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {chatId ? (
                <div className={styles.chatContainer}>
                  <ChatHeader
                    chatId={chatId}
                    translate={t}
                    online={online}
                    theme={theme === 'bg-white'}
                    isMobile={isMobile}
                    siteInfo={siteInfo}
                    onChatId={onChatId}
                    userInfo={userInfo}
                    dispatch={dispatch}
                  />
                  <ChatBody
                    chatId={chatId}
                    translate={t}
                    online={online}
                    chatBodyRef={chatBodyRef}
                    theme={theme === 'bg-white'}
                    isMobile={isMobile}
                    siteInfo={siteInfo}
                    onChatId={onChatId}
                    userInfo={userInfo}
                  />
                  <div className={styles.inputContainer}>
                    <div className={styles.addInner}>
                      <button className={styles.ButtonsInpetActions}>
                        <IconPlusAction height={30} width={30} />
                      </button>
                      <button className={styles.ButtonsInpetActions}>
                        <IconSile height={30} width={30} />
                      </button>
                    </div>
                    <span
                      inputMode={'text'}
                      translate="no"
                      onKeyDown={(event) => {
                        if (event.ctrlKey && event.key === 'Enter') {
                          event.preventDefault()
                          const selection = window.getSelection()
                          const range = selection.getRangeAt(0)
                          const br = document.createElement('br')
                          range.insertNode(br)
                          range.collapse(false)
                          selection.removeAllRanges()
                          selection.addRange(range)
                        }
                        if (event.key === 'Enter') {
                          event.preventDefault()
                          sendMessage({
                            conversation_id: chatId.conversation_id,
                            message: messageInput,
                            image: null,
                            voice_note: null,
                            shared: [],
                            recipient_id: chatId.user.user_id,
                            user_id: userInfo.user_id,
                            message_rid: null,
                            type: 'TEXT',
                          })
                        }
                      }}
                      className={styles.textarea}
                      onClick={() => setIsEmoji(false)}
                      role={'textbox'}
                      onInput={(event) => {
                        setMessageInput(event.target.innerHTML)
                        setTimeout(() => {
                          placeCaretAtEnd(event.target)
                        }, 0)
                      }}
                      suppressContentEditableWarning={true}
                      dangerouslySetInnerHTML={{ __html: messageInput }}
                      contentEditable
                      aria-multiline={true}
                      aria-label={t('messageInput')}
                    ></span>
                  </div>
                </div>
              ) : (
                <div className={styles.noneChat}>
                  <svg
                    width={70}
                    height={70}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 800 800"
                  >
                    <radialGradient
                      id="a"
                      cx="101.9"
                      cy="809"
                      r="1.1"
                      gradientTransform="matrix(800 0 0 -800 -81386 648000)"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" style={{ stopColor: '#09f' }} />
                      <stop offset=".6" style={{ stopColor: '#a033ff' }} />
                      <stop offset=".9" style={{ stopColor: '#ff5280' }} />
                      <stop offset="1" style={{ stopColor: '#ff7061' }} />
                    </radialGradient>
                    <path
                      fill="url(#a)"
                      d="M400 0C174.7 0 0 165.1 0 388c0 116.6 47.8 217.4 125.6 287 6.5 5.8 10.5 14 10.7 22.8l2.2 71.2a32 32 0 0 0 44.9 28.3l79.4-35c6.7-3 14.3-3.5 21.4-1.6 36.5 10 75.3 15.4 115.8 15.4 225.3 0 400-165.1 400-388S625.3 0 400 0z"
                    />
                    <path
                      fill="#FFF"
                      d="m159.8 501.5 117.5-186.4a60 60 0 0 1 86.8-16l93.5 70.1a24 24 0 0 0 28.9-.1l126.2-95.8c16.8-12.8 38.8 7.4 27.6 25.3L522.7 484.9a60 60 0 0 1-86.8 16l-93.5-70.1a24 24 0 0 0-28.9.1l-126.2 95.8c-16.8 12.8-38.8-7.3-27.5-25.2z"
                    />
                  </svg>
                </div>
              )}
            </div>
          </section>
        </PageContainer>
      )}
    </>
  )
}

export default MessagesPage
